import React, { CSSProperties, ReactNode, useEffect } from "react";
import styles from "./PaymentContainer.module.css";
import { Box } from "@mui/material";

interface IPaymentContainerProps {
    title?: string;
    headerLeft?: JSX.Element;
    headerRight?: JSX.Element;
    children?: ReactNode;
    fab?: JSX.Element;
    onClickHeaderLeft?: () => void;
}

export const bgColor: CSSProperties["backgroundColor"] = "#45A779";

const PaymentContainer = ({
    title,
    headerLeft,
    headerRight,
    children,
    fab,
    onClickHeaderLeft,
}: IPaymentContainerProps) => {
    useEffect(() => {
        document.body.classList.add(styles.scroll_hide);

        return () => document.body.classList.remove(styles.scroll_hide);    
    }, []);

    return (
        <Box className={styles.modal_container}>
            <Box className={styles.backdrop} />
            <Box className={styles.phone_container}>
                <Box
                    className={styles.phone_header}
                    style={{ backgroundColor: bgColor }}
                >
                    <Box
                        className={styles.header_left}
                        onClick={onClickHeaderLeft}
                    >
                        {headerLeft}
                    </Box>
                    <Box className={styles.header_title}>{title ?? ""}</Box>
                    <Box className={styles.header_right}>{headerRight}</Box>
                </Box>
                <Box className={styles.phone_body}>{children}</Box>
                {fab && <Box className={styles.fab}>{fab}</Box>}
            </Box>
        </Box>
    );
};

export default PaymentContainer;
