import React from "react";
import { Text, Font, View, StyleSheet } from "@react-pdf/renderer";

Font.register({
    family: "Open-Sans-Bold",
    src: "https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf",
});

interface IPrintStrukTableHeaderProps {}

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        borderColor: "#000",
        borderWidth: 1,
        backgroundColor: "#fff",
        alignItems: "center",
        fontFamily: "Open-Sans-Bold",
        fontSize: 11,
        whiteSpace: "initial",
        textOverflow: "ellipsis",
        flexGrow: 1,
    },
    no: {
        width: "5%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 4,
        height: 24,
    },
    description: {
        width: "50%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        height: 24,
    },
    qty: {
        width: "5%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        textAlign: "right",
        height: 24,
    },
    rate: {
        width: "20%",
        borderRightColor: "#000",
        borderRightWidth: 1,
        paddingHorizontal: 8,
        textAlign: "right",
        height: 24,
    },
    amount: {
        width: "20%",
        paddingHorizontal: 8,
        textAlign: "right",
        height: 24,
    },
});

const PrintStrukTableHeader = (props: IPrintStrukTableHeaderProps) => {
    return (
        <View style={styles.container}>
            <Text style={styles.no}>NO</Text>
            <Text style={styles.description}>NAMA BARANG</Text>
            <Text style={styles.qty}>QTY</Text>
            <Text style={styles.rate}>HARGA SATUAN (Rp)</Text>
            <Text style={styles.amount}>JUMLAH (Rp)</Text>
        </View>
    );
};

export default PrintStrukTableHeader;
