import { useQuery } from "react-query";
import { GetKotaResponse } from "../../constants/types";
import axios from "../axios";
import qs from "query-string";

const handleRequest = async (provinsiId?: number) => {
    const apiKota = qs.stringifyUrl({
        url: "api/kota",
        query: {
            provinsiId: provinsiId,
        },
    });
    const { data } = await axios.get<GetKotaResponse>(apiKota);
    return data.data;
};

export default function useKota(provinsiId?: number) {
    return useQuery<GetKotaResponse["data"]>(["kota", provinsiId], () =>
        handleRequest(provinsiId),
    );
}
