import React from "react";
import toRupiah from "../../utils/toRupiah";
import toRibuan from "../../utils/toRibuan";
import StarIcon from "@mui/icons-material/Star";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import { useTransaksi } from "../../context/transaksiContext";
import { useDetailBarangModal } from "../../context/detailBarangModalContext";
import {
    Avatar,
    Box,
    Card,
    Button,
    Typography,
    Stack,
    Grid,
    Link,
    useMediaQuery,
    Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "./CardBarang.css";
import avatarAlt from "../../utils/avatarAlt";
import { DetailBundling } from "../../constants/types";
import truncate from "../../utils/truncate";

interface ICardBarangProps {
    id: number;
    namaBarang: string;
    deskripsi?: string;
    harga: number;
    url: string;
    sku: string;
    jumlahStok: number;
    detailBundling: DetailBundling[] | [];
    favorite: number;
    hpp: {
        id: number;
        faktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
    multipleHarga: {
        id: number;
        harga: number;
        keterangan: string | null;
    }[];
}

const CardBarang = ({
    id,
    namaBarang,
    deskripsi,
    harga,
    url,
    sku,
    jumlahStok,
    detailBundling,
    favorite,
    hpp,
    multipleHarga,
}: ICardBarangProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
    const { addToCart, cart } = useTransaksi();
    const { openModalDetailBarang } = useDetailBarangModal();

    return (
        <React.Fragment>
            <Card
                variant="outlined"
                sx={{
                    marginBottom: 1,
                    padding: 2,
                    background:
                        jumlahStok <= 0 && jumlahStok !== null
                            ? "#e5f0ee"
                            : "#fff",
                }}
            >
                <Box
                    display="grid"
                    gridTemplateColumns="1fr auto"
                    rowGap={isPhoneScreen ? 1 : 3}
                    sx={{
                        alignItems: { xs: "stretch", sm: "center" },
                        justifyContent: {
                            xs: "space-between",
                            sm: "space-between",
                        },
                    }}
                >
                    <Stack
                        direction={{ xs: "row-reverse", sm: "row" }}
                        sx={{
                            whiteSpace: "nowrap",
                            // overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                        spacing={2}
                    >
                        <Box sx={{ marginBottom: favorite > 0 ? -5 : 0 }}>
                            <Link
                                onClick={() =>
                                    openModalDetailBarang(
                                        namaBarang,
                                        url,
                                        deskripsi,
                                    )
                                }
                                color="#000000"
                                underline="none"
                                sx={{ cursor: "pointer" }}
                            >
                                <Avatar
                                    sx={{
                                        backgroundColor: "primary.main",
                                        width: {
                                            xs: 60,
                                            sm: 60,
                                            md: 70,
                                            lg: 60,
                                            xl: 80,
                                        },
                                        height: {
                                            // xs: 60,
                                            // sm: 60,
                                            // md: 90,
                                            // lg: 70,
                                            // xl: 90,

                                            xs: 60,
                                            sm: 60,
                                            md: 70,
                                            lg: 60,
                                            xl: 80,
                                        },
                                        borderRadius: "50%",
                                    }}
                                    alt={namaBarang}
                                    src={url}
                                >
                                    {avatarAlt(namaBarang)}
                                </Avatar>
                            </Link>
                            {favorite > 0 && (
                                <StarIcon
                                    sx={{
                                        width: isSmallScreen ? 32 : 24,
                                        height: isSmallScreen ? 32 : 24,
                                        position: "relative",
                                        zIndex: 2,
                                        bottom: {
                                            xl: 85,
                                            lg: 65,
                                            sm: 70,
                                            xs: 70,
                                        },
                                        left: "70%",
                                        border: 1,
                                        borderColor: "#e7e5e4",
                                        borderRadius: "50%",
                                        padding: isPhoneScreen ? 0.5 : 0.25,
                                        color: "#fbbf24",
                                        bgcolor: "#FFFFFF",
                                    }}
                                    fontSize="large"
                                />
                            )}
                        </Box>
                        <Grid container>
                            <Grid item>
                                <Link
                                    onClick={() =>
                                        openModalDetailBarang(
                                            namaBarang,
                                            url,
                                            deskripsi,
                                        )
                                    }
                                    color="#000000"
                                    underline="none"
                                    sx={{ cursor: "pointer" }}
                                >
                                    <Typography
                                        mt={isPhoneScreen ? undefined : 1}
                                        fontSize={12}
                                        color={
                                            jumlahStok < 5 &&
                                            jumlahStok !== null
                                                ? "#ef4444"
                                                : "#76747C"
                                        }
                                        fontWeight={
                                            jumlahStok < 5 &&
                                            jumlahStok !== null
                                                ? 300
                                                : 300
                                        }
                                    >
                                        {detailBundling.length > 0 &&
                                        jumlahStok === null
                                            ? "Otomatis"
                                            : jumlahStok === null
                                            ? "Tanpa stok"
                                            : jumlahStok > 5
                                            ? "Tersedia " +
                                              toRibuan(jumlahStok) +
                                              " barang"
                                            : jumlahStok <= 5 && jumlahStok > 0
                                            ? "Barang tersisa " +
                                              toRibuan(jumlahStok)
                                            : "Barang telah habis"}
                                    </Typography>
                                    <Tooltip title={namaBarang}>
                                        <Typography
                                            fontSize={isPhoneScreen ? 14 : 16}
                                            fontWeight="400"
                                        >
                                            {truncate(namaBarang, 20)}
                                        </Typography>
                                    </Tooltip>
                                    {deskripsi ? (
                                        <Tooltip title={deskripsi}>
                                            <Typography
                                                fontSize={12}
                                                color="#464E4B"
                                                fontWeight="300"
                                            >
                                                {truncate(deskripsi, 30)}
                                            </Typography>
                                        </Tooltip>
                                    ) : undefined}
                                    
                                    {multipleHarga.length > 0 ? (
                                        <Typography
                                            variant="subtitle2"
                                            color="#464E4B"
                                            fontWeight="500"
                                        >
                                            {toRupiah(
                                                Number(multipleHarga[0].harga),
                                            )}
                                        </Typography>
                                    ) : (
                                        <Typography
                                            variant="subtitle2"
                                            color="#464E4B"
                                            fontWeight="500"
                                        >
                                            {toRupiah(harga)}
                                        </Typography>
                                    )}
                                </Link>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Button
                        disabled={
                            cart.findIndex((c) => c.id === id) >= 0 ||
                            (jumlahStok <= 0 && jumlahStok !== null)
                        }
                        variant="contained"
                        onClick={() =>
                            addToCart({
                                id,
                                namaBarang,
                                harga:
                                    multipleHarga.length > 0
                                        ? Number(multipleHarga[0].harga)
                                        : harga,
                                url,
                                qty: 1,
                                sku,
                                jumlahStok,
                                detailBundling,
                                hpp,
                                faktur: [],
                                multipleHarga,
                            })
                        }
                        startIcon={
                            cart.findIndex((c) => c.id === id) >= 0 ? (
                                <ShoppingCartIcon htmlColor="#fff" />
                            ) : jumlahStok <= 0 && jumlahStok !== null ? (
                                <RemoveShoppingCartIcon />
                            ) : (
                                <ShoppingCartCheckoutIcon />
                            )
                        }
                        sx={{
                            gridColumn: isPhoneScreen ? "1 / 3" : "unset",
                        }}
                    >
                        {cart.findIndex((c) => c.id === id) >= 0
                            ? "Di Keranjang"
                            : jumlahStok <= 0 && jumlahStok !== null
                            ? "Stok Habis"
                            : "Pesan"}
                    </Button>
                </Box>
            </Card>
        </React.Fragment>
    );
};

export default CardBarang;
