import React from "react";
import Register from "../../components/Register/Register";

interface IRegisterPageProps {}

const RegisterPage = (props: IRegisterPageProps) => {
    return <Register />;
};

export default RegisterPage;
