import {
    Card,
    CardContent,
    CardActionArea,
    Box,
    Avatar,
    Stack,
    Typography,
    Badge,
} from "@mui/material";
import React, { useMemo } from "react";
import { DetailBundling } from "../../constants/types";
import avatarAlt from "../../utils/avatarAlt";
import toRupiah from "../../utils/toRupiah";
import StarIcon from "@mui/icons-material/Star";
import { useTransaksi } from "../../context/transaksiContext";
import toRibuan from "../../utils/toRibuan";
import truncate from "../../utils/truncate";

interface IMenuBarangProps {
    id: number;
    namaBarang: string;
    deskripsi?: string;
    harga: number;
    url: string;
    sku: string;
    jumlahStok: number;
    detailBundling: DetailBundling[] | [];
    favorite: number;
    hpp: {
        id: number;
        faktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
    multipleHarga: {
        id: number;
        harga: number;
        keterangan: string | null;
    }[];
}

const MenuBarang = ({
    id,
    namaBarang,
    deskripsi,
    harga,
    url,
    sku,
    jumlahStok,
    detailBundling,
    favorite,
    hpp,
    multipleHarga,
}: IMenuBarangProps) => {
    const { addToCart, cart } = useTransaksi();

    const qtyLessThanFive = jumlahStok < 5 && jumlahStok !== null;
    const qtyZero = jumlahStok === 0 && jumlahStok !== null;

    const qtyColor = useMemo(() => {
        if (qtyZero) return "#ED4521";
        if (qtyLessThanFive) return "#E4D318";
        return "#7C8883";
    }, [jumlahStok]);

    return (
        <Card
            variant="outlined"
            sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                borderColor:
                    cart.findIndex((c) => c.id === id) >= 0 ||
                    (jumlahStok < 0 && jumlahStok !== null)
                        ? "primary.main"
                        : undefined,
                borderWidth:
                    cart.findIndex((c) => c.id === id) >= 0 ||
                    (jumlahStok < 0 && jumlahStok !== null)
                        ? "medium"
                        : undefined,
                borderRadius: "8px",
                // minWidth: "248px",
            }}
        >
            <CardActionArea
                disabled={
                    cart.findIndex((c) => c.id === id) >= 0 ||
                    (jumlahStok <= 0 && jumlahStok !== null)
                }
                onClick={() => {
                    addToCart({
                        id,
                        namaBarang,
                        harga,
                        url,
                        qty: 1,
                        sku,
                        jumlahStok,
                        detailBundling,
                        hpp,
                        faktur: [],
                        multipleHarga,
                    });
                }}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                }}
            >
                <CardContent
                    sx={{
                        paddingBottom: 0,
                        width: "100%",
                    }}
                >
                    <Box display="flex" justifyContent="center">
                        <Badge
                            overlap="circular"
                            badgeContent={
                                favorite > 0 ? (
                                    <Box
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            position: "relative",
                                            backgroundColor: "#fff",
                                            borderRadius: "50%",
                                        }}
                                    >
                                        <Box>
                                            <StarIcon
                                                htmlColor="#fbbf24"
                                                sx={{
                                                    fontSize: "1.3rem",
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform:
                                                        "translate(-50%, -50%)",
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                ) : undefined
                            }
                            sx={{ marginY: 3 }}
                        >
                            <Avatar
                                alt={namaBarang}
                                src={url === null ? "" : url}
                                sx={{
                                    bgcolor: "primary.main",
                                    width: 70,
                                    height: 70,
                                }}
                            >
                                {avatarAlt(namaBarang)}
                            </Avatar>
                        </Badge>
                    </Box>
                    <Stack direction="column" spacing={1}>
                        <Typography
                            fontSize={10.5}
                            color={qtyColor}
                            fontWeight={
                                jumlahStok < 5 && jumlahStok !== null
                                    ? 700
                                    : 300
                            }
                            letterSpacing={0.25}
                        >
                            {detailBundling.length > 0 && jumlahStok === null
                                ? "Otomatis"
                                : jumlahStok === null
                                ? "Tanpa stok"
                                : jumlahStok > 5
                                ? "Tersedia " + toRibuan(jumlahStok) + " barang"
                                : jumlahStok <= 5 && jumlahStok > 0
                                ? "Barang tersisa " + toRibuan(jumlahStok)
                                : "Barang telah habis"}
                        </Typography>
                        <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                            justifyContent="flex-start"
                        >
                            <Typography
                                variant="h6"
                                textAlign="start"
                                fontWeight={400}
                                fontSize={14}
                                color="#464E4B"
                            >
                                {namaBarang}
                            </Typography>
                        </Stack>
                        {deskripsi && (
                            <Typography
                                variant="body2"
                                textAlign="start"
                                fontSize={10.5}
                                fontWeight={300}
                                letterSpacing={0.25}
                                color="#464E4B"
                                sx={{ marginBottom: 2 }}
                            >
                                {truncate(deskripsi, 50)}
                            </Typography>
                        )}
                    </Stack>
                </CardContent>

                <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    margin={2}
                    px={2}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                    {multipleHarga.length > 0 ? (
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="flex-end"
                            gap={0.5}
                        >
                            <Typography
                                variant="h6"
                                color="#464E4B"
                                fontWeight={500}
                                fontSize={14}
                                textAlign="start"
                                sx={{
                                    marginBottom: 2,
                                    lineHeight: "160%",
                                }}
                            >
                                {toRupiah(Number(multipleHarga[0].harga))}
                            </Typography>
                        </Box>
                    ) : (
                        <Typography
                            variant="h6"
                            color="#464E4B"
                            fontWeight={500}
                            fontSize={14}
                            textAlign="start"
                            sx={{
                                marginBottom: 2,
                                lineHeight: "160%",
                            }}
                        >
                            {toRupiah(harga)}
                        </Typography>
                    )}
                </Box>
            </CardActionArea>
        </Card>
    );
};

export default MenuBarang;
