import { useQuery } from "react-query";
import { ParamsGetLaporanTotal, GetLaporanTotalResponse } from "../../constants/types";
import axios from "../axios";

const queryString = require("query-string");

const handleRequest = async (params: ParamsGetLaporanTotal) => {
    const apiGetLaporanTotal = queryString.stringifyUrl({
        url: "api/laporan/total",
        query: {
            ukmId: params.ukmId,
            tanggalAwal: params.tanggalAwal,
            tanggalAkhir: params.tanggalAkhir,
        },
    });
    const { data } = await axios.get<GetLaporanTotalResponse>(apiGetLaporanTotal);
    return data.data;
};

export default function useLaporanTotal(params: ParamsGetLaporanTotal) {
    return useQuery<GetLaporanTotalResponse["data"]>(["laporanTotal", params], () => handleRequest(params));
}
