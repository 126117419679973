import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { IDetailFaktur, SelectedFakturMutasi } from "../../constants/types";
import { useTransaksi } from "../../context/transaksiContext";
import DetailFaktur from "./DetailFaktur";

interface IDetailFakturStepProps {
    products: SelectedFakturMutasi[];
    onBack: () => void;
    isOpenModalPilihFaktur: boolean;
    closeModalPilihFaktur: () => void;
    qty: number;
    namaBarang: string;
    idBarang: number;
    isBundling?: boolean;
    idSatuan?: number;
}

const DetailFakturStep = ({
    products,
    onBack,
    isOpenModalPilihFaktur,
    closeModalPilihFaktur,
    qty,
    namaBarang,
    idBarang,
    isBundling,
    idSatuan,
}: IDetailFakturStepProps) => {
    const { handleFaktur, handleFakturBundling } = useTransaksi();

    const initialValues: IDetailFaktur = useMemo(
        () => ({
            detail: products.map((li) => ({
                fakturId: li.id,
                jumlahStok: "",
                kodeFaktur: li.kodeFaktur,
            })) ?? [
                {
                    itemId: 1,
                    jumlahStok: 1,
                    kodeFaktur: "",
                },
            ],
        }),
        [products],
    );

    const methods = useForm<IDetailFaktur>({
        // resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const onSubmit = async (values: IDetailFaktur) => {
        console.log(values);
        const jumlahTotal = values.detail
            .map((li) => Number(li.jumlahStok))
            .reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0,
            );
        console.log(jumlahTotal);
        const mappedValues = values.detail.map((li) => ({
            fakturId: li.fakturId,
            kodeFaktur: li.kodeFaktur,
            jumlahItem: Number(li.jumlahStok),
        }));
        if (jumlahTotal > qty) {
            Swal.fire({
                title: "Jumlah melebihi yang ada pada keranjang",
                position: "top-end",
                showConfirmButton: false,
                icon: "error",
                toast: true,
                timer: 3000,
                timerProgressBar: true,
                showCloseButton: true,
                customClass: {
                    container: "my-swal",
                },
            });
        } else if (jumlahTotal < qty) {
            Swal.fire({
                title: "Jumlah kurang dari yang ada pada keranjang",
                position: "top-end",
                showConfirmButton: false,
                icon: "error",
                toast: true,
                timer: 3000,
                timerProgressBar: true,
                showCloseButton: true,
                customClass: {
                    container: "my-swal",
                },
            });
        } else {
            // addFaktur(mappedValues);
            if (isBundling) {
                handleFakturBundling(idBarang, mappedValues, Number(idSatuan));
            } else {
                handleFaktur(idBarang, mappedValues);
            }
            Swal.fire({
                title: `Faktur untuk ${namaBarang} dipilih`,
                position: "top-end",
                showConfirmButton: false,
                icon: "success",
                toast: true,
                timer: 3000,
                timerProgressBar: true,
                showCloseButton: true,
                customClass: {
                    container: "my-swal",
                },
            });
            closeModalPilihFaktur();
            // alert(JSON.stringify(mappedValues));
        }
        // setIsButtonLoading(true);
    };

    useEffect(() => {
        if (isOpenModalPilihFaktur) {
            methods.reset(initialValues);
        }
    }, [initialValues, isOpenModalPilihFaktur, methods]);

    return (
        <React.Fragment>
            <Box mt={2}>
                <Typography component="div">
                    Jumlah barang di keranjang:{" "}
                    <Typography display="inline" fontWeight="bold">
                        {qty}
                    </Typography>
                </Typography>
                <Stack mt={2} mb={5} direction="column" spacing={2}>
                    {products &&
                        products?.map((li, idx) => (
                            <FormProvider key={String(li.id)} {...methods}>
                                <DetailFaktur
                                    id={li.id}
                                    idx={idx}
                                    nama={li.kodeFaktur}
                                    stok={li.jumlahStok}
                                    hargaBeli={li.hargaBeli}
                                />
                            </FormProvider>
                        ))}
                </Stack>
            </Box>
            <Box
                mt={4}
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr"
                justifyContent="flex-end"
                columnGap="16px"
            >
                <div />
                <Button onClick={onBack} variant="outlined">
                    Kembali
                </Button>
                <Button
                    variant="contained"
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    Simpan
                </Button>
            </Box>
        </React.Fragment>
    );
};

export default DetailFakturStep;
