import React, {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";

interface State {
  isOpenAlert: boolean;
  openAlert: () => void;
  words: string;
  handleCloseAlert: (
    event?: Event | React.SyntheticEvent<Element, Event> | undefined,
    reason?: string | undefined
  ) => void;
  assignWords: (data: string) => void;
}

interface IAlertContext {
  children: ReactNode;
}

const AlertContext = createContext<State | undefined>(undefined);

const AlertProvider = ({ children }: IAlertContext) => {
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [words, setWords] = useState("");

  const openAlert = useCallback(() => {
    setIsOpenAlert(true);
  }, []);

  const handleCloseAlert = useCallback(
    (
      event?: Event | React.SyntheticEvent<Element, Event> | undefined,
      reason?: string | undefined
    ) => {
      if (reason === "clickaway") {
        return;
      }

      setIsOpenAlert(false);
    },
    []
  );

  const assignWords = useCallback((data: string) => {
    setWords(data);
  }, []);

  const value = useMemo(
    () => ({
      isOpenAlert,
      setIsOpenAlert,
      words,
      assignWords,
      openAlert,
      handleCloseAlert,
    }),
    [assignWords, handleCloseAlert, isOpenAlert, openAlert, words]
  );
  return (
    <AlertContext.Provider value={value}>{children}</AlertContext.Provider>
  );
};

const useAlert = () => {
  const context = React.useContext(AlertContext);
  if (context === undefined) {
    throw new Error("useAlert must be used within a AlertProvider");
  }
  return context;
};

export { AlertContext, AlertProvider, useAlert };
