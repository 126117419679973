import React, {
    createContext,
    ReactNode,
    useCallback,
    useMemo,
    useState,
} from "react";
import { ModalUkmField } from "../constants/types";

interface State {
    isOpenModalUkm: boolean;
    dataUkm?: ModalUkmField;
    openModalUkm: (data?: ModalUkmField) => void;
    closeModalUkm: () => void;
}

interface IUkmModalContext {
    children: ReactNode;
}

const UkmModalContext = createContext<State | undefined>(undefined);

const UkmModalProvider = ({ children }: IUkmModalContext) => {
    const [isOpenModalUkm, setIsOpenModalUkm] = useState(false);
    const [dataUkm, setDataUkm] = useState<ModalUkmField>();

    const openModalUkm = useCallback((data?: ModalUkmField) => {
        if (data) {
            setDataUkm((prev) => ({
                ...prev,
                ...data,
            }));
        }

        setIsOpenModalUkm(true);
    }, []);

    const closeModalUkm = useCallback(() => {
        setIsOpenModalUkm(false);
        setDataUkm(undefined);
    }, []);

    const value = useMemo(
        () => ({
            isOpenModalUkm,
            dataUkm,
            openModalUkm,
            closeModalUkm,
        }),
        [isOpenModalUkm, dataUkm, openModalUkm, closeModalUkm],
    );

    return (
        <UkmModalContext.Provider value={value}>
            {children}
        </UkmModalContext.Provider>
    );
};

const useUkmModal = () => {
    const context = React.useContext(UkmModalContext);
    if (context === undefined) {
        throw new Error("useUkmModal must be used within a UkmModalProvider");
    }
    return context;
};

export { UkmModalContext, UkmModalProvider, useUkmModal };
