/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
    Box,
    Button,
    // Grid,
    Link,
    Pagination,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useState } from "react";
import { ParamsGetKategori } from "../../constants/types";
import { useAuth } from "../../context/authContext";
import { useKategoriModal } from "../../context/kategoriModalContext";
import useUser from "../../services/queries/useUser";
import useKategori from "../../services/queries/useKategori";
import { useEditKategoriModal } from "../../context/editKategoriModalContext";
import { useHapusKategoriModal } from "../../context/hapusKategoriModalContext";
import ModalKategori from "../../components/ModalKategori/ModalKategori";
import ModalDeleteKategori from "../../components/ModalDeleteKategori/ModalDeleteKategori";
import ModalEditKategori from "../../components/ModalEditKategori/ModalEditKategori";
import Spinner from "../../components/Spinner/Spinner";
import CardKategori from "../../components/CardKategori/CardKategori";
import { ReactComponent as BarangTidakDitemukan } from "../../assets/images/BarangTidakDitemukan.svg";
import NoRowsImage from "../../components/NoRowsImage/NoRowsImage";
import LoadingTabel from "../../components/LoadingTabel/LoadingTabel";

interface IKategoriProps {}

const Kategori = (props: IKategoriProps) => {
    const theme = useTheme();
    const { ukmIdUser } = useAuth();
    const isTabletOrPhone = useMediaQuery(theme.breakpoints.down("md"));
    const { status: statusUser } = useUser();
    const initialParamsKategori = {
        ukmId: Number(ukmIdUser && ukmIdUser[0]),
        size: 10,
        page: 1,
        search: "",
    };
    const [paramsKategori, setParamsKategori] = useState<ParamsGetKategori>(
        initialParamsKategori,
    );
    const {
        data: kategori,
        // status: statusKategori,
        refetch: refetchKategori,
        isLoading: isLoadingKategori,
    } = useKategori(paramsKategori);
    const { openModalKategori } = useKategoriModal();
    const { openModalEditKategori } = useEditKategoriModal();
    const { openModalHapusKategori } = useHapusKategoriModal();
    const [search, setSearch] = useState("");

    const columns: GridColDef[] = [
        {
            field: "nama",
            headerName: "Nama Kategori",
            // headerAlign: "center",
            flex: 1,
            minWidth: 170,
            renderCell: (params) => {
                return (
                    <Typography variant="body2" fontWeight="bold">
                        {params.value}
                    </Typography>
                );
            },
        },
        {
            field: "aksi",
            headerName: "Aksi",
            headerAlign: "center",
            align: "center",
            minWidth: 130,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    // <Grid
                    //     container
                    //     spacing={1}
                    //     justifyContent="center"
                    //     alignItems="center"
                    // >
                    //     <Grid item xs={6} sm={6}>
                    //         <Button
                    //             variant="outlined"
                    //             onClick={() =>
                    //                 openModalEditKategori(
                    //                     Number(params.id),
                    //                     params.row.nama,
                    //                 )
                    //             }
                    //             sx={{
                    //                 minWidth: "unset",
                    //                 padding: "6px",
                    //                 width: "fit-content",
                    //             }}
                    //             // startIcon={<EditIcon />}
                    //             size="small"
                    //         >
                    //             <EditIcon />
                    //         </Button>
                    //     </Grid>
                    //     <Grid item xs={6} sm={6}>
                    //         <Button
                    //             variant="contained"
                    //             onClick={() =>
                    //                 openModalHapusKategori(
                    //                     params.id,
                    //                     params.row.nama,
                    //                 )
                    //             }
                    //             sx={{
                    //                 minWidth: "unset",
                    //                 padding: "6px",
                    //                 width: "fit-content",
                    //             }}
                    //             size="small"
                    //             color="error"
                    //         >
                    //             <DeleteIcon />
                    //         </Button>
                    //     </Grid>
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="outlined"
                            onClick={() =>
                                openModalEditKategori(
                                    Number(params.id),
                                    params.row.nama,
                                )
                            }
                            sx={{
                                minWidth: "unset",
                                padding: "6px",
                                width: "fit-content",
                            }}
                            // startIcon={<EditIcon />}
                            size="small"
                        >
                            <EditIcon />
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() =>
                                openModalHapusKategori(
                                    params.id,
                                    params.row.nama,
                                )
                            }
                            sx={{
                                minWidth: "unset",
                                padding: "6px",
                                width: "fit-content",
                            }}
                            size="small"
                            color="error"
                        >
                            <DeleteIcon />
                        </Button>
                    </Stack>
                    // </Grid>
                );
            },
        },
    ];

    const handleOnPagination = (page: number) => {
        setParamsKategori((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const handleOnSizeChange = (size: number) => {
        setParamsKategori((prev) => ({ ...prev, size }));
    };

    if (statusUser === "loading") {
        return (
            <Box position="relative" height="100vh">
                <Spinner />
            </Box>
        );
    }

    return (
        <Box paddingBottom={3}>
            <Box marginY={3}>
                <Typography variant="h5" fontWeight="bold">
                    Daftar Kategori
                </Typography>
            </Box>
            <Box alignItems="center" marginY={2}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                >
                    <TextField
                        placeholder="Cari kategori..."
                        size="small"
                        value={search}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                            setSearch(event.target.value);
                        }}
                        onKeyPress={(e: any) => {
                            e.key === "Enter"
                                ? setParamsKategori((prev) => ({
                                      ...prev,
                                      search: search,
                                      page: 1,
                                  }))
                                : undefined;
                        }}
                        variant="outlined"
                        InputProps={{
                            style: {
                                backgroundColor: "white",
                            },
                            endAdornment: (
                                <Link
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    onClick={() =>
                                        setParamsKategori((prev) => ({
                                            ...prev,
                                            search: search,
                                            page: 1,
                                        }))
                                    }
                                    sx={{
                                        cursor: "pointer",
                                    }}
                                >
                                    <SearchIcon fontSize="small" />{" "}
                                </Link>
                            ),
                        }}
                    />
                    <Button
                        variant="contained"
                        onClick={() => openModalKategori()}
                        startIcon={<AddIcon />}
                        size="medium"
                        // fullWidth
                        sx={{
                            lineHeight: 2,
                        }}
                    >
                        Tambah
                    </Button>
                </Stack>
            </Box>
            {isTabletOrPhone ? (
                kategori && kategori?.totalElements > 0 ? (
                    <React.Fragment>
                        {kategori.content.map((k) => (
                            <CardKategori key={k.id} id={k.id} nama={k.nama} />
                        ))}
                        <Box
                            display="flex"
                            justifyContent="center"
                            paddingY={2}
                        >
                            <Pagination
                                color="primary"
                                count={Math.ceil(
                                    Number(
                                        kategori &&
                                            kategori?.totalElements /
                                                initialParamsKategori.size,
                                    ),
                                )}
                                page={paramsKategori.page}
                                onChange={(event, value) => {
                                    setParamsKategori((prev) => ({
                                        ...prev,
                                        page: value,
                                    }));
                                }}
                                siblingCount={0}
                                // sx={{ justifyContent: "center" }}
                            />
                        </Box>
                    </React.Fragment>
                ) : (
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        paddingTop={2}
                    >
                        <BarangTidakDitemukan width="150px" height="150px" />
                        <Typography fontWeight="bold">
                            Kategori tidak ditemukan
                        </Typography>
                    </Stack>
                )
            ) : (
                <Box
                    sx={{
                        height: 630,
                        width: "100%",
                        marginTop: 2,
                        backgroundColor: "#ffffff",
                        borderRadius: 1,
                        "& .headerColumn": {
                            backgroundColor: "#E4EEE8",
                            // fontSize: "18px",
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "bold",
                        },
                        "& .MuiDataGrid-columnSeparator": {
                            visibility: "hidden",
                        },
                        "& .MuiDataGrid-cell:focus-within": {
                            outline: "none !important",
                        },
                        "& .MuiDataGrid-iconButtonContainer": {
                            visibility: "visible",
                            width: "0 !important",
                        },
                    }}
                >
                    {/* <ThemeProvider theme={theme}> */}
                    <DataGrid
                        paginationMode="server"
                        components={{
                            ColumnUnsortedIcon: () => {
                                return <ImportExportIcon />;
                            },
                            NoRowsOverlay: NoRowsImage,
                            LoadingOverlay: LoadingTabel,
                            // Pagination: CustomPagination,
                        }}
                        rows={kategori?.content ?? []}
                        columns={columns}
                        disableColumnMenu
                        disableSelectionOnClick
                        // rowsPerPageOptions={[10, 15, 20]}
                        rowsPerPageOptions={[10]}
                        onPageSizeChange={handleOnSizeChange}
                        onPageChange={handleOnPagination}
                        rowCount={kategori?.totalElements ?? 0}
                        page={paramsKategori.page - 1}
                        pageSize={paramsKategori.size}
                        density="standard"
                        loading={isLoadingKategori}
                    />
                    {/* </ThemeProvider> */}
                </Box>
            )}
            <ModalKategori refetch={refetchKategori} />
            <ModalDeleteKategori refetch={refetchKategori} />
            <ModalEditKategori refetch={refetchKategori} />
        </Box>
    );
};

export default Kategori;
