import React, { createContext, ReactNode, useCallback, useMemo, useState } from "react";

interface State {
    openModalDetailBarang: (namaBarang: string, url: string, deskripsi?: string | undefined) => void;
    closeModalDetailBarang: () => void;
    isOpenModalDetailBarang: boolean;
    namaBarang: string;
    url: string;
    deskripsi?: string;
}

interface IDetailBarangModalContext {
    children: ReactNode;
}

const DetailBarangModalContext = createContext<State | undefined>(undefined);

const DetailBarangModalProvider = ({ children }: IDetailBarangModalContext) => {
    const [isOpenModalDetailBarang, setIsOpenModalDetailBarang] = useState(false);
    const [namaBarang, setNamaBarang] = useState<string>("");
    const [deskripsi, setDeskripsi] = useState<string>("");
    const [url, setUrl] = useState<string>("");

    const openModalDetailBarang = useCallback((namaBarang: string, url: string, deskripsi?: string | undefined) => {
        setIsOpenModalDetailBarang(true);
        setNamaBarang(namaBarang);
        setUrl(url);
        if (deskripsi) {
            setDeskripsi(deskripsi);
        }
    }, []);

    const closeModalDetailBarang = useCallback(() => {
        setIsOpenModalDetailBarang(false);
        // setDeskripsi("");
    }, []);

    const value = useMemo(
        () => ({
            openModalDetailBarang,
            isOpenModalDetailBarang,
            closeModalDetailBarang,
            namaBarang,
            deskripsi,
            url,
        }),
        [openModalDetailBarang, isOpenModalDetailBarang, closeModalDetailBarang, namaBarang, deskripsi, url]
    );
    return <DetailBarangModalContext.Provider value={value}>{children}</DetailBarangModalContext.Provider>;
};

const useDetailBarangModal = () => {
    const context = React.useContext(DetailBarangModalContext);
    if (context === undefined) {
        throw new Error("useDetailBarangModal must be used within a DetailBarangModalProvider");
    }
    return context;
};

export { DetailBarangModalContext, DetailBarangModalProvider, useDetailBarangModal };
