import { Snackbar, Alert } from "@mui/material";
import React from "react";

interface IAlertSnackbarProps {
  open: boolean;
  onClose: (event?: React.SyntheticEvent | Event, reason?: string) => void;
  words: React.ReactNode;
}

const AlertSnackbar = ({ open, onClose, words }: IAlertSnackbarProps) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <Alert onClose={onClose} severity="success" sx={{ width: "100%" }}>
        {words}
      </Alert>
    </Snackbar>
  );
};

export default AlertSnackbar;
