import { GridOverlay } from "@mui/x-data-grid";
import React from "react";
import logoUloGif from "../../assets/images/LoadingUlo.gif";

interface ILoadingTabelProps {}

const LoadingTabel = (props: ILoadingTabelProps) => {
    return (
        <GridOverlay>
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    // width: 100%;
                    transform: "translate(-50%, -50%)",
                }}
            >
                <img
                    src={logoUloGif}
                    height="100px"
                    width="100px"
                    style={{
                        objectFit: "contain",
                    }}
                    alt="loading..."
                />
            </div>
        </GridOverlay>
    );
};

export default LoadingTabel;
