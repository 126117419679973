import { Backdrop } from "@mui/material";
import React from "react";
import logoUloGif from "../../assets/images/LoadingUlo.gif";

interface ILoadingOverlayProps {
    open: boolean;
}

const LoadingOverlay = ({ open }: ILoadingOverlayProps) => {
    return (
        <Backdrop
            sx={{
                color: "primary.main",
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={open}
        >
            <img
                src={logoUloGif}
                height="100px"
                width="100px"
                style={{ objectFit: "contain" }}
                alt="loading..."
            />
        </Backdrop>
    );
};

export default LoadingOverlay;
