import React, { useMemo } from "react";
import Close from "../Assets/Close";
import PaymentContainer, {
    bgColor,
} from "../PaymentContainer/PaymentContainer";
import styles from "../Payment.module.css";
import useLayanan from "../../../services/queries/useLayanan";
import Spinner from "../../Spinner/Spinner";
import { Stack } from "@mui/material";
import toRupiah from "../../../utils/toRupiah";
import { Link, useHistory, useParams } from "react-router-dom";
import { PaymentRouteParams } from "../../../constants/types";

interface IPaymentInformationProps {
    orderId: string;
}

const PaymentInformation = ({ orderId }: IPaymentInformationProps) => {
    const history = useHistory();
    let { layananId } = useParams<PaymentRouteParams>();
    const { data: dataLayanan, status: statusLayanan } = useLayanan();

    const namaLayanan =
        dataLayanan &&
        dataLayanan?.find((item) => item.id.toString() === layananId)?.nama;
    const deskripsiLayanan =
        dataLayanan &&
        dataLayanan?.find((item) => item.id.toString() === layananId)
            ?.deskripsiLayanan;
    const hargaLayanan =
        dataLayanan &&
        dataLayanan?.find((item) => item.id.toString() === layananId)?.harga;

    const RedirectButton = useMemo(
        () => (
            <div style={{ padding: 16 }}>
                <div className={styles.section_float}>
                    <div className={styles.half_focused_text}>
                        Total tagihan
                    </div>
                    <div className={styles.focused_text}>
                        {toRupiah(hargaLayanan ?? 0)}
                    </div>
                </div>
                <Link
                    className={styles.button}
                    style={{ backgroundColor: bgColor }}
                    to={`${window.location.pathname}/metode`}
                >
                    Lanjut ke Pembayaran
                </Link>
            </div>
        ),
        [hargaLayanan],
    );

    return (
        <>
            <PaymentContainer
                title="Informasi Pembayaran"
                headerLeft={<Close />}
                onClickHeaderLeft={() => history.goBack()}
                fab={RedirectButton}
            >
                <div className={styles.section}>
                    <div className={styles.section_header}>
                        <div className={styles.half_focused_text}>Order ID</div>
                        <div className={styles.focused_text}>
                            {orderId ?? ""}
                        </div>
                    </div>
                </div>
                <div className={styles.spacer} />
                {statusLayanan === "loading" ? (
                    <Stack
                        display="grid"
                        gridTemplateColumns="1fr"
                        alignItems="center"
                        height={400}
                        position="relative"
                    >
                        <Spinner />
                    </Stack>
                ) : (
                    <div className={styles.section}>
                        <div className={styles.section_header}>
                            <div className={styles.half_focused_text}>
                                Detail Pesanan
                            </div>
                        </div>
                        <div className={styles.product}>
                            <div className={styles.product_container}>
                                <div className={styles.product_info}>
                                    <div className={styles.info_title}>
                                        {namaLayanan}
                                    </div>
                                    <div
                                        className={[
                                            styles.info_subtitle,
                                            styles.half_focused_text,
                                        ].join(" ")}
                                    >
                                        {deskripsiLayanan}
                                    </div>
                                </div>
                                <div
                                    className={[
                                        styles.product_price,
                                        styles.focused_text,
                                    ].join(" ")}
                                >
                                    {toRupiah(hargaLayanan ?? 0)}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </PaymentContainer>
        </>
    );
};

export default PaymentInformation;
