import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useMemo, useState } from "react";
import useLayanan from "../../services/queries/useLayanan";
import { IOptions } from "../../constants/types";
import toRupiah from "../../utils/toRupiah";
import { ArrowBack } from "@mui/icons-material";
import useUser from "../../services/queries/useUser";
// import { getLocalUkmId } from "../../utils/handleChangeUkmId";

interface IModalAlertProProps {
    closeModalAlertPro: () => void;
    isOpenModalAlertPro: boolean;
    dataUkm: {
        id: number;
        nama: string;
    };
}

const ModalAlertPro = ({
    closeModalAlertPro,
    isOpenModalAlertPro,
    dataUkm,
}: IModalAlertProProps) => {
    const moment = require("moment");
    const orderId = useMemo(() => moment().format("YYMMDDHHmmss"), [moment]);
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { data: user } = useUser();
    const { data: dataLayanan, isLoading: isLoadingLayanan } = useLayanan();
    const [selectedLayanan, setSelectedLayanan] = useState<IOptions | null>(
        null,
    );

    const filteredLayanan = dataLayanan?.filter((li) => li.isHide !== 1);

    // const ukmId = useMemo(() => {
    //     if (user?.userId) {
    //         return getLocalUkmId(user?.userId);
    //     }
    //     return null;
    // }, [user?.userId]);

    return (
        <Dialog
            maxWidth="sm"
            fullWidth={true}
            fullScreen={isPhoneScreen}
            open={isOpenModalAlertPro}
            onClose={closeModalAlertPro}
            PaperProps={{
                sx: {
                    //   minHeight: "150px",
                    //   maxHeight: "150px",
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalAlertPro}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Pengajuan langganan ({dataUkm.nama})
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Pilih layanan terlebih dahulu:
                </DialogContentText>
                <Stack marginTop={3} spacing={3}>
                    {/* <Typography
                        fontWeight="bold"
                        color="#343941"
                        fontSize="18px"
                    >
                        Detail Tagihan
                    </Typography> */}
                    <Autocomplete
                        disablePortal
                        loading={isLoadingLayanan}
                        options={filteredLayanan ?? []}
                        getOptionLabel={(option) => option.nama}
                        isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                        }
                        size="small"
                        value={selectedLayanan}
                        onChange={(_event, selected: IOptions | null) => {
                            setSelectedLayanan(selected);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Pilih layanan" />
                        )}
                    />
                    <Stack
                        direction={isPhoneScreen ? "column" : "row"}
                        display="flex"
                        justifyContent="space-between"
                        alignItems={isPhoneScreen ? "normal" : "center"}
                    >
                        <Box>
                            <Typography color="#343941" fontSize="18px">
                                {
                                    dataLayanan?.find(
                                        (item) =>
                                            item.id === selectedLayanan?.id,
                                    )?.nama
                                }
                            </Typography>
                            <Typography variant="body2" color="#6D6D6D">
                                {
                                    dataLayanan?.find(
                                        (item) =>
                                            item.id === selectedLayanan?.id,
                                    )?.deskripsiLayanan
                                }
                            </Typography>
                        </Box>
                        <Typography
                            fontWeight="bold"
                            color="#343941"
                            fontSize="18px"
                            mt={isPhoneScreen ? 2 : 0}
                        >
                            {toRupiah(
                                dataLayanan?.find(
                                    (item) => item.id === selectedLayanan?.id,
                                )?.harga ?? 0,
                            )}
                        </Typography>
                    </Stack>
                    <Divider />
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography
                            variant="h5"
                            color="#343941"
                            fontWeight="bold"
                        >
                            Total
                        </Typography>
                        <Typography
                            variant="h5"
                            color="#343941"
                            fontWeight="bold"
                        >
                            {toRupiah(
                                dataLayanan?.find(
                                    (item) => item.id === selectedLayanan?.id,
                                )?.harga ?? 0,
                            )}
                        </Typography>
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ padding: 2, gap: 1 }}>
                <Button
                    disabled={selectedLayanan === null}
                    variant="contained"
                    onClick={closeModalAlertPro}
                    href={`/payment/U-${orderId}${user?.userId}/${selectedLayanan?.id}/${dataUkm.id}`}
                    color="primary"
                    sx={{
                        fontWeight: "bold",
                    }}
                >
                    Bayar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalAlertPro;
