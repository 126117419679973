import { useQuery } from "react-query";
import { GetFindMetodePembayaranResponse } from "../../constants/types";
import axios from "../axios";

const handleRequest = async (metodeId?: number) => {
    const { data } = await axios.get<GetFindMetodePembayaranResponse>(
        `/api/metode-bayar/${metodeId}`,
    );
    return data.data;
};

export default function useFindMetodePembayaran(metodeId?: number) {
    return useQuery<GetFindMetodePembayaranResponse["data"]>(
        ["listMetodePembayaran", metodeId],
        () => handleRequest(metodeId),
        {
            enabled: !!metodeId,
        },
    );
}
