import React from "react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ThemeDefaultComponent from "../ThemeDefaultComponent/ThemeDefaultComponent";
import { useHistory } from "react-router-dom";
import LogoUlo from "../LogoUlo/LogoUlo";
import { ReactComponent as VerifikasiEmailIllust } from "../../assets/images/VerifikasiEmailIllust.svg";

interface IVerifikasiEmailProps {}

const VerifikasiEmail = (props: IVerifikasiEmailProps) => {
    const theme = useTheme();
    const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
    const history = useHistory();

    return (
        <ThemeDefaultComponent>
            <Box
                sx={{
                    width: isTabletScreen ? "100%" : undefined,
                    height: isTabletScreen ? "100%" : undefined,
                    display: "flex",
                    flexDirection: "column",
                    gap: 6,
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "#FFF",
                    borderRadius: 1,
                    padding: isTabletScreen ? 3 : 9,
                }}
            >
                <LogoUlo width="97px" height="48px" />
                <VerifikasiEmailIllust
                    width={267}
                    style={{
                        height: "fit-content",
                        marginTop: -64,
                        marginBottom: -64,
                    }}
                />
                <Box display="flex" flexDirection="column" gap={1}>
                    <Typography
                        variant="h5"
                        fontSize={28}
                        fontWeight="bold"
                        textAlign="center"
                    >
                        Cek email anda untuk verifikasi
                    </Typography>
                    <Typography
                        variant="body2"
                        color="#808080"
                        textAlign="center"
                    >
                        Silahkan verifikasi akun anda dengan cara klik link yang
                        telah kami kirim ke alamat email anda
                    </Typography>
                </Box>
                <Button variant="contained" onClick={() => history.push("/")}>
                    Kembali ke halaman Masuk
                </Button>
            </Box>
        </ThemeDefaultComponent>
    );
};

export default VerifikasiEmail;
