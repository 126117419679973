import { useQuery } from "react-query";
import { GetProvinsiResponse } from "../../constants/types";
import axios from "../axios";
import qs from "query-string";

const handleRequest = async () => {
    const apiProvinsi = qs.stringifyUrl({
        url: "api/provinsi",
    });
    const { data } = await axios.get<GetProvinsiResponse>(apiProvinsi);
    return data.data;
};

export default function useProvinsi() {
    return useQuery<GetProvinsiResponse["data"]>(["provinsi"], () =>
        handleRequest(),
    );
}
