import {
    CheckBox,
    CheckBoxOutlineBlank,
    ImportExport,
} from "@mui/icons-material";
import { Typography, IconButton, Box, Tooltip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { SelectedFakturMutasi } from "../../constants/types";
import toRupiah from "../../utils/toRupiah";
import LoadingTabel from "../LoadingTabel/LoadingTabel";
import NoRowsImage from "../NoRowsImage/NoRowsImage";

interface IPilihFakturStepProps {
    addProducts: (item: SelectedFakturMutasi[]) => void;
    stokId: number;
    namaBarang: string;
    qty: number;
    hpp: {
        id: number;
        faktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
}

const PilihFakturStep = ({
    addProducts,
    stokId,
    namaBarang,
    qty,
    hpp,
}: IPilihFakturStepProps) => {
    const [selectedFaktur, setSelectedFaktur] = useState<
        SelectedFakturMutasi[]
    >([]);

    const filteredFaktur = hpp.map((li) => ({
        id: li.id,
        kodeFaktur: li.faktur,
        hargaBeli: li.hargaBeli,
        jumlahStok: li.jumlah,
    }));

    const isSelected = (id: number) => {
        const filteredFaktur = selectedFaktur.filter(
            (li) => li.id === id,
        ).length;
        if (filteredFaktur > 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSelectedFaktur = (
        id: number,
        kodeFaktur: string,
        hargaBeli: number,
        jumlahStok: number,
    ) => {
        if (isSelected(id)) {
            const newList = selectedFaktur.filter((li) => li.id !== id);
            setSelectedFaktur(newList);
        } else {
            setSelectedFaktur((prev) => [
                ...prev,
                {
                    id,
                    kodeFaktur,
                    hargaBeli,
                    jumlahStok,
                },
            ]);
        }
    };

    const columns: GridColDef[] = [
        {
            field: "kodeFaktur",
            headerName: "Kode Faktur",
            width: 300,
            flex: 1,
            minWidth: 150,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: "hargaBeli",
            headerName: "Harga Beli",
            headerAlign: "center",
            align: "right",
            flex: 1,
            minWidth: 125,
            renderCell: (params) => (
                <Tooltip title={toRupiah(params.value ?? 0)}>
                    <span className="table-cell-trucate">
                        {toRupiah(params.value ?? 0)}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: "jumlahStok",
            headerName: "Jumlah Stok",
            headerAlign: "center",
            align: "center",
            // type: "number",
            // width: 150,
            flex: 1,
            minWidth: 100,
            renderCell: (params) => (
                <Tooltip title={params.value}>
                    <span className="table-cell-trucate">{params.value}</span>
                </Tooltip>
            ),
        },
        {
            field: "aksi",
            headerName: "Aksi",
            headerAlign: "center",
            align: "center",
            minWidth: 100,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <IconButton
                        disabled={
                            params.row.stok === null ||
                            params.row.jumlahStok <= 0
                        }
                        onClick={() => {
                            handleSelectedFaktur(
                                params.row.id,
                                params.row.kodeFaktur,
                                params.row.hargaBeli,
                                params.row.jumlahStok,
                            );
                        }}
                        sx={{
                            minWidth: "unset",
                            padding: "6px",
                            width: "fit-content",
                        }}
                        // startIcon={<EditIcon />}
                        size="small"
                        color="primary"
                    >
                        {isSelected(params.row.id) ? (
                            <CheckBox />
                        ) : (
                            <CheckBoxOutlineBlank />
                        )}
                    </IconButton>
                );
            },
        },
    ];

    useEffect(() => {
        addProducts(selectedFaktur ?? []);
    }, [addProducts, selectedFaktur]);

    return (
        <Box>
            <Typography fontWeight={600}>{namaBarang}</Typography>
            <Typography marginBottom={2}>
                Jumlah barang di keranjang: {qty}
            </Typography>
            <Box
                sx={{
                    height: 240,
                    width: "100%",
                    backgroundColor: "#ffffff",
                    borderRadius: 1,
                    "& .headerColumn": {
                        backgroundColor: "#E4EEE8",
                        // fontSize: "18px",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                        fontWeight: "bold",
                    },
                    "& .MuiDataGrid-columnSeparator": {
                        visibility: "hidden",
                    },
                    "& .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    },
                    "& .MuiDataGrid-iconButtonContainer": {
                        visibility: "visible",
                        width: "0 !important",
                    },
                }}
            >
                <DataGrid
                    // autoHeight
                    rows={filteredFaktur ?? []}
                    components={{
                        ColumnUnsortedIcon: () => {
                            return <ImportExport />;
                        },
                        NoRowsOverlay: NoRowsImage,
                        LoadingOverlay: LoadingTabel,
                    }}
                    columns={columns}
                    rowsPerPageOptions={[10]}
                    pageSize={10}
                    disableColumnMenu
                    disableSelectionOnClick
                    density="compact"
                    // onSelectionModelChange={handleSelectedFakturs}
                    // isRowSelectable={(params) => params.row.stok !== null}
                />
            </Box>
            <Box mt={2}>
                <Typography fontWeight="bold">Faktur Terpilih:</Typography>
                <Typography>
                    {selectedFaktur.map((li) => li.kodeFaktur).join(", ")}
                </Typography>
            </Box>
        </Box>
    );
};

export default PilihFakturStep;
