import React, {
    createContext,
    ReactNode,
    useCallback,
    useMemo,
    useState,
} from "react";
import { ItemSatuan, DetailBundling, ModalStokField } from "../constants/types";

interface State {
    isOpenModal: boolean;
    dataStok?: ModalStokField;
    openModal: (data?: ModalStokField) => void;
    closeModal: () => void;
}

interface IStokModalContext {
    children: ReactNode;
}

const StokModalContext = createContext<State | undefined>(undefined);

const StokModalProvider = ({ children }: IStokModalContext) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [dataStok, setDataStok] = useState<ModalStokField>();

    const openModal = useCallback(
        (data?: ModalStokField) => {
            if (data) {
                setDataStok((prev) => ({
                    ...prev,
                    ...data,
                    // favorite: data.favorite === 1 ? true : false
                }));
            }

            setIsOpenModal(true);
        },
        [],
    );

    const closeModal = useCallback(() => {
        setIsOpenModal(false);
        setDataStok(undefined);
    }, []);

    const value = useMemo(
        () => ({
            isOpenModal,
            dataStok,
            openModal,
            closeModal,
        }),
        [isOpenModal, dataStok, openModal, closeModal],
    );

    return (
        <StokModalContext.Provider value={value}>
            {children}
        </StokModalContext.Provider>
    );
};

const useStokModal = () => {
    const context = React.useContext(StokModalContext);
    if (context === undefined) {
        throw new Error("useStokModal must be used within a StokModalProvider");
    }
    return context;
};

export { StokModalContext, StokModalProvider, useStokModal };
