import React, {
    createContext,
    ReactNode,
    useCallback,
    useMemo,
    useState,
} from "react";

interface State {
    closeModalHapusUkm: () => void;
    idUkm: number | undefined;
    isOpenModalHapusUkm: boolean;
    namaUkm: string;
    openModalHapusUkm: (id: number | undefined, nama: string) => void;
}

interface IHapusUkmModalContext {
    children: ReactNode;
}

const HapusUkmModalContext = createContext<State | undefined>(undefined);

const HapusUkmModalProvider = ({ children }: IHapusUkmModalContext) => {
    const [isOpenModalHapusUkm, setIsOpenModalHapusUkm] = useState(false);
    const [idUkm, setIdUkm] = useState<number | undefined>();
    const [namaUkm, setNamaUkm] = useState("");

    const openModalHapusUkm = useCallback(
        (id: number | undefined, nama: string) => {
            setIdUkm(id);
            setNamaUkm(nama);
            setIsOpenModalHapusUkm(true);
        },
        [],
    );

    const closeModalHapusUkm = useCallback(() => {
        setIsOpenModalHapusUkm(false);
    }, []);

    const value = useMemo(
        () => ({
            closeModalHapusUkm,
            idUkm,
            isOpenModalHapusUkm,
            namaUkm,
            openModalHapusUkm,
        }),
        [
            closeModalHapusUkm,
            idUkm,
            isOpenModalHapusUkm,
            namaUkm,
            openModalHapusUkm,
        ],
    );
    return (
        <HapusUkmModalContext.Provider value={value}>
            {children}
        </HapusUkmModalContext.Provider>
    );
};

const useHapusUkmModal = () => {
    const context = React.useContext(HapusUkmModalContext);
    if (context === undefined) {
        throw new Error(
            "useHapusUkmModal must be used within a HapusUkmModalProvider",
        );
    }
    return context;
};

export { HapusUkmModalContext, HapusUkmModalProvider, useHapusUkmModal };
