const toRibuan = (num: number | string) => {
    if (Number(num) >= 0) {
        let number_string = num.toString(),
            split = number_string.split("."),
            sisa = split[0].length % 3,
            rupiah = split[0].substring(0, sisa),
            ribuan = split[0].substring(sisa).match(/\d{1,3}/gi);

        if (ribuan) {
            let separator = sisa ? "." : "";
            rupiah += separator + ribuan.join(".");
        }
        rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
        return (num = rupiah);
    } else {
        let number_string = num.toString(),
            split = number_string.slice(1, number_string.length).split("."),
            sisa = split[0].length % 3,
            rupiah = split[0].substring(0, sisa),
            ribuan = split[0].substring(sisa).match(/\d{1,3}/gi);

        if (ribuan) {
            let separator = sisa ? "." : "";
            rupiah += separator + ribuan.join(".");
        }
        rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
        return (num = "-" + rupiah);
    }
};

export default toRibuan;
