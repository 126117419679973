import React from "react";
import Ulo from "../../assets/logo/ulo_cr.png";

interface ILogoUloProps {
  width?: string | number | undefined;
  height?: string | number | undefined;
}

const LogoUlo = ({ width, height }: ILogoUloProps) => {
  return <img src={Ulo} alt="Logo Ulo" width={width} height={height} />;
};

export default LogoUlo;
