import { useQuery } from "react-query";
import { GetLayananResponse } from "../../constants/types";
import axios from "../axios";

const handleRequest = async () => {
    const { data } = await axios.get<GetLayananResponse>(`/api/layanan`);
    return data.data;
};

export default function useLayanan() {
    return useQuery<GetLayananResponse["data"]>(["listLayanan"], () =>
        handleRequest(),
    );
}
