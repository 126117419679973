import { Box, Button, Card, Stack, Typography } from "@mui/material";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEditKategoriModal } from "../../context/editKategoriModalContext";
import { useHapusKategoriModal } from "../../context/hapusKategoriModalContext";

interface ICardKategoriProps {
    id: number | string;
    nama: string;
}

const CardKategori = ({ id, nama }: ICardKategoriProps) => {
    const { openModalEditKategori } = useEditKategoriModal();
    const { openModalHapusKategori } = useHapusKategoriModal();

    return (
        <Card
            variant="outlined"
            sx={{
                marginBottom: 1,
                padding: 2,
            }}
        >
            <Stack
                direction="column"
                spacing={1}
                justifyContent="space-between"
                marginTop={1}
            >
                <Typography variant="subtitle2" component="div">
                    Nama Kategori:{" "}
                    <Typography variant="body1" fontWeight="bold">
                        {nama}
                    </Typography>
                </Typography>
                <Box
                    display="flex"
                    alignItems="flex-end"
                    justifyContent="flex-end"
                    flexDirection="row"
                >
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="outlined"
                            onClick={() =>
                                openModalEditKategori(Number(id), nama)
                            }
                            size="small"
                            sx={{
                                minWidth: "unset",
                                padding: "6px",
                                width: "fit-content",
                            }}
                        >
                            <EditIcon />
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => openModalHapusKategori(id, nama)}
                            size="small"
                            color="error"
                            sx={{
                                minWidth: "unset",
                                padding: "6px",
                                width: "fit-content",
                            }}
                        >
                            <DeleteIcon />
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        </Card>
    );
};

export default CardKategori;
