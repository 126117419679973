import React, {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";

interface State {
  closeModalLogout: () => void;
  openModalLogout: () => void;
  isOpenModalLogout: boolean;
}

interface ILogoutModalContext {
  children: ReactNode;
}

const LogoutModalContext = createContext<State | undefined>(undefined);

const LogoutModalProvider = ({ children }: ILogoutModalContext) => {
  const [isOpenModalLogout, setIsOpenModalLogout] = useState(false);

  const openModalLogout = useCallback(() => {
    setIsOpenModalLogout(true);
  }, []);

  const closeModalLogout = useCallback(() => {
    setIsOpenModalLogout(false);
  }, []);

  const value = useMemo(
    () => ({ isOpenModalLogout, openModalLogout, closeModalLogout }),
    [closeModalLogout, isOpenModalLogout, openModalLogout]
  );
  return (
    <LogoutModalContext.Provider value={value}>
      {children}
    </LogoutModalContext.Provider>
  );
};

const useLogoutModal = () => {
  const context = React.useContext(LogoutModalContext);
  if (context === undefined) {
    throw new Error("useLogoutModal must be used within a LogoutModalProvider");
  }
  return context;
};

export { LogoutModalContext, LogoutModalProvider, useLogoutModal };
