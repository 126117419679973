import * as React from "react";
import { SVGProps } from "react";

const ChevronLeft = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="24px"
        height="24px"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="m15 18-6-6 6-6"
            stroke="#fff"
            strokeWidth={1.5}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default ChevronLeft;
