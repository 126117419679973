import React from "react";
import { Box } from "@mui/material";
import logoUloGif from "../../assets/images/LoadingUlo.gif";

interface ISpinnerProps {}

const Spinner = (props: ISpinnerProps) => {
    return (
        <Box
            sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
            }}
        >
            <img
                src={logoUloGif}
                height="100px"
                width="100px"
                style={{ objectFit: "contain" }}
                alt="loading..."
            />
        </Box>
        // <CircularProgress
        //   sx={{
        //     position: "absolute",
        //     top: "50%",
        //     left: "50%",
        //     transform: "translate(-50%, -50%)",
        //   }}
        // />
    );
};

export default Spinner;
