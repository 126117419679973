import { useQuery } from "react-query";
import { ParamsGrafikPenjualan, GetGrafikPenjualanResponse } from "../../constants/types";
import axios from "../axios";

const queryString = require("query-string");

const handleRequest = async (params: ParamsGrafikPenjualan) => {
    const apiGetGrafikPenjualan = queryString.stringifyUrl({
        url: "api/laporan/grafik",
        query: {
            ukmId: params.ukmId,
            tanggalAwal: params.tanggalAwal,
            tanggalAkhir: params.tanggalAkhir,
        },
    });
    const { data } = await axios.get<GetGrafikPenjualanResponse>(apiGetGrafikPenjualan);
    return data.data;
};

export default function useGrafikPenjualan(params: ParamsGrafikPenjualan) {
    return useQuery<GetGrafikPenjualanResponse["data"]>(["grafikPenjualan", params], () => handleRequest(params));
}
