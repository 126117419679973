import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PaymentRouteParams } from "../../constants/types";
import VirtualAccount from "../../components/Payment/PaymentMethod/VirtualAccount/VirtualAccount";

enum PaymentType {
    VA = "va",
    WALLET = "wallet",
    TRANSFER = "transfer",
}

const PaymentVAPage = () => {
    let { type } = useParams<PaymentRouteParams>();
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        console.log("mounted");
        setIsMounted(true);
    }, []);

    if (type === PaymentType.VA) {
        return <VirtualAccount isMounted={isMounted} />;
    }

    return null;
};

export default PaymentVAPage;
