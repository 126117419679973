import React from "react";
import VerifikasiEmail from "../../components/VerifikasiEmail/VerifikasiEmail";

interface IVerifikasiEmailPageProps {}

const VerifikasiEmailPage = (props: IVerifikasiEmailPageProps) => {
    return <VerifikasiEmail />;
};

export default VerifikasiEmailPage;
