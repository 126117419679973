import { useQuery } from "react-query";
import { IncrementSKUResponse, ParamsGetIncrementSKU } from "../../constants/types";
import axios from "../axios";

const queryString = require("query-string");

const handleRequest = async (params: ParamsGetIncrementSKU) => {
    // const { data } = await axios.get<IncrementSKUResponse>("/api/incrementsku");
    const apiGetIncrementSKU = queryString.stringifyUrl({
        url: "api/incrementsku",
        query: {
            ukmId: params.ukmId,
        },
    });
    const { data } = await axios.get<IncrementSKUResponse>(apiGetIncrementSKU);
    return data.data;
};

export default function useIncrementSKU(params: ParamsGetIncrementSKU) {
    return useQuery<IncrementSKUResponse["data"]>(["incrementSKU", params], () => handleRequest(params));
}