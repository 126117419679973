export interface IStokBarang {
    id: number;
    namaBarang: string;
    infoBarang: {
        nama: string;
        deskripsi?: string;
        url: string;
    };
    kategori: string;
    harga: number;
    jumlahStok: number;
    SKU: string;
    url: string;
}

export interface IKategori {
    id: number;
    nama: string;
}

export interface IInvoice {
    id: string;
    nomorInvoice: string;
    saldo: number;
    namaToko: string;
    alamatToko: string;
    email: string;
    noTelp: string;
    tanggalTransaksi: string;
    barang: {
        id: string;
        namaBarang: string;
        qty: number;
        harga: number;
    }[];
}

export interface LoginBody {
    email: string;
    password: string;
}

export interface RegistrasiForm {
    nama: string;
    username: string;
    email: string;
    no_telp: string;
    password: string;
    passwordUlang: string;
    referral: string;
    namaToko: string;
    alamat: string;
    provinsi?: {
        id: number;
        nama: string;
    };
    kota?: {
        id: number;
        nama: string;
        provinsiId: number;
        provinsiNama: string;
    };
}

export interface AuthState {
    isAuthenticated: boolean;
    isLoading: boolean;
    isRegistered: boolean;
    checkToken: (token: string) => void;
    login: (values: LoginBody) => void;
    logout: () => void;
    ukmIdUser: number[] | undefined;
    register: (values: RegistrasiForm) => void;
    handleRegistered: () => void;
    handleAuthenticated: (value: boolean) => void;
    setRegistered: React.Dispatch<React.SetStateAction<boolean>>;
    handleSetToken: (token: any) => Promise<void>;
    loginCount: number;
    resetLoginCount: () => void;
}

export interface CheckToken {
    code: number;
    message: string;
    data: string;
}

export interface LogoutResponse {
    code: number;
    message: string;
}

export interface LoginResponse {
    code: number;
    message: string;
    data: {
        token: string;
    };
}

export interface RegistrasiResponse {
    code: number;
    message: string;
    data: {
        nama: string;
        username: string;
        email: string;
        alamat: string;
        no_telp: string;
        role: string;
        status: number;
        gambar: string | null;
        updated_at: string;
        created_at: string;
        id: number;
        token: string;
    };
}

export interface ErrorsFieldResponse {
    field: string;
    message: string;
}

export interface ErrorResponse {
    code: number;
    message: string;
    data: {
        errors: {
            field: string;
            message: string;
        };
    };
}

export type DefaultResponse = {
    code: number;
    message: string;
};

export type ItemContent = {
    id: number;
    nomer: number;
    nama: string;
    deskripsi: string | null;
    harga: number;
    sku: string;
    jumlah_stok: number | null;
    kategori: number;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
    kategoriwa: null | {
        id: number;
        nama: string;
        created_at: string | null;
        updated_at: string | null;
        deleted_at: string | null;
    };
};

export type ItemResponse = ItemContent[];

export type UserResponse = {
    code: number;
    data: {
        userId: number;
        namaLengkap: string;
        username: string;
        role: {
            id: string;
            nama: string;
        };
        isActive: number;
        aksesTransaksi: number;
        aksesStok: number;
        aksesLaporan: number;
        email: string;
        gambar: string | null;
        noTelp: string | null;
        ukmId: number[];
        referral: string | null;
        akses: {
            transaksi: number[];
            stok: number[];
            laporan: number[];
        };
        countProUkm: number;
    };
};

export type ParamsGetItem = {
    ukmId: number | undefined;
    search: string;
    kategori: string;
    size: number;
    isShow: number;
    page: number;
};

export type GetItemResponse = {
    code: number;
    message: string;
    data: {
        ukmId: number | undefined;
        totalElements: number;
        page: number;
        lastPage: boolean;
        size: number;
        nextId: number;
        content: ContentGetItemResponse[];
        totalItem: number;
    };
};

export type ItemSatuan = {
    id: number;
    namaBarang: string;
    deskripsiBarang: string;
    sku: string;
    harga: number;
};

export type DetailBundling = {
    id: number;
    bundlingId: number;
    namaBarang: string;
    deskripsiBarang: string;
    sku: string;
    harga: number;
    stok: number;
    jumlahYangDiambil: number;
    faktur: {
        id: number;
        faktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
    newFaktur?: IFaktur[];
};

export type ContentGetItemResponse = {
    id: number;
    nomer: number;
    namaBarang: string;
    deskripsi: string;
    sku: string;
    kategori: {
        id: number;
        nama: string;
    };
    gambar: string;
    harga: number;
    isActiveStok: boolean;
    favorite: number;
    stok: number;
    isBundling: number;
    itemSatuan: ItemSatuan[] | null;
    detailBundling: DetailBundling[] | [];
    isShow: number | string;
    hpp: {
        id: number;
        faktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
    expAt: string;
    hargaMultiple: { id: number; harga: string; keterangan: string }[];
};

export type JenisItem = {
    itemId: number;
    namaItem: string;
    skuItem: string;
    jumlahItem: number;
    hargaSatuan: number;
    hargaTotal: number;
};

export type InputPembayaranBody = {
    ukmId: number;
    jenisItem: JenisItem[];
    userInput: string;
    totalHarga: number;
    diskon: number;
    totalPembayaran: number;
    uangYangDibayar: number;
    kembalian: number;
};

export type InputPembayaranResponse = {
    code: number;
    message: string;
    data: {
        nomorInvoice: string;
        jenisItem: JenisItem[];
        totalPembayaran: number;
        diskon: number;
        totalHarga: number;
        encrypt: string;
    };
};
export type ParamsGetKategori = {
    ukmId: number | undefined;
    search: string;
    size: number;
    page: number;
};

export type GetKategoriResponse = {
    code: number;
    message: string;
    data: {
        ukmId: number | undefined;
        totalElements: number;
        page: number;
        lastPage: number;
        content: ContentGetKategoriResponse[];
    };
};

export type ContentGetKategoriResponse = {
    id: number;
    nama: string;
};

export type AddItemBody = {
    id: number;
    ukmId: number | undefined;
    namaBarang: string;
    deskripsiBarang: string;
    sku: string;
    gambar: File | null | string;
    kategoriBaru: string;
    kategoriId: IOptions;
    hargaBeli: number;
    harga: number;
    isActiveStok: boolean;
    jumlahStok: number | null;
    isKategoriBaru: boolean;
    favorite: boolean;
    isHPP: boolean;
    hpp: number;
    isShow?: boolean;
    multipleHarga: {
        harga: number | string;
        keterangan: string | null;
    }[];
};

export type AddItemResponse = {
    code: number;
    message: string;
    data: AddItemBody;
};

export type ParamsGetLaporanTotal = {
    ukmId: number | undefined;
    tanggalAwal: string;
    tanggalAkhir: string;
};

export type GetLaporanTotalResponse = {
    code: number;
    message: string;
    data: {
        totalPendapatan: number;
        totalTransaksi: number;
        jumlahStokTerjual: number;
    };
};

export type ParamsGetProdukTerjual = {
    search: string;
    size: number;
    page: number;
    ukmId: number | undefined;
    tanggalAwal: string;
    tanggalAkhir: string;
};

export type ContentGetProdukTerjual = {
    id: number;
    namaItem: string;
    skuItem: string;
    jumlahItem: string;
    totalHarga: string;
};

export type GetProdukTerjualResponse = {
    code: number;
    message: string;
    data: {
        ukmId: string;
        totalElements: number;
        page: number;
        lastPage: boolean;
        content: ContentGetProdukTerjual[];
    };
};

export type AddKategoriBody = {
    ukmId: number;
    namaKategori: string;
};

export type AddKategoriResponse = {
    code: number;
    message: string;
};

export type EditKategoriBody = {
    namaKategori: string;
};

export type ModalStokField = {
    id: number;
    namaBarang: string;
    deskripsiBarang: string;
    kategoriId: IOptions;
    harga: number;
    sku: string;
    jumlahStok: number;
    isActiveStok: boolean;
    itemSatuan: ItemSatuan[] | null;
    isBundling: number;
    detailBundling: DetailBundling[] | [];
    favorite: boolean;
    isShow?: boolean;
    hargaMultiple: { id: number; harga: number; keterangan: string }[];
};

export type ProductsBundling = {
    itemId: number;
    jumlahItem: number;
};

export type AddProductBundling = {
    ukmId: number;
    namaBundling: string;
    deskripsiBundling?: string;
    kategoriBundling: string;
    sku: string;
    gambar: File | null;
    item: ProductsBundling[];
    hargaBundling: number;
    isActiveStok: boolean;
    jumlahStok: number;
};

export type SelectedProductBundling = {
    id: number;
    nama: string;
    harga: number;
    stok: number;
};

export type BundlingValues = {
    id: number | undefined;
    item: ProductsBundling[];
    namaBundling: string;
    deskripsiBundling: string;
    kategoriBundling: string | number;
    sku: string;
    hargaBundling: string;
    isActiveStok: boolean;
    jumlahStok: number;
    isKategoriBaru: boolean;
    kategoriBaru: string;
    gambar: File | null;
    favorite: boolean;
    isShow?: boolean;
    multipleHarga: {
        harga: number | string | undefined;
        keterangan: string | null;
    }[];
};

export type AddBundlingResponse = {
    code: number;
    message: string;
    data: {
        ukmId: number;
        namaBundling: string;
        deskripsiBundling: string;
        sku: string;
        kategoriBundling: string;
        gambar: string | null;
        hargaBundling: number;
        isActiveStok: 0 | 1; // 0 = false, 1 = true
        jumlahStok: number;
    };
};

export type ParamsGetListInvoice = {
    ukmId: number | undefined;
    search: string;
    size: number;
    page: number;
    tanggalAwal: string;
    tanggalAkhir: string;
    sales: string;
    kasir: string;
    metodeId: string;
    voidInvoice: string;
};

export type ContentGetListInvoice = {
    id: number;
    tanggal: string;
    nomorInvoice: string;
    hargaPenjualan: number;
    uangYangDibayar: number;
    kembalian: number;
    isReverted: number;
    diskon: number;
    pajak: number;
    kasir: string;
    sales: string | null;
    metode: string | null;
    metodeArr: {
        namaMetode: string | null;
        charge: {
            mode: string | null;
            charge: string | null;
        };
        noTujuan: string | null;
        namaPemegang: string | null;
        gambar: string | null;
    };
    keterangan: string | null;
    faktur: {
        idFaktur: number;
        jumlah: number;
    };
};

export type GetListInvoiceResponse = {
    code: number;
    message: string;
    data: {
        ukmId: string;
        totalElements: number;
        page: number;
        lastPage: boolean;
        content: ContentGetListInvoice[];
        totalHarga: string;
    };
};

export type ParamsGrafikPenjualan = {
    ukmId: number | undefined;
    tanggalAwal: string;
    tanggalAkhir: string;
};

export type GetGrafikPenjualanResponse = {
    code: number;
    message: string;
    data: {
        labels: string[];
        data: {
            totalPendapatan: number[];
            totalTransaksi: number[];
            jumlahProdukTerjual: number[];
        };
    };
};

export type DaftarItemListInvoice = {
    nomer: number;
    idItem: number;
    namaItem: string;
    skuItem: string;
    jumlahItem: number;
    hargaSatuan: number;
    hargaTotal: number;
    faktur: {
        idFaktur: number;
        jumlah: number;
    }[];
};

export type GetDetailInvoiceResponse = {
    code: number;
    message: string;
    data: {
        tanggalTransaksi: string;
        nomorInvoice: string;
        daftarItem: DaftarItemListInvoice[];
        totalPembayaran: number;
        diskon: number;
        pajak: number;
        totalHarga: number;
        uangYangDibayar: number;
        kembalian: number;
        kasir: string;
        sales: string;
        metode: string | null;
        metodeArr: {
            namaMetode: string | null;
            charge: {
                mode: string | null;
                charge: number | null;
            };
            noTujuan: string | null;
            namaPemegang: string | null;
            gambar: string | null;
        };
        keterangan: string | null;
        encrypt: string | null;
    };
};
export type IncrementSKUResponse = {
    code: number;
    message: string;
    data: {
        urutanke: string;
    };
};

export type ParamsGetIncrementSKU = {
    ukmId: number | undefined;
};

export type ErrorFieldResponse = {
    message: string;
    errors: {
        [key: string]: string[];
    };
};

export type ContentUkm = {
    id: number;
    namaToko: string;
    gambarToko: string | null;
    gambarTokoBW: string | null;
    alamatToko: string;
    noTelp: string;
    warna: string;
    deskripsi: string | null;
    besarUsaha: string;
    jenisUsaha: string;
    omset: number;
    pajak: number | null;
    isPro: number;
    lewatTempoPro: number;
    isActive: number | null;
    pembayaranBerikutnya: string | null;
    statusPembayaran: "ACTIVE" | "PAID" | "EXPIRED" | "-" | null;
    statusToko: 0 | 1 | 2 | 3 | 4 | 99;
    expiredTime: string;
    orderId: string;
    bank: string;
    layanan: {
        id: number;
        deskripsi: string;
        periode: string;
        harga: number;
    };
    limitItem: number | null;
    isTrial: number | null;
    trialTempo: string | null;
    kota: string | null;
    provinsi: string | null;
};

export type ParamsGetUkm = {
    search: string;
    size: number;
    page: number;
};

export type GetUkmResponse = {
    code: number;
    message: string;
    data: {
        totalElements: number;
        page: number;
        lastPage: number;
        content: ContentUkm[];
    };
};

export type ParamsGetUkmIsDueExpired = {
    search: string;
    size: number;
    page: number;
    isDueExpired: number;
};

export type UserValues = {
    namaLengkap: string;
    username: string;
    email: string;
    gambar: File | string | null;
    noTelp: string | null;
};

export type EditUserResponse = {
    code: number;
    message: string;
    data: UserValues;
};

export type PasswordValues = {
    passwordLama: string;
    passwordBaru: string;
    passwordBaruUlang: string;
};

export type ChangePasswordResponse = {
    code: number;
    message: string;
};

export type ModalUkmField = {
    id?: number;
    namaToko: string;
    noTelp: string;
    alamatToko: string;
    warna: string;
    gambarToko?: File | null;
    deskripsi: string | null;
    besarUsaha: string;
    jenisUsaha: string;
    omset: string;
    provinsi?: {
        id: number;
        nama: string;
    };
    kota?: {
        id: number;
        nama: string;
        provinsiId: number;
        provinsiNama: string;
    };
};

export type DownloadKatalogBody = {
    ukmId: number | undefined;
    kategori: string;
    tipe: string;
};

export type EditUkmResponse = {
    code: number;
    message: string;
};

export type GetInfiniteItemResponse = {
    pages: GetItemResponse["data"][];
    pageParams: (number | undefined)[];
};

export type SelectedProductMutasi = {
    id: number;
    nama: string;
    sku: string;
    harga: number;
    stok: number;
    hpp: {
        id: number;
        faktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
};

export type ModalBundlingField = {
    id: number | undefined;
    item: ProductsBundling[];
    namaBundling: string;
    deskripsiBundling: string;
    kategoriBundling: string | number;
    sku: string;
    hargaBundling: string;
    isActiveStok: boolean;
    jumlahStok: number;
    favorite: boolean;
    isShow: boolean;
    hargaMultiple: { id: number; harga: number; keterangan: string }[];
};

export type DetailMutasi = {
    itemId: number;
    jumlahMasuk: number | null;
    jumlahKeluar: number | null;
    // hargaBeli: number;
    keterangan: string;
};

export type AddMutasiBody = {
    ukmId: number | undefined;
    detail: DetailMutasi[];
};

export type AddMutasiResponse = {
    code: number;
    message: string;
};

export type NestedDetail = {
    isMasuk: string;
    itemId: number;
    jumlahStok: string;
    // hargaBeli: number;
    keterangan: string;
    faktur?: {
        id: number;
        faktur: string;
        hargaBeli: number;
        jumlah: number;
    };
    stokRepack: string | null;
    itemRepack: IOptions | null;
    hargaBeliRepack: number | null;
};

export type MutasiValues = {
    ukmId: number | undefined;
    detail: {
        nestedDetail: NestedDetail[];
    }[];
};

export type ParamsGetMutasiStok = {
    ukmId: number | undefined;
    search: string;
    size: number;
    page: number;
    tanggalAwal: string;
    tanggalAkhir: string;
};

export type ContentGetMutasiStok = {
    nomor: number;
    itemId: number;
    namaBarang: string;
    sku: string;
    stokAwal: number;
    hargaBeli: number;
    masuk: number;
    keluar: number;
    jual: number;
    stokAkhir: number;
};

export type GetMutasiStokResponse = {
    code: number;
    message: string;
    data: {
        ukmId: string;
        totalElements: number;
        page: number;
        lastPage: boolean;
        content: ContentGetMutasiStok[];
    };
};

export type BuatTokoForm = {
    namaToko: string;
    noTelpToko: string;
    alamatToko: string;
    warna: string;
    deskripsiToko: string;
    jenisUsaha: string;
    besarUsaha: string;
    omzetPerTahun: string;
    logoToko: string | null;
};

export type BuatTokoResponse = {
    code: number;
    message: string;
    data: {
        nama: string;
        alamat: string;
        deskripsi: string | null;
        no_telp: string;
        warna: string;
        jenis_usaha: string;
        besar_usaha: string;
        omset: string;
        status: number;
        gambar: string | null;
        updated_at: string;
        created_at: string;
        id: number;
    };
};

export type NoWaAdminResponse = {
    code: number;
    message: string;
    data: {
        nama: string;
        nomor: string;
    };
};

export type ParamsPostUsersByUkm = {
    ukmId: number | undefined;
    size: number;
    page: number;
    search: string;
};

export type UsersByUkmContent = {
    userId: number;
    namaLengkap: string;
    username: string;
    aksesTransaksi: number;
    aksesStok: number;
    aksesLaporan: number;
    email: string;
    gambar: string | null;
    noTelp: string;
    ukmId: string[];
};

export type PostUsersByUkmResponse = {
    code: number;
    message: string;
    data: {
        totalElements: number;
        page: number;
        lastPage: boolean;
        content: UsersByUkmContent[];
    };
};

export type IOptions = {
    id: string | number;
    nama: string;
};

export type LupaPasswordResponse = {
    code: number;
    message: string;
};

export type GetMetodePembayaranResponse = {
    code: number;
    message: string;
    data: {
        id: number;
        ukmId: { id: number; nama: string };
        namaMetode: string;
        noTujuan: string;
        namaPemegang: string;
        gambar: string | null;
    }[];
};

export type GetFindMetodePembayaranResponse = {
    code: number;
    message: string;
    data: {
        id: number;
        ukmId: number;
        namaMetode: string;
        noTujuan: string;
        namaPemegang: string;
        gambar: string | null;
        charge: number;
    };
};

export type FindCustomStrukResponse = {
    code: number;
    message: string;
    data: {
        html: string | null;
    };
};

export type DataLayanan = {
    id: number;
    nama: string;
    deskripsiLayanan: string;
    harga: number;
    isHide: number;
};

export type GetLayananResponse = {
    code: number;
    message: string;
    data: DataLayanan[];
};

export type DaftarItemListStruk = {
    nomer: number;
    idItem: number;
    namaItem: string;
    skuItem: string;
    jumlahItem: number;
    hargaSatuan: number;
    hargaTotal: number;
};

export type GetDetailStrukResponse = {
    code: number;
    message: string;
    data: {
        tanggalTransaksi: string;
        nomorInvoice: string;
        daftarItem: DaftarItemListStruk[];
        totalPembayaran: number;
        diskon: number;
        pajak: number;
        totalHarga: number;
        uangYangDibayar: number;
        kembalian: number;
        kasir: string;
        sales: string;
        metode: string | null;
        namaUkm: string;
        alamatUkm: string;
        noTelp: string;
        gambarToko: string | null;
        keterangan: string;
    };
};

export type SelectedFakturMutasi = {
    id: number;
    kodeFaktur: string;
    hargaBeli: number;
    jumlahStok: number;
};

export type ParamsGetFakturPembelian = {
    ukmId?: number | string;
    search: string;
    size: number;
    page: number;
};

export type ContentGetFakturPembelianResponse = {
    id: number;
    nomer: number;
    ukm: {
        id: number;
        nama: string;
    };
    stok: {
        id: number;
        nama: string;
        sku: string;
        harga: number;
        jumlah: number;
    };
    faktur: string;
    keterangan: string | null;
    hargaBeli: number;
    jumlah: number;
    terjual: number;
};

export type GetFakturPembelianResponse = {
    code: number;
    message: string;
    data: {
        ukmId?: number[];
        totalElements: number;
        page: number;
        lastPage: boolean;
        size: number;
        nextId: number;
        content: ContentGetFakturPembelianResponse[];
    };
};

export interface ICart {
    id: number;
    namaBarang: string;
    sku?: string | undefined;
    qty: number;
    harga: number;
    url: string;
    jumlahStok?: number | undefined;
    detailBundling: DetailBundling[] | [];
    hpp: {
        id: number;
        faktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
    faktur: IFaktur[];
    multipleHarga: {
        id: number;
        harga: number;
        keterangan: string | null;
    }[];
}

export interface IFaktur {
    fakturId: number;
    kodeFaktur: string;
    jumlahItem: number;
}

export type IDetailFaktur = {
    detail: {
        fakturId: number;
        kodeFaktur: string;
        jumlahStok: string;
    }[];
};

export type GetProvinsiResponse = {
    code: number;
    message: string;
    data: {
        content: {
            id: number;
            nama: string;
        }[];
    };
};

export type GetKotaResponse = {
    code: number;
    message: string;
    data: {
        content: {
            id: number;
            nama: string;
            provinsiId: number;
            provinsiNama: string;
        }[];
    };
};

export type GetTimeResponse = {
    abbreviation: string;
    client_ip: string;
    datetime: string;
    day_of_week: number;
    day_of_year: number;
    dst: boolean;
    dst_from: string | null;
    dst_offset: number;
    dst_until: string | null;
    raw_offset: number;
    timezone: string;
    unixtime: number;
    utc_datetime: string;
    utc_offset: string;
    week_number: number;
};

export interface Keterangan {
    jumlah: number;
    keterangan: string;
    tanggal: string;
    invoice: string;
    user: string | null;
}

export enum TipeStruk {
    Mobile = "MOBILE",
    PC = "PC",
}

export type PaymentRouteParams = {
    orderId: string;
    layananId: string;
    ukmId: string;
    type: string;
};

export type DataBanks = {
    method: string;
    payments: string[];
};

export type GetListBanksResponse = {
    code: number;
    message: string;
    data: {
        method: string;
        code: string;
        payments: string[];
        info: {
            nama: string;
            rekening: string;
            gambar: string;
        } | null;
    }[];
};

export type AddPaymentVAResponse = {
    code: number;
    message: string;
    data: {
        response_code: string;
        response_message: string;
        transaction_id: string;
        external_id: string;
        order_id: string;
        currency: string;
        transaction_status: string;
        payment_method: string;
        payment_channel: string;
        callback_url: string;
        payment_details: {
            payment_system: string;
            billing_name: string;
            va_number: string;
            amount: number;
            total_fee_amount: number;
            total_feenore_amount: number;
            total_promo_amount: number;
            total_amount: number;
            is_multi_use: boolean;
            layanan_amount: number;
            is_customer_paying_fee: boolean;
            expired_time: string;
            transaction_description: string;
        };
        customer_details: {
            email: string;
            full_name: string;
        };
        item_details: {
            item_id: string;
            name: string;
            amount: number;
            qty: number;
            description: string | null;
        }[];
    };
};

export type GetInquiryVAResponse = {
    code: number;
    message: string;
    data: {
        response_code: string;
        transaction_id: string;
        external_id: string;
        order_id: string;
        currency: string;
        transaction_status: string;
        transaction_time: string;
        payment_method: string;
        payment_channel: string;
        callback_url: string;
        customer_details: {
            email: string;
            full_name: string;
        };
        item_details: {
            item_id: string;
            name: string;
            amount: number;
            qty: number;
            description: string | null;
        }[];
        payment_history: [];
        payment_details: {
            payment_system: string;
            billing_name: string;
            va_number: string;
            amount: number;
            total_fee_amount: number;
            total_promo_amount: number;
            total_amount: number;
            total_paid_amount: number;
            is_multi_use: false;
            is_customer_paying_fee: false;
            expired_time: string;
            transaction_description: string;
        };
    };
};
export type GetStatusPembayaranResponse = {
    code: number;
    message: string;
    data: {
        transaction_id: string;
        transaction_status: string;
        status: number;
    };
};

export type CancelPembayaranVAResponse = {
    code: number;
    message: string;
    data: {
        transaction_id: string;
        external_id: string;
        order_id: string;
        payment_method: string;
        payment_channel: string;
        transaction_status: string;
        response_code: string;
        pembayaranId: number;
    };
};
export type ParamsKesimpulan = {
    ukmId: number | undefined;
    tanggal: string;
};

export type GetKesimpulanResponse = {
    code: number;
    message: string;
    data: {
        totalTransaksi: number;
        totalItemTerjual: number;
        totalPajak: number;
        totalGross: number;
        totalDiskon: number;
        totalVoid: number;
        totalItemVoid: number;
        totalNet: number;
        items: {
            nama: string | null;
            sku: string | null;
            jumlah: string;
        }[];
        metodePembayarans: {
            nama: string | null;
            total: string;
        }[];
    };
};
