import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect, useMemo, useState } from "react";
import axios from "../../services/axios";
import Swal from "sweetalert2";
import { LupaPasswordResponse } from "../../constants/types";
import { LoadingButton } from "@mui/lab";
import defaultAxios, { AxiosError } from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { captchaSiteKey2 } from "../../constants/common";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import LogoUlo from "../LogoUlo/LogoUlo";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import CloseIcon from "@mui/icons-material/Close";

interface IModalLupaPasswordProps {
    open: boolean;
    onClose: () => void;
}

interface ILupaPasswordInput {
    email: string;
}

const schema = yup
    .object({
        email: yup
            .string()
            .email("Format email salah")
            .required("Kolom wajib diisi"),
    })
    .required();

const ModalLupaPassword = ({ open, onClose }: IModalLupaPasswordProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [token, setToken] = useState<string | null>();
    const [isHuman, setIsHuman] = useState(false);

    const pathname = window.location.pathname;

    useEffect(() => console.log("pathname", pathname), [pathname]);

    const initialValues = useMemo(
        () => ({
            email: "",
        }),
        [],
    );

    const {
        handleSubmit,
        setError,
        control,
        formState: { errors },
    } = useForm<ILupaPasswordInput>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const handleErrorReponse = (error: any) => {
        if (defaultAxios.isAxiosError(error)) {
            const serverError = error as AxiosError<any>;
            if (serverError && serverError?.response) {
                const fieldError = serverError?.response?.data;
                if (fieldError?.errors) {
                    Object.keys(fieldError.errors).forEach((key) => {
                        const errorMessage = fieldError.errors[key];
                        setError(key as keyof typeof initialValues, {
                            type: "manual",
                            message: errorMessage[0],
                        });
                    });
                } else {
                    Swal.fire({
                        title: `${fieldError.message}`,
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "error",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                }
            }
        }
    };

    const onSubmit = async (values: ILupaPasswordInput) => {
        console.log("Email: ", values);
        setIsButtonLoading(true);
        if (isHuman && token) {
            try {
                const formData = new FormData();
                formData.append("email", values.email);
                const { data } = await axios.post<LupaPasswordResponse>(
                    `/api/reqgantipass`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );
                if (data.code === 200) {
                    // assignWords("Kata sandi berhasil diubah");
                    // openAlert();
                    setIsButtonLoading(false);
                    Swal.fire({
                        title: `${data.message}`,
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                } else {
                    setIsButtonLoading(false);
                    Swal.fire({
                        title: `${data.message}`,
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "error",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                }
            } catch (error) {
                setIsButtonLoading(false);
                handleErrorReponse(error);
                console.error(error);
            }
        } else {
            setIsButtonLoading(false);
            Swal.fire({
                title: "Terjadi Kesalahan!",
                text: `Tolong centang box reCAPTCHA`,
                icon: "error",
                confirmButtonColor: "#45A779",
                customClass: {
                    container: "my-swal",
                },
            });
        }
    };

    return (
        <Dialog
            maxWidth="sm"
            fullScreen={isPhoneScreen}
            fullWidth
            open={open}
            onClose={onClose}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 2,
                },
            }}
        >
            <DialogTitle>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ marginBottom: 6 }}>
                <Stack spacing={4.5} alignItems="center">
                    <Link
                        href="https://ukmlokal.com/"
                        rel="noreferrer"
                        target="_blank"
                    >
                        <LogoUlo width="100px" />
                    </Link>
                    <Stack spacing={1}>
                        <Typography
                            textAlign="center"
                            variant={isPhoneScreen ? "h6" : "h5"}
                            fontSize={28}
                            fontWeight="bold"
                        >
                            Lupa Kata Sandi?
                        </Typography>
                        <Typography
                            variant="body2"
                            textAlign="center"
                            width={isPhoneScreen ? 300 : 375}
                        >
                            Masukkan alamat email anda untuk mendapatkan tautan
                            atur ulang kata sandi
                        </Typography>
                    </Stack>
                    <form
                        style={{
                            width: "100%",
                            maxWidth: "375px",
                            minWidth: "300px",
                        }}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="body2"
                                    fontWeight={500}
                                    marginBottom={1}
                                >
                                    Email
                                </Typography>
                                <Controller
                                    name="email"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControl
                                            fullWidth
                                            variant="outlined"
                                            error={Boolean(errors.email)}
                                        >
                                            <OutlinedInput
                                                id="email"
                                                type="email"
                                                autoComplete="email"
                                                autoFocus={isLaptopScreen}
                                                placeholder="Contoh: ulo@gmail.com"
                                                {...field}
                                            />
                                            <FormHelperText>
                                                {errors.email
                                                    ? errors.email.message
                                                    : " "}
                                            </FormHelperText>
                                        </FormControl>
                                    )}
                                    rules={{ required: "Email required" }}
                                />
                            </Grid>
                        </Grid>
                        <Box
                            marginTop={1}
                            display="flex"
                            justifyContent="center"
                        >
                            <ReCAPTCHA
                                sitekey={captchaSiteKey2}
                                onChange={(value) => {
                                    setToken(value);
                                    setIsHuman(true);
                                }}
                                onExpired={() => {
                                    setToken(null);
                                    setIsHuman(false);
                                }}
                                hl="id"
                            />
                        </Box>
                        <Stack direction="row" justifyContent="center">
                            <LoadingButton
                                loading={isButtonLoading}
                                loadingPosition="center"
                                type="submit"
                                size="large"
                                variant="contained"
                                sx={{ marginTop: 4.5 }}
                            >
                                Kirim ke Email
                            </LoadingButton>
                        </Stack>
                    </form>
                    <Box
                        display="flex"
                        justifyContent="center"
                        marginTop={3}
                        sx={{ width: "100%" }}
                    >
                        <Typography
                            component="div"
                            variant="body2"
                            display="block"
                        >
                            Kembali ke{" "}
                            <Link
                                display="inline"
                                onClick={onClose}
                                variant="body2"
                                fontWeight={500}
                                sx={{ cursor: "pointer" }}
                            >
                                {pathname === "/" ? "Login" : "Pengaturan"}
                            </Link>
                        </Typography>
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ModalLupaPassword;
