import React from "react";
import Login from "../../components/Login/Login";

interface ILoginPageProps {}

const LoginPage = (props: ILoginPageProps) => {
  return <Login />;
};

export default LoginPage;
