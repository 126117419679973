import { useQuery } from "react-query";
import { ParamsGetKategori, GetKategoriResponse } from "../../constants/types";
import axios from "../axios";

const queryString = require("query-string");

const handleRequest = async (params: ParamsGetKategori) => {
    const apiGetKategori = queryString.stringifyUrl({
        url: "api/kategori",
        query: {
            ukmId: params.ukmId,
            search: `${params.search}`,
            size: params.size,
            page: params.page,
        },
    });
    const { data } = await axios.get<GetKategoriResponse>(apiGetKategori);
    return data.data;
};

export default function useKategori(params: ParamsGetKategori) {
    return useQuery<GetKategoriResponse["data"]>(
        ["kategori", params],
        () => handleRequest(params),
        {
            enabled: !!params.ukmId,
        },
    );
}
