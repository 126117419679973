import React, { useCallback, useState } from "react";
import Swal from "sweetalert2";
import axios from "../../services/axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import defaultAxios, { AxiosError } from "axios";
import { LoadingButton } from "@mui/lab";
import { useKategoriModal } from "../../context/kategoriModalContext";
import { useHapusKategoriModal } from "../../context/hapusKategoriModalContext";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";

interface IModalDeleteKategoriProps {
    refetch: () => void;
}

const ModalDeleteKategori = ({ refetch }: IModalDeleteKategoriProps) => {
    const { openModalKategori } = useKategoriModal();
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const {
        isOpenModalHapusKategori,
        closeModalHapusKategori,
        namaKategori,
        idKategori,
    } = useHapusKategoriModal();

    const handleDelete = async (id: string | number | undefined) => {
        setIsButtonLoading(true);
        try {
            const { data } = await axios.delete(`/api/kategori/${id}`);
            if (data.code === 200) {
                // assignWords("Kategori " + namaKategori + " berhasil dihapus!");
                // openAlert();
                Swal.fire({
                    title: "Kategori " + namaKategori + " berhasil dihapus!",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                refetch();
            }
            openModalKategori();
            setIsButtonLoading(false);
            closeModalHapusKategori();
        } catch (error) {
            // console.error(error);
            setIsButtonLoading(false);
            handleErrorResponse(error);
        }
    };

    const handleErrorResponse = useCallback(
        (error) => {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<any>;
                if (serverError && serverError.response) {
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `${serverError.response.data.message}`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModalHapusKategori();
                } else {
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `Telah Terjadi Kesalahan`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModalHapusKategori();
                }
            }
        },
        [closeModalHapusKategori],
    );

    return (
        <Dialog
            maxWidth="xs"
            fullWidth={true}
            open={isOpenModalHapusKategori}
            onClose={closeModalHapusKategori}
            PaperProps={{
                sx: { borderRadius: 1 },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton
                        onClick={() => {
                            closeModalHapusKategori();
                            openModalKategori();
                        }}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Hapus Kategori
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {namaKategori} akan dihapus secara permanen. Yakin ingin
                    menghapus?
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <LoadingButton
                    loading={isButtonLoading}
                    loadingPosition="center"
                    variant="outlined"
                    onClick={() => handleDelete(idKategori)}
                    color="error"
                >
                    Hapus
                </LoadingButton>
                <Button
                    variant="contained"
                    onClick={() => {
                        closeModalHapusKategori();
                        openModalKategori();
                    }}
                    color="error"
                >
                    Tidak
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalDeleteKategori;
