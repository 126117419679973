import { useQuery } from "react-query";
import { GetTimeResponse } from "../../constants/types";
import axios from "axios";

const handleRequest = async () => {
    const { data } = await axios.get<GetTimeResponse>(
        "https://worldtimeapi.org/api/timezone/Asia/Jakarta",
    );
    return data;
};

export default function useTime() {
    return useQuery(["time"], () => handleRequest());
}
