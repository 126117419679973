import React, {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";

interface State {
  isOpenModalKatalog: boolean;
  openModalKatalog: () => void;
  closeModalKatalog: () => void;
}

interface IKatalogModalContext {
  children: ReactNode;
}

const KatalogModalContext = createContext<State | undefined>(undefined);

const KatalogModalProvider = ({ children }: IKatalogModalContext) => {
  const [isOpenModalKatalog, setIsOpenModalKatalog] = useState(false);

  const openModalKatalog = useCallback(() => {
    setIsOpenModalKatalog(true);
  }, []);

  const closeModalKatalog = useCallback(() => {
    setIsOpenModalKatalog(false);
  }, []);

  const value = useMemo(
    () => ({ isOpenModalKatalog, openModalKatalog, closeModalKatalog }),
    [closeModalKatalog, isOpenModalKatalog, openModalKatalog]
  );

  return (
    <KatalogModalContext.Provider value={value}>
      {children}
    </KatalogModalContext.Provider>
  );
};

const useKatalogModal = () => {
  const context = React.useContext(KatalogModalContext);
  if (context === undefined) {
    throw new Error(
      "useKatalogModal must be used within a KatalogModalProvider"
    );
  }
  return context;
};

export { KatalogModalContext, KatalogModalProvider, useKatalogModal };
