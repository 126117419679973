import { useQuery } from "react-query";
import { FindCustomStrukResponse } from "../../constants/types";
import axios from "../axios";
import qs from "query-string";

const handleRequest = async (ukmId?: number) => {
    const apiGetFindCustomStruk = qs.stringifyUrl({
        url: "api/custom-struk",
        query: {
            ukmId: ukmId,
        },
    });
    const { data } = await axios.get<FindCustomStrukResponse>(
        apiGetFindCustomStruk,
    );
    return data.data;
};

export default function useFindCustomStruk(ukmId?: number) {
    return useQuery<FindCustomStrukResponse["data"]>(
        ["findCustomStruk", ukmId],
        () => handleRequest(ukmId),
        { enabled: !!ukmId },
    );
}
