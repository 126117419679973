import React, {
    createContext,
    ReactNode,
    useCallback,
    useMemo,
    useState,
} from "react";

interface State {
    isOpenModalMutasiStok: boolean;
    openModalMutasiStok: () => void;
    closeModalMutasiStok: () => void;
}

interface IMutasiStokModalContext {
    children: ReactNode;
}

const MutasiStokModalContext = createContext<State | undefined>(undefined);

const MutasiStokModalProvider = ({ children }: IMutasiStokModalContext) => {
    const [isOpenModalMutasiStok, setIsOpenModalMutasiStok] = useState(false);

    const openModalMutasiStok = useCallback(() => {
        setIsOpenModalMutasiStok(true);
    }, []);

    const closeModalMutasiStok = useCallback(() => {
        setIsOpenModalMutasiStok(false);
    }, []);

    const value = useMemo(
        () => ({
            isOpenModalMutasiStok,
            openModalMutasiStok,
            closeModalMutasiStok,
        }),
        [closeModalMutasiStok, isOpenModalMutasiStok, openModalMutasiStok],
    );

    return (
        <MutasiStokModalContext.Provider value={value}>
            {children}
        </MutasiStokModalContext.Provider>
    );
};

const useMutasiStokModal = () => {
    const context = React.useContext(MutasiStokModalContext);
    if (context === undefined) {
        throw new Error(
            "useMutasiStokModal must be used within a MutasiStokModalProvider",
        );
    }
    return context;
};

export { MutasiStokModalContext, MutasiStokModalProvider, useMutasiStokModal };
