export default function scrollToHash() {
    /* Obtain hash from current location (and trim off leading #) */
    const id = window.location.hash.substring(1);

    if (id) {
        /* Find matching element by id */
        const anchor = document.getElementById(id);

        if (anchor) {
            /* Scroll to that element if present */
            anchor.scrollIntoView({ behavior: "smooth" });
        }
    }
}
