import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { defaultColor } from "../../constants/common";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface IThemeDefaultComponentProps {
    children: React.ReactNode;
}

const ThemeDefaultComponent = ({ children }: IThemeDefaultComponentProps) => {
    const loginTheme = createTheme({
        palette: {
            primary: {
                main: defaultColor,
                contrastText: "#fff",
            },
        },
        typography: {
            // fontFamily: font,
            fontWeightMedium: 500,
            fontWeightBold: 700,
        },
        components: {
            MuiButton: {
                defaultProps: {
                    disableElevation: true,
                },
                styleOverrides: {
                    root: {
                        borderRadius: "8px",
                        textTransform: "none",
                        fontSize: 14,
                        fontWeight: 500,
                        padding: "12px 24px",
                        "&.Mui-disabled": {
                            color: "#fff",
                            background: "#CAD9D4",
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        borderRadius: "8px",
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#fff",
                    },
                    inputRoot: {
                        borderRadius: "8px",
                        border: "1px solid #A8B4AF)",
                    },
                    popupIndicator: {
                        "& svg": {
                            "& path": {
                                d: "path('M7.41 8.57996L12 13.17L16.59 8.57996L18 9.99996L12 16L6 9.99996L7.41 8.57996Z')",
                            },
                        },
                    },
                },
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "& .MuiOutlinedInput-root": {
                            border: "1px solid #A8B4AF)",
                            borderRadius: "8px",
                        },
                        "& .MuiInputBase-root": {
                            color: "#464E4B",
                        },
                    },
                },
            },
            MuiSelect: {
                defaultProps: {
                    IconComponent: ExpandMoreIcon,
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    root: ({ _, theme }) => ({
                        width: 42,
                        height: 26,
                        padding: 0,
                        marginRight: 12,
                        marginLeft: 12,
                        "& .MuiSwitch-switchBase": {
                            padding: 0,
                            margin: 2,
                            transitionDuration: "300ms",
                            "&.Mui-checked": {
                                transform: "translateX(16px)",
                                color: "#fff",
                                "& + .MuiSwitch-track": {
                                    backgroundColor: theme.palette.primary.main,
                                    opacity: 1,
                                    border: 0,
                                },
                                "&.Mui-disabled + .MuiSwitch-track": {
                                    opacity: 0.5,
                                },
                            },
                            "&.Mui-focusVisible .MuiSwitch-thumb": {
                                color: "#33cf4d",
                                border: "6px solid #fff",
                            },
                            "&.Mui-disabled .MuiSwitch-thumb": {
                                color:
                                    theme.palette.mode === "light"
                                        ? theme.palette.grey[100]
                                        : theme.palette.grey[600],
                            },
                            "&.Mui-disabled + .MuiSwitch-track": {
                                opacity:
                                    theme.palette.mode === "light" ? 0.7 : 0.3,
                            },
                        },
                        "& .MuiSwitch-thumb": {
                            boxSizing: "border-box",
                            width: 22,
                            height: 22,
                        },
                        "& .MuiSwitch-track": {
                            borderRadius: 26 / 2,
                            backgroundColor: "#E9F2F0",
                            opacity: 1,
                            transition: theme.transitions.create(
                                ["background-color"],
                                {
                                    duration: 500,
                                },
                            ),
                        },
                    }),
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        textTransform: "none",
                        fontWeight: 500,
                    },
                },
            },
            MuiTabs: {
                styleOverrides: {
                    root: ({ _, theme }) => ({
                        "& .MuiTabs-indicator": {
                            height: "4px"
                        }
                    }),
                }
            },
            MuiToggleButton: {
                styleOverrides: {
                    root: ({ _, theme }) => ({
                        borderRadius: "8px",
                        color: theme.palette.primary.main,
                        border: `2px solid ${theme.palette.primary.main}`,
                        "&.Mui-selected": {
                            background: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                            border: `2px solid ${theme.palette.primary.main}`,
                            borderRadius: "8px",
                        },
                    }),
                },
            },
        },
    });
    return (
        <ThemeProvider theme={loginTheme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

export default ThemeDefaultComponent;
