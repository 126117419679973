import React, {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";

interface State {
  closeModalHapusKategori: () => void;
  idKategori: number | string | undefined;
  isOpenModalHapusKategori: boolean;
  namaKategori: string;
  openModalHapusKategori: (id: number | string, nama: string) => void;
}

interface IHapusKategoriModalContext {
  children: ReactNode;
}

const HapusKategoriModalContext = createContext<State | undefined>(undefined);

const HapusKategoriModalProvider = ({
  children,
}: IHapusKategoriModalContext) => {
  const [isOpenModalHapusKategori, setIsOpenModalHapusKategori] =
    useState(false);
  const [idKategori, setIdKategori] = useState<number | string | undefined>();
  const [namaKategori, setNamaKategori] = useState("");

  const openModalHapusKategori = useCallback(
    (id: number | string | undefined, nama: string) => {
      setIdKategori(id);
      setNamaKategori(nama);
      setIsOpenModalHapusKategori(true);
    },
    []
  );

  const closeModalHapusKategori = useCallback(() => {
    setIsOpenModalHapusKategori(false);
  }, []);

  const value = useMemo(
    () => ({
      closeModalHapusKategori,
      idKategori,
      isOpenModalHapusKategori,
      namaKategori,
      openModalHapusKategori,
    }),
    [
      closeModalHapusKategori,
      idKategori,
      isOpenModalHapusKategori,
      namaKategori,
      openModalHapusKategori,
    ]
  );
  return (
    <HapusKategoriModalContext.Provider value={value}>
      {children}
    </HapusKategoriModalContext.Provider>
  );
};

const useHapusKategoriModal = () => {
  const context = React.useContext(HapusKategoriModalContext);
  if (context === undefined) {
    throw new Error(
      "useHapusKategoriModal must be used within a HapusKategoriModalProvider"
    );
  }
  return context;
};

export {
  HapusKategoriModalContext,
  HapusKategoriModalProvider,
  useHapusKategoriModal,
};
