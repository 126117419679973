import {
    Box,
    Button,
    Typography,
    FormControl,
    FormHelperText,
    OutlinedInput,
    useMediaQuery,
    Link,
    IconButton,
    InputAdornment,
    Checkbox,
    FormControlLabel,
    Grid,
    Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuth } from "../../context/authContext";
// import { ReactComponent as LoginImage } from "../../assets/images/LoginImage.svg";
import LoginImage from "../../assets/images/IlustrasiMainMenu.png";
import LogoUlo from "../LogoUlo/LogoUlo";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import ThemeDefaultComponent from "../ThemeDefaultComponent/ThemeDefaultComponent";
import Swal from "sweetalert2";
import Countdown from "react-countdown";
import ModalLupaPassword from "../ModalLupaPassword/ModalLupaPassword";

interface ILoginInputs {
    email: string;
    password: string;
}

const schema = yup
    .object({
        email: yup
            .string()
            // .email("Format email salah")
            .required("Kolom wajib diisi"),
        password: yup.string().required("Kolom wajib diisi"),
    })
    .required();

const Login = () => {
    const moment = require("moment");
    const theme = useTheme();
    const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const { login, isLoading, loginCount, resetLoginCount } = useAuth();
    const [showPassword, setShowPassword] = useState(false);
    const [saveEmail, setSaveEmail] = useState(false);
    const [initEmail, setInitEmail] = useState<any>();
    const [isOpenModalLupaPassword, setIsOpenModalLupaPassword] =
        useState(false);

    const openModalLupaPassword = () => setIsOpenModalLupaPassword(true);
    const closeModalLupaPassword = () => setIsOpenModalLupaPassword(false);

    const startTime = moment();
    const endTime = moment(localStorage.getItem("time")) ?? moment();
    const duration = moment.duration(endTime.diff(startTime));
    const initialSeconds = parseInt(duration.asSeconds()) % 60;
    const initialMinutes = parseInt(duration.asMinutes()) % 60;
    const timeoutId = useRef<any>(null);
    const [seconds, setSeconds] = useState<number>(
        localStorage.getItem("time") ? initialSeconds : 0,
    );
    const [minutes, setMinutes] = useState<number>(
        localStorage.getItem("time") ? initialMinutes : 0,
    );
    const thisYear = new Date().getFullYear();

    const localStorageTime = localStorage.getItem("time");

    const dateExpiredTime = moment(localStorage.getItem("time"));

    const dateNow = moment();
    const expiredTime = dateExpiredTime.diff(dateNow);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const queryEmail = urlParams.get("email");

    // const queryUkmId = urlParams.get("redirect");

    const [startTimer, setStartTimer] = useState(false);
    // const { data: user } = useUser();

    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    const initialValues = useMemo(
        () => ({
            email: initEmail ?? "",
            password: "",
        }),
        [initEmail],
    );

    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors },
    } = useForm<ILoginInputs>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const watchEmail = watch("email");

    const handleSaveEmail = (checked: boolean) => {
        if (checked) {
            setSaveEmail(true);
        } else {
            setSaveEmail(false);
        }
    };

    const countTimer = useCallback(() => {
        if (seconds <= 0) {
            if (minutes <= 0) {
                localStorage.removeItem("time");
                setStartTimer(false);
            } else {
                setMinutes(minutes - 1);
                setSeconds(59);
            }
        } else {
            setSeconds(seconds - 1);
        }
    }, [seconds, minutes]);

    useEffect(() => {
        timeoutId.current = window.setTimeout(countTimer, 1000);
        // cleanup function
        return () => window.clearTimeout(timeoutId.current);
    }, [seconds, countTimer]);
    const add5Minutes = moment().add(5, "m");

    useEffect(() => {
        if (loginCount >= 5) {
            // setMinutes(2);
            setMinutes(5);
            setSeconds(0);
            setStartTimer(true);
            localStorage.setItem("time", add5Minutes);
            resetLoginCount();
        }
    }, [add5Minutes, loginCount, resetLoginCount]);

    useEffect(() => {
        if (localStorage.getItem("email")) {
            setSaveEmail(true);
        } else {
            setSaveEmail(false);
        }
    }, []);

    useEffect(() => {
        if (queryEmail) {
            reset({ email: queryEmail ?? "", password: "" });
        } else if (localStorage.getItem("email")) {
            reset({ email: localStorage.getItem("email") ?? "", password: "" });
        } else {
            setInitEmail("");
        }
    }, [queryEmail, reset]);

    const onSubmit = (data: ILoginInputs) => {
        if (loginCount >= 5) {
            Swal.fire({
                title: "Silahkan menunnggu!",
                text: `Anda harus menunggu sebelum bisa mencoba masuk kembali`,
                icon: "error",
                confirmButtonColor: "#45A779",
                customClass: {
                    container: "my-swal",
                },
            });
        } else {
            login(data);
        }
        if (saveEmail) {
            // localStorage.setItem("saveEmail", "true");
            localStorage.setItem("email", watchEmail);
        } else {
            // localStorage.setItem("saveEmail", "false");
            localStorage.removeItem("email");
        }
    };

    return (
        <ThemeDefaultComponent>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ height: "100vh", backgroundColor: "#FFF" }}
            >
                <Box
                    display={isTabletScreen ? "flex" : "grid"}
                    gridTemplateColumns={isTabletScreen ? undefined : "1fr 1fr"}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    gap={8}
                    padding={isTabletScreen ? 3 : undefined}
                    sx={{ backgroundColor: "#FFF" }}
                >
                    <Stack
                        spacing={4.5}
                        padding={isTabletScreen ? undefined : 3}
                    >
                        <Stack spacing={3}>
                            <LogoUlo width="129px" height="64px" />
                            <Typography
                                variant={isTabletScreen ? "h5" : "h4"}
                                component="div"
                                fontWeight="bold"
                            >
                                Tumbuh Bersama{" "}
                                <Typography
                                    variant={isTabletScreen ? "h5" : "h4"}
                                    fontWeight="bold"
                                    sx={{ color: "#45A779" }}
                                    display="inline"
                                >
                                    UKM LOKAL
                                </Typography>
                            </Typography>
                        </Stack>
                        <form
                            style={{
                                width: "100%",
                                maxWidth: "580px",
                                minWidth: "200px",
                            }}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography
                                        marginBottom={1}
                                        variant="body1"
                                    >
                                        Email atau Username
                                    </Typography>
                                    <Controller
                                        name="email"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(errors.email)}
                                                sx={{
                                                    maxWidth: "500px",
                                                }}
                                            >
                                                <OutlinedInput
                                                    id="email"
                                                    // type="email"
                                                    autoComplete="email"
                                                    autoFocus={isLaptopScreen}
                                                    placeholder="Contoh: ulo@gmail.com"
                                                    size="medium"
                                                    {...field}
                                                />
                                                <FormHelperText>
                                                    {errors.email
                                                        ? errors.email.message
                                                        : " "}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                        rules={{ required: "Email required" }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        marginBottom={1}
                                        variant="body1"
                                    >
                                        Kata Sandi
                                    </Typography>
                                    <Controller
                                        name="password"
                                        control={control}
                                        render={({ field }) => (
                                            <FormControl
                                                fullWidth
                                                variant="outlined"
                                                error={Boolean(errors.password)}
                                                sx={{
                                                    maxWidth: "500px",
                                                }}
                                            >
                                                <OutlinedInput
                                                    id="password"
                                                    type={
                                                        showPassword
                                                            ? "text"
                                                            : "password"
                                                    }
                                                    autoComplete="password"
                                                    placeholder="Minimal 8 karakter"
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={
                                                                    handleClickShowPassword
                                                                }
                                                                edge="end"
                                                            >
                                                                {showPassword ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    size="medium"
                                                    {...field}
                                                />
                                                <FormHelperText>
                                                    {errors.password
                                                        ? errors.password
                                                              .message
                                                        : " "}
                                                </FormHelperText>
                                            </FormControl>
                                        )}
                                        rules={{
                                            required: "Kata sandi required",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Stack direction="row" justifyContent="flex-end">
                                <Link
                                    onClick={openModalLupaPassword}
                                    sx={{ cursor: "pointer" }}
                                >
                                    Lupa Kata Sandi?
                                </Link>
                            </Stack>
                            <Box
                                width="100%"
                                display="flex"
                                justifyContent="flex-start"
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={saveEmail}
                                            onChange={(_, checked) => {
                                                handleSaveEmail(checked);
                                            }}
                                        />
                                    }
                                    label="Simpan Informasi Login"
                                    sx={{
                                        height: "fit-content",
                                    }}
                                />
                            </Box>
                            <Button
                                disabled={!!localStorageTime || startTimer}
                                disableElevation
                                type="submit"
                                fullWidth
                                size="large"
                                variant="contained"
                                sx={{
                                    textTransform: "none",
                                    marginTop: 4.5,
                                    maxWidth: "500px",
                                }}
                            >
                                {!!localStorageTime || startTimer ? (
                                    <Countdown
                                        date={Date.now() + expiredTime}
                                        renderer={({
                                            completed,
                                            formatted,
                                        }) => {
                                            if (completed) {
                                                localStorage.removeItem("time");
                                                setStartTimer(false);
                                                window.location.reload();
                                                return <span>Masuk</span>;
                                            } else {
                                                return (
                                                    <span>
                                                        {formatted.minutes}:
                                                        {formatted.seconds}
                                                    </span>
                                                );
                                            }
                                        }}
                                    />
                                ) : (
                                    "Masuk"
                                )}
                            </Button>
                            <Button
                                fullWidth
                                size="large"
                                variant="outlined"
                                href={
                                    process.env.NODE_ENV === "production"
                                        ? `https://juragan.ukmlokal.com`
                                        : `http://localhost:3000`
                                }
                                rel="noreferrer"
                                sx={{
                                    textTransform: "none",
                                    marginTop: 2,
                                    maxWidth: "500px",
                                }}
                            >
                                Ke Juragan
                            </Button>
                            <Typography component="div" sx={{ marginTop: 3 }}>
                                Belum punya akun?{" "}
                                <Link
                                    component={RouterLink}
                                    to="/registrasi"
                                    sx={{
                                        cursor: "pointer",
                                    }}
                                >
                                    Daftar Sekarang
                                </Link>
                            </Typography>
                        </form>
                        {/* <Button
                            variant="contained"
                            component={Link}
                            noLinkStyle
                            href="/beranda"
                        >
                            Masuk
                        </Button> */}
                    </Stack>
                    {isTabletScreen ? null : (
                        <Box>
                            <img
                                src={LoginImage}
                                width="540px"
                                height="650px"
                                alt="Ilustrasi Main"
                                style={{ objectFit: "contain" }}
                            />
                            <Typography
                                variant="caption"
                                component="div"
                                sx={{ fontSize: 16, color: "#7C8883" }}
                                textAlign="center"
                            >
                                <Link
                                    variant="caption"
                                    target="_blank"
                                    sx={{ fontSize: 16, color: "#7C8883" }}
                                    href="https://nore.web.id/"
                                    underline="hover"
                                >
                                    Nore Inovasi
                                </Link>{" "}
                                {`© ${thisYear}. All right reserved`}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
            <LoadingOverlay open={isLoading} />
            <ModalLupaPassword
                open={isOpenModalLupaPassword}
                onClose={closeModalLupaPassword}
            />
        </ThemeDefaultComponent>
    );
};

export default Login;
