import React, {
    createContext,
    ReactNode,
    useCallback,
    useMemo,
    useState,
} from "react";

interface State {
    isOpenModalImport: boolean;
    openModalImport: () => void;
    closeModalImport: () => void;
}

interface IImportModalContext {
    children: ReactNode;
}

const ImportModalContext = createContext<State | undefined>(undefined);

const ImportModalProvider = ({ children }: IImportModalContext) => {
    const [isOpenModalImport, setIsOpenModalImport] = useState(false);

    const openModalImport = useCallback(() => {
        setIsOpenModalImport(true);
    }, []);

    const closeModalImport = useCallback(() => {
        setIsOpenModalImport(false);
    }, []);

    const value = useMemo(
        () => ({
            isOpenModalImport,
            openModalImport,
            closeModalImport,
        }),
        [isOpenModalImport, openModalImport, closeModalImport],
    );

    return (
        <ImportModalContext.Provider value={value}>
            {children}
        </ImportModalContext.Provider>
    );
};

const useImportModal = () => {
    const context = React.useContext(ImportModalContext);
    if (context === undefined) {
        throw new Error(
            "useImportModal must be used within a ImportModalProvider",
        );
    }
    return context;
};

export { ImportModalContext, ImportModalProvider, useImportModal };
