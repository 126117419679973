import PaymentContainer from "../PaymentContainer/PaymentContainer";
import styles from "../Payment.module.css";
import ChevronLeft from "../Assets/ChevronLeft";
import { Fragment } from "react";
import useListBanks from "../../../services/queries/useListBanks";
import { CircularProgress } from "@mui/material";
import useLayanan from "../../../services/queries/useLayanan";
import toRupiah from "../../../utils/toRupiah";
import { Link, useHistory, useParams } from "react-router-dom";
import { PaymentRouteParams } from "../../../constants/types";
import BRIImage from "../../../assets/images/banks/BRI.png";
import BNIImage from "../../../assets/images/banks/BNI.png";
import BCAImage from "../../../assets/images/banks/BCA.png";
import PERMATAImage from "../../../assets/images/banks/PERMATA.png";
import CIMBImage from "../../../assets/images/banks/CIMB.png";

const PaymentMethod = () => {
    const history = useHistory();
    const { data: listBanks, status: statusListBanks } = useListBanks();

    const { data: dataLayanan, status: statusLayanan } = useLayanan();

    let { layananId } = useParams<PaymentRouteParams>();
    const hargaLayanan =
        dataLayanan &&
        dataLayanan?.find((item) => item.id.toString() === layananId)?.harga;

    if (statusListBanks === "loading" || statusLayanan === "loading") {
        return (
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    backgroundColor: "#4a4a4a",
                    alignItems: "center",
                    height: "100vh",
                    width: "100vw",
                }}
            >
                <CircularProgress />
            </div>
        );
    }
    return (
        <>
            <PaymentContainer
                title="Metode Pembayaran"
                headerLeft={<ChevronLeft />}
                onClickHeaderLeft={() => history.goBack()}
            >
                <div className={styles.section}>
                    <div className={styles.section_header}>
                        <div className={styles.half_focused_text}>
                            Total tagihan
                        </div>
                        <div className={styles.focused_text}>
                            {toRupiah(hargaLayanan ?? 0)}
                        </div>
                    </div>
                </div>
                {listBanks &&
                    listBanks.map((payment, idxPayment) => (
                        <Fragment key={idxPayment}>
                            <div className={styles.spacer} />
                            <div className={styles.section}>
                                <div className={styles.section_header}>
                                    <div className={styles.half_focused_text}>
                                        {payment.method}
                                    </div>
                                </div>
                                <div className={styles.method}>
                                    {payment.payments.map((m, idx) => (
                                        <Fragment key={idx}>
                                            <Link
                                                to={
                                                    window.location.pathname +
                                                    `/${payment.code}?code=${m}`
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.method_container
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.method_image
                                                        }
                                                    >
                                                        <img
                                                            src={
                                                                m === "BNI"
                                                                    ? BNIImage
                                                                    : m ===
                                                                      "BRI"
                                                                    ? BRIImage
                                                                    : m ===
                                                                      "CIMB"
                                                                    ? CIMBImage
                                                                    : m ===
                                                                      "PERMATA"
                                                                    ? PERMATAImage
                                                                    : m ===
                                                                      "BCA-MCP"
                                                                    ? BCAImage
                                                                    : ""
                                                            }
                                                            width={46}
                                                            height={46}
                                                            alt="loading..."
                                                            style={{
                                                                objectFit:
                                                                    "contain",
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.focused_text
                                                        }
                                                    >
                                                        {m === "BCA-MCP"
                                                            ? "BCA"
                                                            : m}
                                                    </div>
                                                </div>
                                            </Link>
                                            {idx !==
                                                payment.payments.length - 1 && (
                                                <div
                                                    className={
                                                        styles.method_spacer
                                                    }
                                                />
                                            )}
                                        </Fragment>
                                    ))}
                                </div>
                            </div>
                        </Fragment>
                    ))}
                <div className={styles.spacer} />
            </PaymentContainer>
        </>
    );
};

export default PaymentMethod;
