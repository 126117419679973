import * as React from "react";
import { SVGProps } from "react";

const ErrorIllustration = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={304}
        height={304}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M253.811 149.634c-56.697-32.664-148.318-32.664-204.648 0-56.33 32.664-56.019 85.62.666 118.284 56.685 32.664 148.318 32.67 204.648 0 56.33-32.671 56.062-85.626-.666-118.284Z"
            fill="#F5F5F5"
        />
        <path
            d="m138.93 272.209 11.919 11.342 33.275-11.646-11.919-11.341-33.275 11.645ZM79.382 232.129l4.855 2.795 16.152-9.366-27.636-15.925-16.158 9.372 7.09 4.081-11.23 1.64 9.667 21.832 22.208-3.243-4.948-11.186Z"
            fill="#E0E0E0"
        />
        <path
            d="m225.154 211.484 31.595-13.118-13.432-10.77-25.184 10.484-.497-5.795.915.212 8.583-12.305-31.925-7.453 23.13-13.329-16.183-9.372-27.636 15.925 14.583 8.453-6.697 9.609 12.405 2.875-16.102.429 7.127-14.168-22.009-3.726 9.697 12.937-9.697-12.937-6.978 13.863-37.8 1.006.946 11.062-9.878-2.329-8.614 12.267 27.424 6.478-30.499 1.615 2.16 13.192 35.933-1.863.367.907-20.54 2.13 2.427 7.677-19.488 6.379 1.326 1.347-5.434-1.857-19.401 19.503 19.917 6.627 14.503-14.596 8.409 8.547 18.517-6.062-1.543 1.552 19.917 6.628 13.582-13.665 1.867 1.075 25.52-14.714 19.071-6.721L202 216.713l.324.125 12.797-10.969-14.938-5.851 14.104-.379-2.527 1.05 13.394 10.795ZM99.312 255.222l-3.18-10.608 3.18 10.608Zm75.662-60.446 3.318-6.59.709 8.323-3.012 2.584-7.724-5.454 6.709 1.137Zm-15.884 1.727-.38-4.491 7.382 1.242-20.988 9.938 1.133-1.615-17.964-4.242 30.817-.832Zm-3.112 44.316-.044-.162.044.162Zm3.404-1.963-4.45-1.478-9.274 9.316-3.884-12.273 32.895-4.397-15.287 8.832ZM132.68 248.93l12.038-1.242-6.666 6.708-5.372-5.466Zm26.435 2.336-2.614-8.696 2.614 8.696Zm18.816-23.093-4.681-11.546-34.401 4.59-1.208-7.385 2.975-4.236 10.893 7.739 23.528-11.118 14.938 5.851-14.422 5.08 12.486 11.801-5.714-3.31-4.394 2.534ZM181.373 267.278l22.482 2.627 7.606-22.068-22.482-2.633-7.606 22.074Z"
            fill="#E0E0E0"
        />
        <path
            d="M209.799 221.732c11.204-6.453 29.292-6.453 40.415 0 11.123 6.453 11.06 16.907-.131 23.354-11.191 6.447-29.291 6.453-40.414 0-11.123-6.454-11.061-16.907.13-23.354ZM160.777 166.404l-66.22 38.434a5.142 5.142 0 0 1-5.142 0L55.617 185.36a1.283 1.283 0 0 1-.642-1.109 1.28 1.28 0 0 1 .642-1.108l66.22-38.416a5.145 5.145 0 0 1 5.142 0l33.798 19.478a1.274 1.274 0 0 1 .626 1.099 1.278 1.278 0 0 1-.626 1.1Z"
            fill="#E0E0E0"
        />
        <path
            d="m92.017 189.882-.043 14.062 66.817-38.751.044-14.062-66.818 38.751ZM80.515 170 57.21 156.584l-.075 27.292 34.819 20.068.044-14.062L80.515 170Z"
            fill="#E62828"
        />
        <path
            opacity={0.3}
            d="M80.515 170 57.21 156.584l-.075 27.292 34.819 20.068.044-14.062L80.515 170Z"
            fill="#000"
        />
        <path
            d="m92.017 189.882 66.818-38.751-11.496-19.876L80.515 170l11.502 19.882Z"
            fill="#E62828"
        />
        <path
            opacity={0.1}
            d="m92.017 189.882 66.818-38.751-11.496-19.876L80.515 170l11.502 19.882Z"
            fill="#000"
        />
        <path
            d="m147.059 140.442 3.915 8.223a.516.516 0 0 1-.143.671l-11.03 6.354a.429.429 0 0 1-.585-.249l-3.896-8.192c0-.062-.038-.106-.05-.143a.943.943 0 0 1 0-.168c.007-.048.017-.096.031-.143a.768.768 0 0 1 .068-.118.4.4 0 0 1 .106-.099l11.036-6.354a.304.304 0 0 1 .106-.037.245.245 0 0 1 .137 0c.04.005.078.02.112.043a.424.424 0 0 1 .124.094.81.81 0 0 1 .069.118Zm2.975 8.186-3.193-6.764-2.372 7.658a.417.417 0 0 1-.18.236.323.323 0 0 1-.268.031l-7.531-2.018 3.236 6.832 10.308-5.938m-4.108-6.907-8.957 5.162 6.791 1.863 2.166-7"
            fill="#fff"
            opacity={0.5}
        />
        <path
            d="m147.333 131.255-23.285-13.416-66.818 38.745L80.515 170l66.818-38.745Z"
            fill="#E62828"
        />
        <path
            opacity={0.2}
            d="m147.333 131.255-23.285-13.416-66.818 38.745L80.515 170l66.818-38.745Z"
            fill="#000"
        />
        <path
            opacity={0.2}
            d="M124.048 117.839 57.23 156.584l14.247 8.211 66.818-38.751-14.247-8.205Z"
            fill="#000"
        />
        <path
            d="m108.2 158.385-22.432 13.013 7.65 13.229 22.438-13.012-7.656-13.23Z"
            fill="#455A64"
        />
        <g opacity={0.5} fill="#fff">
            <path d="m103.159 171.584-.921.528-1.867-3.311 1.381-.801 1.407 3.584ZM102.91 172.279a.75.75 0 0 1 .629-.052.74.74 0 0 1 .442.45.923.923 0 0 1-.305 1.242.776.776 0 0 1-.64.046.77.77 0 0 1-.45-.456.91.91 0 0 1 .324-1.23Z" />
            <path d="M107.335 170.596a2.29 2.29 0 0 1 .841 1.758 1.748 1.748 0 0 1-.791 1.578l-5.801 3.341a1.415 1.415 0 0 1-1.63-.186 2.288 2.288 0 0 1-.835-1.758l-.118-8.012a1.721 1.721 0 0 1 .79-1.578 1.414 1.414 0 0 1 1.624.18l5.92 4.677Zm.187 1.683a1.36 1.36 0 0 0-.504-1.055l-5.913-4.671a.861.861 0 0 0-.977-.112 1.042 1.042 0 0 0-.48.95l.119 8.013a1.385 1.385 0 0 0 .504 1.056.861.861 0 0 0 .977.111l5.801-3.341a1.058 1.058 0 0 0 .473-.951Z" />
        </g>
        <path
            d="m117.593 170.602-7.65-13.223-1.742 1.006 7.656 13.23 1.736-1.013Z"
            fill="#E62828"
        />
        <path
            opacity={0.3}
            d="m117.593 170.602-7.65-13.223-1.742 1.006 7.656 13.23 1.736-1.013Z"
            fill="#000"
        />
        <path
            opacity={0.1}
            d="m93.418 184.627 22.438-13.012-.709-1.18-20.54 11.913-7.053-11.988-1.786 1.038 7.65 13.229Z"
            fill="#000"
        />
        <path
            d="m155.773 160.025-2.347 1.348 2.359 1.36-.012-2.708Z"
            fill="#455A64"
        />
        <path
            d="m153.426 161.373-58.104 33.459.007 2.708 60.456-34.807-2.359-1.36Z"
            fill="#37474F"
        />
        <path
            d="M70.506 59.393c0 .069 0 .137.044.199 0 .075.044.155.068.23.024.066.05.13.081.192l.119.249.105.174c.058.09.12.184.187.28l.112.142.106.13.05.056c.075.081.155.156.236.236l.112.106c.075.068.162.137.25.205l.142.112.088.074.292.193c.1.068.2.137.311.199l7.526 4.347 27.567-15.881-7.525-4.348c-3.18-1.863-8.347-1.863-11.552 0l-15.96 9.26c-1.711.988-2.49 2.305-2.39 3.597.004.083.015.166.031.248Z"
            fill="#455A64"
        />
        <path
            opacity={0.5}
            d="M70.506 59.393c0 .069 0 .137.044.199 0 .075.044.155.068.23.024.066.05.13.081.192l.119.249.105.174c.058.09.12.184.187.28l.112.142.106.13.05.056c.075.081.155.156.236.236l.112.106c.075.068.162.137.25.205l.142.112.088.074.292.193c.1.068.2.137.311.199l7.526 4.347 27.567-15.881-7.525-4.348c-3.18-1.863-8.347-1.863-11.552 0l-15.96 9.26c-1.711.988-2.49 2.305-2.39 3.597.004.083.015.166.031.248Z"
            fill="#fff"
        />
        <path
            d="M72.828 62.225a5.07 5.07 0 0 1-.305-.198l-.292-.193-.088-.074-.137-.106a4.396 4.396 0 0 1-.249-.205l-.112-.105a2.7 2.7 0 0 1-.23-.23l-.05-.056-.105-.13-.106-.137a3.267 3.267 0 0 1-.187-.28 2.46 2.46 0 0 1-.106-.168l-.118-.248c0-.062-.056-.118-.081-.18 0-.075-.044-.155-.069-.23 0-.062-.03-.124-.043-.186a1.605 1.605 0 0 1 0-.242v-.236l.075 25.689v.074a1.403 1.403 0 0 0 0 .162 1.862 1.862 0 0 0 0 .26v.044c0 .056.037.106.05.161.012.056.043.168.068.249v.05c.02.048.043.096.068.142.038.087.075.174.119.255v.062c0 .044.056.08.08.118.025.037.125.193.194.286.02.027.04.056.056.087l.161.186.056.062c.092.104.19.201.293.292l.05.044c.074.068.168.136.249.205.08.068.15.124.23.18l.292.198.212.137.1.062 7.525 4.348-.075-25.683-7.525-4.466Z"
            fill="#37474F"
        />
        <path
            opacity={0.3}
            d="M72.828 62.225a5.07 5.07 0 0 1-.305-.198l-.292-.193-.088-.074-.137-.106a4.396 4.396 0 0 1-.249-.205l-.112-.105a2.7 2.7 0 0 1-.23-.23l-.05-.056-.105-.13-.106-.137a3.267 3.267 0 0 1-.187-.28 2.46 2.46 0 0 1-.106-.168l-.118-.248c0-.062-.056-.118-.081-.18 0-.075-.044-.155-.069-.23 0-.062-.03-.124-.043-.186a1.605 1.605 0 0 1 0-.242v-.236l.075 25.689v.074a1.403 1.403 0 0 0 0 .162 1.862 1.862 0 0 0 0 .26v.044c0 .056.037.106.05.161.012.056.043.168.068.249v.05c.02.048.043.096.068.142.038.087.075.174.119.255v.062c0 .044.056.08.08.118.025.037.125.193.194.286.02.027.04.056.056.087l.161.186.056.062c.092.104.19.201.293.292l.05.044c.074.068.168.136.249.205.08.068.15.124.23.18l.292.198.212.137.1.062 7.525 4.348-.075-25.683-7.525-4.466Z"
            fill="#fff"
        />
        <path
            d="M107.92 50.71 80.353 66.592l.075 25.683 27.567-15.876-.075-25.689Z"
            fill="#263238"
        />
        <path
            d="m50.595 166.646-.168-75.216a7.392 7.392 0 0 1 3.36-5.814l93.676-53.837a2.402 2.402 0 0 1 2.378-.249l-3.529-2.037c-.623-.391-1.488-.36-2.446.199L50.184 83.536a7.409 7.409 0 0 0-3.361 5.807l.168 75.197a2.397 2.397 0 0 0 .884 2.118l.186.106 3.48 2.025a2.37 2.37 0 0 1-.946-2.143Z"
            fill="#E62828"
        />
        <path
            opacity={0.3}
            d="M146.33 29.493c-.622-.391-1.487-.36-2.446.199l-93.7 53.844a7.048 7.048 0 0 0-2.57 2.863l3.603 2.08a7.05 7.05 0 0 1 2.57-2.863l93.676-53.837a2.402 2.402 0 0 1 2.378-.249l-3.511-2.037Z"
            fill="#fff"
        />
        <path
            opacity={0.2}
            d="m46.823 89.343.168 75.197a2.397 2.397 0 0 0 .884 2.118l.186.106 3.48 2.025a2.378 2.378 0 0 1-.965-2.149l-.168-75.216a6.493 6.493 0 0 1 .79-2.95l-3.603-2.081c-.486.91-.75 1.92-.772 2.95Z"
            fill="#000"
        />
        <path
            d="M150.868 33.698a2.35 2.35 0 0 0-.971-2.143 2.4 2.4 0 0 0-2.378.249L53.788 85.616a7.41 7.41 0 0 0-3.361 5.814l.168 75.216a2.367 2.367 0 0 0 .965 2.149h.056a2.434 2.434 0 0 0 2.34-.267l93.719-53.8a7.383 7.383 0 0 0 3.361-5.808l-.168-75.222Zm-4.861 78.154L53.919 164.77l-.162-73.346a4.137 4.137 0 0 1 1.693-2.926l92.119-52.918.162 73.347a4.151 4.151 0 0 1-1.724 2.925Z"
            fill="#E62828"
        />
        <path
            d="m147.544 35.58-2.384 1.366.156 70.807a4.173 4.173 0 0 1-1.693 2.925l-89.71 51.552v2.534l92.119-52.918a4.18 4.18 0 0 0 1.161-1.283 4.143 4.143 0 0 0 .538-1.643l-.187-73.34Z"
            fill="#E62828"
        />
        <path
            opacity={0.3}
            d="m147.544 35.58-2.384 1.366.156 70.807a4.173 4.173 0 0 1-1.693 2.925l-89.71 51.552v2.534l92.119-52.918a4.18 4.18 0 0 0 1.161-1.283 4.143 4.143 0 0 0 .538-1.643l-.187-73.34Z"
            fill="#000"
        />
        <path
            d="m145.316 107.765-.156-70.806L55.45 88.51a4.148 4.148 0 0 0-1.693 2.925l.155 70.806 89.711-51.552a4.156 4.156 0 0 0 1.693-2.925Z"
            fill="#455A64"
        />
        <g opacity={0.5} fill="#fff">
            <path d="m117.798 112.02.006 2.664-27.324 15.739-.006-2.664 27.324-15.739ZM93.1 103.995l1.65.938-3.822 6.602 3.84 2.186-1.63 2.82-3.847-2.18-3.815 6.596-1.65-.931 3.822-6.603-3.847-2.186 1.637-2.826 3.847 2.18 3.815-6.596ZM122.703 86.94l1.643.937-3.815 6.603 3.84 2.186-1.637 2.826-3.84-2.186-3.816 6.596-1.649-.932 3.815-6.596-3.84-2.186 1.637-2.833 3.84 2.187 3.822-6.603Z" />
        </g>
        <path
            d="m86.876 44.667-2.664-5.087v-.075l.871-5.745a2.425 2.425 0 0 0-1.2-2.484l-12.007-6.857a1.162 1.162 0 0 0-1.585.425 1.155 1.155 0 0 0 .427 1.58l12.013 6.883.056.124-.865 5.751a2.43 2.43 0 0 0 .249 1.491l2.663 5.087v.1l-3.821 9.13a2.424 2.424 0 0 0-.187.938v1.745a.546.546 0 0 0 .33.521 1.833 1.833 0 0 0 1.65 0 .578.578 0 0 0 .342-.478v-1.863l3.815-9.13a2.43 2.43 0 0 0-.087-2.056Z"
            fill="#E62828"
        />
        <path
            d="M90.2 38.822v-.08l1.481-4.292c.142-.412.17-.854.081-1.28l-2.141-10.559a1.162 1.162 0 0 0-1.373-.91 1.165 1.165 0 0 0-.912 1.37l2.148 10.633-1.481 4.298a2.48 2.48 0 0 0 0 1.634l1.5 4.062v.074L87.174 51.3c-.07.234-.105.477-.106.72v1.64a.578.578 0 0 0 .336.473 1.796 1.796 0 0 0 1.65 0 .548.548 0 0 0 .335-.522v-1.64l2.328-7.528a2.43 2.43 0 0 0-.043-1.559L90.2 38.822Z"
            fill="#455A64"
        />
        <path
            d="M111.089 28.568h-4.028a2.436 2.436 0 0 0-2.029 1.08l-2.253 3.36-.075.05-2.95.696a2.448 2.448 0 0 0-1.68 1.404 2.48 2.48 0 0 0-.094 1.69l.33 1.241a.123.123 0 0 1 0 .093.168.168 0 0 1-.075.056l-.908.224a2.442 2.442 0 0 0-1.75 1.664l-2.807 9.36c-.062.231-.089.47-.08.709v1.44a.564.564 0 0 0 .336.479 1.834 1.834 0 0 0 1.65 0 .535.535 0 0 0 .329-.547v-1.41l2.807-9.36.081-.08.909-.224a2.456 2.456 0 0 0 1.643-1.416 2.58 2.58 0 0 0 .087-1.677l-.336-1.242a.124.124 0 0 1-.01-.047c0-.016.004-.032.01-.046a.086.086 0 0 1 .075-.056l3-.659a2.492 2.492 0 0 0 1.475-1.018l2.247-3.36.1-.056h4.027a1.16 1.16 0 0 0 .877-.308 1.167 1.167 0 0 0 .372-.85 1.158 1.158 0 0 0-.779-1.097 1.161 1.161 0 0 0-.47-.062l-.031-.031Z"
            fill="#E62828"
        />
        <g opacity={0.1} fill="#fff">
            <path d="m86.876 44.667-2.664-5.087v-.075l.871-5.745a2.425 2.425 0 0 0-1.2-2.484l-12.007-6.857a1.162 1.162 0 0 0-1.585.425 1.155 1.155 0 0 0 .427 1.58l12.013 6.883.056.124-.865 5.751a2.43 2.43 0 0 0 .249 1.491l2.663 5.087v.1l-3.821 9.13a2.424 2.424 0 0 0-.187.938v1.745a.546.546 0 0 0 .33.521 1.833 1.833 0 0 0 1.65 0 .578.578 0 0 0 .342-.478v-1.863l3.815-9.13a2.43 2.43 0 0 0-.087-2.056Z" />
            <path d="M90.2 38.822v-.08l1.481-4.292c.142-.412.17-.854.081-1.28l-2.141-10.559a1.162 1.162 0 0 0-1.373-.91 1.165 1.165 0 0 0-.912 1.37l2.148 10.633-1.481 4.298a2.48 2.48 0 0 0 0 1.634l1.5 4.062v.074L87.174 51.3c-.07.234-.105.477-.106.72v1.64a.578.578 0 0 0 .336.473 1.796 1.796 0 0 0 1.65 0 .548.548 0 0 0 .335-.522v-1.64l2.328-7.528a2.43 2.43 0 0 0-.043-1.559L90.2 38.822ZM111.089 28.568h-4.028a2.436 2.436 0 0 0-2.029 1.08l-2.253 3.36-.075.05-2.95.696a2.448 2.448 0 0 0-1.68 1.404 2.48 2.48 0 0 0-.094 1.69l.33 1.241a.123.123 0 0 1 0 .093.168.168 0 0 1-.075.056l-.908.224a2.442 2.442 0 0 0-1.75 1.664l-2.807 9.36c-.062.231-.089.47-.08.709v1.44a.564.564 0 0 0 .336.479 1.834 1.834 0 0 0 1.65 0 .535.535 0 0 0 .329-.547v-1.41l2.807-9.36.081-.08.909-.224a2.456 2.456 0 0 0 1.643-1.416 2.58 2.58 0 0 0 .087-1.677l-.336-1.242a.124.124 0 0 1-.01-.047c0-.016.004-.032.01-.046a.086.086 0 0 1 .075-.056l3-.659a2.492 2.492 0 0 0 1.475-1.018l2.247-3.36.1-.056h4.027a1.16 1.16 0 0 0 .877-.308 1.167 1.167 0 0 0 .372-.85 1.158 1.158 0 0 0-.779-1.097 1.161 1.161 0 0 0-.47-.062l-.031-.031Z" />
        </g>
        <path
            d="m50.607 14.195 6.728 7.714 4.675-2.745 5.483 13.037-6.36-9.242-4.974 3.33-5.552-12.094ZM82.563 11.643l-3.125 6.242 3.125.478-2.565 6.317 5.372-7.28-4.252-.72 1.445-5.037ZM109.974 13.742l-1.282 7.422-2.564-.963-1.338 7.522 2.085-5.764 2.888 1.23.211-9.447ZM114.089 26.922l5.16-4.51v2.255l3.716-2.596-4.998 5.36.13-3.168-4.008 2.659ZM112.252 33.698l4.513 4.298.853-2.416 4.861 3.764-5.11-6.398-.928 3.2-4.189-2.448ZM76.432 42.866l-6.86 2.559 2.422 1.844-5.303 3.05 8.658-2.336-2.596-2.08 3.679-3.037Z"
            fill="#E62828"
        />
        <g opacity={0.5} fill="#fff">
            <path d="m50.607 14.195 6.728 7.714 4.675-2.745 5.483 13.037-6.36-9.242-4.974 3.33-5.552-12.094ZM82.563 11.643l-3.125 6.242 3.125.478-2.565 6.317 5.372-7.28-4.252-.72 1.445-5.037ZM109.974 13.742l-1.282 7.422-2.564-.963-1.338 7.522 2.085-5.764 2.888 1.23.211-9.447ZM114.088 26.922l5.16-4.51v2.255l3.716-2.596-4.998 5.36.131-3.168-4.009 2.659ZM112.252 33.698l4.513 4.298.853-2.416 4.861 3.764-5.11-6.398-.928 3.2-4.189-2.448ZM76.431 42.866l-6.859 2.559 2.421 1.844-5.303 3.05 8.658-2.336-2.595-2.08 3.678-3.037Z" />
        </g>
        <path
            d="m173.225 214.564-37.924 5.062 5.228 12.913 37.931-5.068-5.235-12.907Z"
            fill="#E62828"
        />
        <path
            opacity={0.2}
            d="m173.225 214.564-14.714 11.193-23.21-6.131 37.924-5.062Z"
            fill="#fff"
        />
        <path
            opacity={0.1}
            d="m173.225 214.564 5.235 12.907-37.931 5.068-5.228-12.913 23.21 6.131 14.714-11.193Z"
            fill="#fff"
        />
        <path
            d="m190.958 169.665-8.552 12.279 36.145 8.466 8.558-12.28-36.151-8.465Z"
            fill="#E62828"
        />
        <path
            opacity={0.3}
            d="m227.109 178.13-23.21 4.255-12.941-12.72 36.151 8.465Z"
            fill="#fff"
        />
        <path
            opacity={0.1}
            d="m227.109 178.13-8.558 12.28-36.145-8.466 8.552-12.279 12.941 12.72 23.21-4.255Z"
            fill="#fff"
        />
        <path
            d="m108.947 191.186-8.558 12.279 36.144 8.466 8.559-12.279-36.145-8.466Z"
            fill="#E62828"
        />
        <path
            opacity={0.3}
            d="m145.092 199.652-23.211 4.254-12.934-12.72 36.145 8.466Z"
            fill="#fff"
        />
        <path
            opacity={0.1}
            d="m145.092 199.652-8.559 12.279-36.144-8.466 8.558-12.279 12.934 12.72 23.211-4.254Z"
            fill="#fff"
        />
        <path
            d="m243.342 185.534-31.601 13.111 13.414 10.77 31.594-13.111-13.407-10.77Z"
            fill="#E62828"
        />
        <path
            opacity={0.5}
            d="m243.342 185.534-6.119 13.615-25.482-.504 31.601-13.111Z"
            fill="#fff"
        />
        <path
            opacity={0.3}
            d="m243.342 185.534 13.407 10.77-31.594 13.111-13.414-10.77 25.482.504 6.119-13.615Z"
            fill="#fff"
        />
        <path
            d="m182.811 191.186-12.804 10.969 32.317 12.633 12.797-10.962-32.31-12.64Z"
            fill="#E62828"
        />
        <path
            opacity={0.2}
            d="m215.121 203.826-24.237 1.354-8.073-13.994 32.31 12.64Z"
            fill="#fff"
        />
        <path
            opacity={0.1}
            d="m215.121 203.826-12.797 10.962-32.317-12.633 12.804-10.969 8.073 13.994 24.237-1.354Z"
            fill="#fff"
        />
        <path
            d="m211.461 245.769-22.482-2.627-7.606 22.068 22.482 2.633 7.606-22.074Z"
            fill="#E62828"
        />
        <path
            opacity={0.5}
            d="m188.979 243.142 11.59 12.409-19.196 9.659 7.606-22.068Z"
            fill="#fff"
        />
        <path
            opacity={0.3}
            d="m203.855 267.843-22.482-2.633 19.196-9.659-11.59-12.409 22.482 2.627-7.606 22.074Z"
            fill="#fff"
        />
        <path
            d="m172.205 258.495-33.275 11.646 11.919 11.348 33.275-11.646-11.919-11.348Z"
            fill="#E62828"
        />
        <path
            opacity={0.5}
            d="m172.205 258.495-7.936 13.304-25.339-1.658 33.275-11.646Z"
            fill="#fff"
        />
        <path
            opacity={0.3}
            d="m172.205 258.495 11.919 11.348-33.275 11.646-11.919-11.348 25.339 1.658 7.936-13.304Z"
            fill="#fff"
        />
        <path
            d="m202.156 147.957-27.636 15.925 16.158 9.366 27.636-15.919-16.158-9.372Z"
            fill="#E62828"
        />
        <path
            opacity={0.5}
            d="m174.52 163.882 24.262-1.31 3.374-14.615-27.636 15.925Z"
            fill="#fff"
        />
        <path
            opacity={0.3}
            d="m174.52 163.882 16.158 9.366 27.636-15.919-16.158-9.372-3.374 14.615-24.262 1.31Z"
            fill="#fff"
        />
        <path
            d="m165.675 190.9-28.943 13.41 15.262 10.764 28.943-13.41-15.262-10.764Z"
            fill="#E62828"
        />
        <path
            opacity={0.6}
            d="m136.732 204.31 24.288.845 4.655-14.255-28.943 13.41Z"
            fill="#fff"
        />
        <path
            opacity={0.4}
            d="m136.732 204.31 15.262 10.764 28.943-13.41-15.262-10.764-4.655 14.255-24.288-.845Z"
            fill="#fff"
        />
        <path
            d="m217.132 184.254-38.915 1.038 1.133 13.235 38.914-1.037-1.132-13.236Z"
            fill="#E62828"
        />
        <path
            opacity={0.5}
            d="m217.132 184.254-17.97 9.479-20.945-8.441 38.915-1.038Z"
            fill="#fff"
        />
        <path
            opacity={0.3}
            d="m217.132 184.254 1.132 13.236-38.914 1.037-1.133-13.235 20.945 8.441 17.97-9.479Z"
            fill="#fff"
        />
        <path
            d="m157.964 181.205-38.915 1.037 1.133 13.236 38.908-1.037-1.126-13.236Z"
            fill="#E62828"
        />
        <path
            opacity={0.5}
            d="m157.964 181.205-17.97 9.478-20.945-8.441 38.915-1.037Z"
            fill="#fff"
        />
        <path
            opacity={0.3}
            d="m157.964 181.205 1.126 13.236-38.908 1.037-1.133-13.236 20.945 8.441 17.97-9.478Z"
            fill="#fff"
        />
        <path
            d="m174.813 241.943-19.881-6.596-19.376 19.496 19.918 6.634 19.339-19.534Zm-18.636-2.398 2.895 9.659-2.895-9.659Z"
            fill="#E62828"
        />
        <path
            opacity={0.2}
            d="m154.926 235.316 4.164 13.888-23.54 5.608 19.376-19.496Z"
            fill="#fff"
        />
        <path
            opacity={0.1}
            d="m155.436 261.439-19.886-6.627 23.54-5.608-4.164-13.888 19.887 6.627-19.377 19.496Z"
            fill="#fff"
        />
        <path
            d="m163.827 167.373-10.874 21.602 22.022 3.739 10.861-21.608-22.009-3.733Z"
            fill="#E62828"
        />
        <path
            opacity={0.5}
            d="m163.827 167.373 9.697 12.937-20.571 8.665 10.874-21.602Z"
            fill="#fff"
        />
        <path
            opacity={0.3}
            d="m174.975 192.714-22.022-3.739 20.571-8.665-9.697-12.937 22.009 3.733-10.861 21.608Z"
            fill="#fff"
        />
        <path
            d="m195.129 208.167-19.551 6.888 20.354 19.242 19.544-6.888-20.347-19.242Z"
            fill="#E62828"
        />
        <path
            opacity={0.5}
            d="m195.128 208.167 20.348 19.242-23.055-4.59 2.707-14.652Z"
            fill="#fff"
        />
        <path
            opacity={0.3}
            d="m195.129 208.167-2.708 14.652 23.055 4.59-19.544 6.888-20.354-19.242 19.551-6.888Z"
            fill="#fff"
        />
        <path
            d="m182.35 223.546-27.636 15.925 16.159 9.372 27.635-15.925-16.158-9.372Z"
            fill="#E62828"
        />
        <path
            opacity={0.6}
            d="m154.714 239.471 24.263-1.311 3.373-14.614-27.636 15.925Z"
            fill="#fff"
        />
        <path
            opacity={0.4}
            d="m154.714 239.471 16.159 9.372 27.635-15.925-16.158-9.372-3.373 14.614-24.263 1.311Z"
            fill="#fff"
        />
        <path
            d="m119.211 233.204-19.918 6.521 19.283 19.603 19.924-6.522-19.289-19.602Z"
            fill="#E62828"
        />
        <path
            opacity={0.5}
            d="m122.093 244.738-3.517 14.59-19.282-19.603 22.799 5.013Z"
            fill="#fff"
        />
        <path
            opacity={0.3}
            d="m119.211 233.204 19.289 19.602-19.924 6.522-19.282-19.603 19.282 19.603 3.517-14.59-22.8-5.013 19.918-6.521Z"
            fill="#fff"
        />
        <path
            d="m138.905 223.322-22.551 2.348 7.009 22.161 22.55-2.342-7.008-22.167Z"
            fill="#E62828"
        />
        <path
            opacity={0.6}
            d="m138.905 223.322 7.008 22.167-18.666-9.086 11.658-13.081Z"
            fill="#fff"
        />
        <path
            opacity={0.4}
            d="m138.905 223.322-11.658 13.081 18.666 9.086-22.55 2.342-7.009-22.161 22.551-2.348Z"
            fill="#fff"
        />
        <path
            d="m115.041 245.874-19.893-6.627 4.164 13.888-4.164-13.888-19.37 19.497 19.886 6.633 19.377-19.503Z"
            fill="#E62828"
        />
        <path
            opacity={0.6}
            d="m95.148 239.247 4.164 13.888-23.534 5.609 19.37-19.497Z"
            fill="#fff"
        />
        <path
            opacity={0.4}
            d="m95.664 265.377-19.886-6.633 23.534-5.609-4.164-13.888 19.893 6.627-19.377 19.503Z"
            fill="#fff"
        />
        <path
            d="m72.753 207.571-16.158 9.366 27.642 15.925 16.152-9.372-27.636-15.919Z"
            fill="#E62828"
        />
        <path
            opacity={0.2}
            d="m100.389 223.49-24.263-1.311-3.373-14.608 27.636 15.919Z"
            fill="#fff"
        />
        <path
            opacity={0.1}
            d="m100.389 223.49-16.152 9.372-27.642-15.925 16.158-9.366 3.373 14.608 24.263 1.311Z"
            fill="#fff"
        />
        <path
            d="m74.664 219.421-22.208 3.243 9.666 21.832 22.208-3.243-9.666-21.832Z"
            fill="#E62828"
        />
        <path
            opacity={0.5}
            d="m74.664 219.421 9.666 21.832-19.718-8.31 10.052-13.522Z"
            fill="#fff"
        />
        <path
            opacity={0.3}
            d="m74.664 219.421-10.052 13.522 19.718 8.31-22.208 3.243-9.666-21.832 22.208-3.243Z"
            fill="#fff"
        />
        <path
            d="m137.212 209.285-38.784 2.043 2.154 13.199 38.783-2.043-2.153-13.199Z"
            fill="#E62828"
        />
        <path
            opacity={0.6}
            d="m137.212 209.285-17.217 9.938-21.567-7.895 38.784-2.043Z"
            fill="#fff"
        />
        <path
            opacity={0.3}
            d="m137.212 209.285 2.153 13.199-38.783 2.043-2.154-13.199 21.567 7.895 17.217-9.938Z"
            fill="#fff"
        />
        <path
            opacity={0.1}
            d="m135.369 171.758-5.72 3.298-10.799 6.217 28.121 10.987 12.798-10.968-24.4-9.534Z"
            fill="#000"
        />
        <path
            d="m137.305 170.658-5.726 3.292-10.799 6.218 26.192 10.242 12.797-10.969-22.464-8.783Z"
            fill="#E62828"
        />
        <path
            opacity={0.6}
            d="m135.531 180.795 24.237-1.354-22.463-8.783-5.726 3.292 3.952 6.845Z"
            fill="#fff"
        />
        <path
            opacity={0.4}
            d="m159.769 179.441-24.238 1.354-3.952-6.845-10.799 6.218 26.192 10.242 12.797-10.969Z"
            fill="#fff"
        />
        <path
            d="m255.299 132.417.827-13.994a11.557 11.557 0 0 0-4.599-9.937l-5.036-3.783.735 30.024 8.073-2.31Z"
            fill="#E62828"
        />
        <path
            opacity={0.1}
            d="m255.299 132.417.827-13.994a11.557 11.557 0 0 0-4.599-9.937l-5.036-3.783.735 30.024 8.073-2.31Z"
            fill="#000"
        />
        <path
            opacity={0.1}
            d="M247.469 117.107a34.147 34.147 0 0 0 7.469 1.639 5.41 5.41 0 0 1-4.177 1.031 5.275 5.275 0 0 1-3.292-2.67Z"
            fill="#000"
        />
        <path
            d="M238.151 240.583c-.411 1.285-1.83 2.024-3.181 2.13-1.354 0-2.703-.171-4.015-.509-2.987-.541-5.869 0-8.844-.075a12.633 12.633 0 0 1-2.907-.317 4.785 4.785 0 0 1-2.49-1.465 3.303 3.303 0 0 1-.716-1.553 3.108 3.108 0 0 0 .716 2.428 4.822 4.822 0 0 0 2.49 1.46c.953.217 1.929.324 2.907.317 2.975.068 5.857-.466 8.844.074a15.77 15.77 0 0 0 4.015.516c1.351-.112 2.77-.845 3.181-2.137.128-.445.164-.913.106-1.372-.024.17-.059.338-.106.503Z"
            fill="#263238"
        />
        <path
            d="m238.076 238.111-.554-2.708a3.932 3.932 0 0 0-.46-1.373 1.344 1.344 0 0 0-1.208-.689c-1.631-.218-3.934-.808-5.291.124a12.21 12.21 0 0 1-7.313 2.118c-1.351-.05-2.701-.329-4.046-.162-1.344.168-2.732.907-3.112 2.199-.11.381-.133.782-.069 1.174.098.571.345 1.107.716 1.553a4.785 4.785 0 0 0 2.49 1.465c.953.218 1.929.324 2.907.317 2.975.069 5.857-.466 8.844.075 1.312.338 2.661.509 4.015.509 1.351-.106 2.77-.845 3.181-2.13.047-.165.082-.333.106-.503a6.437 6.437 0 0 0-.206-1.969Z"
            fill="#37474F"
        />
        <path
            d="m230.165 230.626-.112 3.528a1.39 1.39 0 0 0 1.294 1.441c1.185.094 2.376.094 3.561 0a2.015 2.015 0 0 0 1.823-1.863l.144-2.609-6.71-.497Z"
            fill="#FFA8A7"
        />
        <path
            d="M253.276 228.751a2.767 2.767 0 0 1-.473 1.459 2.694 2.694 0 0 1-1.668.938 4.228 4.228 0 0 1-4.139-1.702c-.448-.621-.704-1.397-1.121-2.056a9.415 9.415 0 0 0-2.321-2.254 9.753 9.753 0 0 1-2.341-2.236 3.304 3.304 0 0 1-.442-2.982 2.122 2.122 0 0 0-.224.379 3.392 3.392 0 0 0 .424 3.174 9.99 9.99 0 0 0 2.383 2.304 9.572 9.572 0 0 1 2.372 2.323c.429.677.685 1.454 1.139 2.118a4.269 4.269 0 0 0 4.22 1.752 2.722 2.722 0 0 0 1.706-.963 3.27 3.27 0 0 0 .491-2.031c-.006-.074-.006-.149-.006-.223Z"
            fill="#263238"
        />
        <path
            d="M252.168 224.328c-1.071-1.863-3.286-3.248-4.979-4.577a6.384 6.384 0 0 0-3.312-1.503 3.11 3.11 0 0 0-3.112 1.571c0 .031 0 .068-.031.099a3.291 3.291 0 0 0 .442 2.982 9.767 9.767 0 0 0 2.34 2.236 9.42 9.42 0 0 1 2.322 2.254c.417.659.672 1.416 1.12 2.056a4.219 4.219 0 0 0 4.139 1.702 2.689 2.689 0 0 0 1.668-.938 2.76 2.76 0 0 0 .474-1.459v-.51a8.162 8.162 0 0 0-1.071-3.913Z"
            fill="#37474F"
        />
        <path
            d="M247.512 222.695v3.205a5.395 5.395 0 0 0 4.98-.218l.298-2.888-5.278-.099Z"
            fill="#FFA8A7"
        />
        <path
            d="M253.655 164.54c.959 2.889-.224 16.584-.784 29.727 2.085 4.969 2.148 19.198.94 29.192 0 0-4.083 1.242-7.855-.056l-3.361-27.714-.66-15.621s-1.189 13.857-2.552 22.751c1.282 6.832 1.419 8.242-1.749 29.192 0 0-5.141 1.547-8.994-.341-1.793-29.428.293-56.061.623-65.707l24.392-1.423Z"
            fill="#455A64"
        />
        <path
            opacity={0.3}
            d="m242.334 189.608-.536-12.745c3.299 0 6.368-2.484 8.714-4.795-2.24 3.975-6.573 5.739-6.573 5.739l-1.605 11.801Z"
            fill="#000"
        />
        <path
            d="m228.871 138.907-.1 27.863c14.72 6.155 24.275.621 25.563-2.298-.678-10.155 1.724-22.037 1.538-27.086-.187-5.05-.803-10.739-8.646-9.596l-8.341.813-4.232 1.025-5.782 9.279Z"
            fill="#E62828"
        />
        <path
            d="M247.226 123.802a2.252 2.252 0 0 0 1.631-.553 1.613 1.613 0 0 0 .386-1.397 1.525 1.525 0 0 0 1.12-.907 1.75 1.75 0 0 0-.261-1.522 2.55 2.55 0 0 0 1.655-1.348 2.67 2.67 0 0 0 .075-2.192 2.374 2.374 0 0 0 1.668-1.615 2.574 2.574 0 0 0-.473-2.373 3.205 3.205 0 0 0 .374-3.192 3.378 3.378 0 0 0-2.745-1.863 3.286 3.286 0 0 0-1.077-2.951 3.183 3.183 0 0 0-3.031-.59 2.923 2.923 0 0 0-.873-1.158 2.92 2.92 0 0 0-3.982.326 2.249 2.249 0 0 0-1.967-.727 2.3 2.3 0 0 0-1.774 1.174 2.984 2.984 0 0 0-3.901 1.374 2.963 2.963 0 0 0-.313 1.471 1.75 1.75 0 0 0-1.245 1.733 1.867 1.867 0 0 0 1.45 1.671 41.506 41.506 0 0 0 3.909 11.434c.828 1.609 1.868 3.236 3.448 4.068 1.718.92 4.438.404 5.926-.863Z"
            fill="#455A64"
        />
        <path
            d="m233.333 114.399-1.724 2.999 1.893.696-.169-3.695Z"
            fill="#F28F8F"
        />
        <path
            d="M233.93 109.181a27.893 27.893 0 0 0-.497 12.733c.09.729.364 1.424.796 2.018.571.609 1.357.97 2.191 1.007a9.435 9.435 0 0 0 2.49-.236v3.9c2.963.696 6.013.969 8.34-.813v-3.988a10.597 10.597 0 0 1-5.178.317c-2.048-1.1-2.459-4.832-2.172-5.82a1.535 1.535 0 0 0 1.599.559 2.127 2.127 0 0 0 1.332-1.05 4.41 4.41 0 0 0 .498-1.658c.144-.7.036-1.428-.305-2.056a2.16 2.16 0 0 0-2.322-.782 3.328 3.328 0 0 0-1.954 1.627c-.132.24-.246.489-.342.745a.465.465 0 0 1-.494.311.46.46 0 0 1-.403-.423 6.145 6.145 0 0 1 .586-3.26 2.28 2.28 0 0 1-1.675-1.025 2.15 2.15 0 0 1-.323-1.864 6.2 6.2 0 0 1-2.167-.242Z"
            fill="#FFA8A7"
        />
        <path
            d="M236.296 114.753a9.644 9.644 0 0 0 7.301-3.007.882.882 0 0 0 .125-1.056.852.852 0 0 0-.922-.394.853.853 0 0 0-.323.146 5.797 5.797 0 0 1-2.751 1.168c.068-1.447 2.079-4.267 3.112-5.286.328-.24.568-.58.684-.969a.8.8 0 0 0-.255-.695.77.77 0 0 0-.697-.212.614.614 0 0 0 .294-.248.612.612 0 0 0 .092-.373.768.768 0 0 0-.504-.59.994.994 0 0 0-.791 0 .616.616 0 0 0-.435-.49 1.07 1.07 0 0 0-.685 0c-.228.078-.447.18-.654.304a.942.942 0 0 0-1.145-.323c-2.62 1.329-5.763 5.59-5.788 8.521a3.64 3.64 0 0 0 1.005 2.385 3.662 3.662 0 0 0 2.337 1.119Z"
            fill="#FFA8A7"
        />
        <path
            d="M242.539 104.399a7.079 7.079 0 0 0-3.343 3.378c.243-2.242 2.453-3.279 3.343-3.378ZM241.63 103.169c-2.216 1.05-3.193 2.149-3.797 3.105a5.116 5.116 0 0 1 3.797-3.105ZM239.856 103.007a7.251 7.251 0 0 0-3.143 2.553 3.928 3.928 0 0 1 1.217-1.71 3.951 3.951 0 0 1 1.926-.843ZM240.697 113.287a8.016 8.016 0 0 1-4.656.695 4.456 4.456 0 0 0-1.469-2.31 4.374 4.374 0 0 0-1.625-.845v.379a3.653 3.653 0 0 0 3.374 3.547 9.645 9.645 0 0 0 7.276-3.007.861.861 0 0 0 .137-1.012 8.05 8.05 0 0 1-3.037 2.553Z"
            fill="#F28F8F"
        />
        <path
            opacity={0.2}
            d="M236.906 137.833c-2.49.043-4.98.168-7.513.261l-.523.844v5.218a11.667 11.667 0 0 0 8.036-6.323Z"
            fill="#000"
        />
        <path
            d="m237.149 129.019-16.632-4.701 14.646-6.982c2.129-3.888-1.774-7.198-5.085-6.72l-16.936 8.348a7.13 7.13 0 0 0-3.007 10.379 7.158 7.158 0 0 0 3.1 2.633l19.295 8.534c5.808 1.944 9.866-10.006 4.619-11.491Z"
            fill="#E62828"
        />
        <path
            opacity={0.2}
            d="m220.517 124.318-4.749-1.348a24.542 24.542 0 0 1 7.469-.354l.697.074-3.417 1.628Z"
            fill="#000"
        />
    </svg>
);

export default ErrorIllustration;
