import { useQuery } from "react-query";
import { GetDetailStrukResponse } from "../../constants/types";
import axios from "../axios";
import qs from "query-string";

const handleRequest = async (ulo: string) => {
    const apiGetDetailStruk = qs.stringifyUrl({
        url: "api/cekstruk",
        query: {
            ulo: ulo,
        },
    });
    const { data } = await axios.get<GetDetailStrukResponse>(apiGetDetailStruk);
    return data.data;
};

export default function useCekStruk(ulo: string) {
    return useQuery<GetDetailStrukResponse["data"]>(
        ["cekStruk", ulo],
        () => handleRequest(ulo),
        { enabled: !!ulo },
    );
}
