import { Circle } from "@mui/icons-material";
import {
    Box,
    Button,
    Grid,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DetailBundling, ICart } from "../../constants/types";
import { useTransaksi } from "../../context/transaksiContext";
import ModalPilihFaktur from "../ModalPilihFaktur/ModalPilihFaktur";
import { useTheme } from "@mui/material/styles";
import truncate from "../../utils/truncate";

interface IListSelectedBarangProps {
    id: number;
    idx: number;
    namaBarang: string;
    sku?: string;
    qty: number;
    hpp: {
        id: number;
        faktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
    detailBundling: DetailBundling[];
}

const ListSelectedBarang = ({
    id,
    idx,
    namaBarang,
    sku,
    qty,
    hpp,
    detailBundling,
}: IListSelectedBarangProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [stokId, setStokId] = useState<number>();
    const [isOpenModalPilihFaktur, setIsOpenModalPilihFaktur] = useState(false);
    // const [faktur, setFaktur] = useState<IFaktur[]>([]);
    const { cart } = useTransaksi();
    const [bundlingBarang, setBundlingBarang] = useState<string | undefined>(
        "",
    );
    const [bundlingHpp, setBundlingHpp] = useState<ICart["hpp"] | undefined>(
        [],
    );
    const [bundlingQty, setBundlingQty] = useState<number | undefined>();
    const [isBundling, setIsBundling] = useState<boolean | undefined>(false);
    const [idSatuan, setIdSatuan] = useState<number | undefined>();

    useEffect(() => console.log("cart", cart), [cart]);

    const openModalPilihFaktur = (
        stokId: number,
        bundlingBarang?: string,
        bundlingHpp?: ICart["hpp"],
        bundlingQty?: number,
        isBundling?: boolean,
        idSatuan?: number,
    ) => {
        setStokId(stokId);
        if (bundlingBarang) {
            setBundlingBarang(bundlingBarang);
        } else {
            setBundlingBarang(undefined);
        }
        if (bundlingHpp) {
            setBundlingHpp(bundlingHpp);
        } else {
            setBundlingHpp(undefined);
        }
        if (bundlingQty) {
            setBundlingQty(bundlingQty);
        } else {
            setBundlingQty(undefined);
        }
        if (isBundling) {
            setIsBundling(isBundling);
        }
        if (idSatuan) {
            setIdSatuan(idSatuan);
        }
        setIsOpenModalPilihFaktur(true);
    };

    const closeModalPilihFaktur = () => {
        setStokId(undefined);
        setIsOpenModalPilihFaktur(false);
    };

    return (
        <React.Fragment>
            <Box
                display="flex"
                flexDirection={isPhoneScreen ? "column" : "row"}
                justifyContent={isPhoneScreen ? undefined : "space-between"}
                alignItems={isPhoneScreen ? undefined : "center"}
                marginBottom={1}
                sx={{
                    border: "1px solid rgba(0,0,0,0.12)",
                    borderRadius: "4px",
                    padding: 2,
                }}
            >
                <Grid container alignItems="center">
                    <Tooltip title={namaBarang}>
                        <Typography fontWeight={500}>
                            {truncate(namaBarang, 30)}
                        </Typography>
                    </Tooltip>
                    <Typography fontWeight={500}>({sku})</Typography>
                    <Grid item xs={12}>
                        {detailBundling.length === 0 && (
                            <Typography variant="body2">
                                Di keranjang: {qty}
                            </Typography>
                        )}
                        {detailBundling.length === 0 ? (
                            cart[idx].faktur.length > 0 ? (
                                <>
                                    <Typography variant="body2" marginTop={1}>
                                        Faktur Terpilih:
                                    </Typography>
                                    <Box display="flex" flexDirection="column">
                                        {cart[idx].faktur.map((li, idx) => (
                                            <Box
                                                key={String(li.fakturId)}
                                                display="flex"
                                                flexDirection="row"
                                                alignItems="center"
                                                gap={0.5}
                                                marginBottom={0.5}
                                            >
                                                <Circle
                                                    sx={{
                                                        width: "8px",
                                                        height: "8px",
                                                    }}
                                                />
                                                <Typography variant="body2">
                                                    {li.kodeFaktur} (Terpilih:{" "}
                                                    {li.jumlahItem})
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                </>
                            ) : (
                                <Typography
                                    variant="body2"
                                    marginTop={1}
                                    color="error"
                                    fontWeight={500}
                                >
                                    Segera pilih faktur
                                </Typography>
                            )
                        ) : (
                            detailBundling.map((li) => (
                                <Box
                                    key={String(li.id)}
                                    display="flex"
                                    flexDirection={
                                        isPhoneScreen ? "column" : "row"
                                    }
                                    justifyContent={
                                        isPhoneScreen
                                            ? undefined
                                            : "space-between"
                                    }
                                    alignItems={
                                        isPhoneScreen ? undefined : "center"
                                    }
                                    marginBottom={1}
                                >
                                    <Grid
                                        container
                                        alignItems="center"
                                        paddingLeft={2}
                                    >
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <Circle
                                                sx={{ width: 8, height: 8 }}
                                            />
                                            <Typography fontWeight={500}>
                                                {li.namaBarang} ({li.sku})
                                            </Typography>
                                        </Stack>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                Di keranjang:{" "}
                                                {qty * li.jumlahYangDiambil}
                                            </Typography>
                                            {li.newFaktur &&
                                            li.newFaktur.length > 0 ? (
                                                <>
                                                    <Typography
                                                        variant="body2"
                                                        marginTop={1}
                                                    >
                                                        Faktur Terpilih:
                                                    </Typography>
                                                    <Box
                                                        display="flex"
                                                        flexDirection="column"
                                                    >
                                                        {li.newFaktur.map(
                                                            (fa, idx) => (
                                                                <Box
                                                                    key={String(
                                                                        fa.fakturId,
                                                                    )}
                                                                    display="flex"
                                                                    flexDirection="row"
                                                                    alignItems="center"
                                                                    gap={0.5}
                                                                    marginBottom={
                                                                        0.5
                                                                    }
                                                                >
                                                                    <Circle
                                                                        sx={{
                                                                            width: "8px",
                                                                            height: "8px",
                                                                        }}
                                                                    />
                                                                    <Typography variant="body2">
                                                                        {
                                                                            fa.kodeFaktur
                                                                        }{" "}
                                                                        (Terpilih:{" "}
                                                                        {
                                                                            fa.jumlahItem
                                                                        }
                                                                        )
                                                                    </Typography>
                                                                </Box>
                                                            ),
                                                        )}
                                                    </Box>
                                                </>
                                            ) : (
                                                <Typography
                                                    variant="body2"
                                                    marginTop={1}
                                                    color="error"
                                                    fontWeight={500}
                                                >
                                                    Segera pilih faktur
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Box
                                        width={isPhoneScreen ? "100%" : 200}
                                        marginTop={
                                            isPhoneScreen ? 1 : undefined
                                        }
                                        paddingLeft={2}
                                        paddingRight={
                                            isPhoneScreen ? 2 : undefined
                                        }
                                    >
                                        <Button
                                            variant="outlined"
                                            onClick={() =>
                                                openModalPilihFaktur(
                                                    li.bundlingId,
                                                    li.namaBarang,
                                                    li.faktur,
                                                    qty * li.jumlahYangDiambil,
                                                    true,
                                                    li.id,
                                                )
                                            }
                                            fullWidth={isPhoneScreen}
                                        >
                                            Pilih Faktur
                                        </Button>
                                    </Box>
                                </Box>
                            ))
                        )}
                    </Grid>
                </Grid>
                {detailBundling.length === 0 && (
                    <Box
                        width={isPhoneScreen ? "100%" : 200}
                        marginTop={isPhoneScreen ? 1 : undefined}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => openModalPilihFaktur(id)}
                            fullWidth={isPhoneScreen}
                        >
                            Pilih Faktur
                        </Button>
                    </Box>
                )}
            </Box>
            <ModalPilihFaktur
                stokId={stokId}
                isOpenModalPilihFaktur={isOpenModalPilihFaktur}
                closeModalPilihFaktur={closeModalPilihFaktur}
                qty={!!bundlingQty ? bundlingQty : qty}
                hpp={!!bundlingHpp ? bundlingHpp : hpp}
                namaBarang={!!bundlingBarang ? bundlingBarang : namaBarang}
                isBundling={isBundling}
                idSatuan={idSatuan}
            />
        </React.Fragment>
    );
};

export default ListSelectedBarang;
