import { useMemo } from "react";
import PaymentContainer, {
    bgColor,
} from "../PaymentContainer/PaymentContainer";
import styles from "../Payment.module.css";
import SuccessIllustration from "../Assets/SuccessIllustration";
import ErrorIllustration from "../Assets/ErrorIllustration";
import { Link } from "react-router-dom";

interface IPaymentResultProps {
    response: "success" | "error";
}

const PaymentResult = ({ response }: IPaymentResultProps) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const message = urlParams.get("message");

    const RedirectButton = useMemo(
        () => (
            <div style={{ padding: 16 }}>
                <Link to={`/pengaturan`}>
                    <a
                        className={styles.button}
                        style={{ backgroundColor: bgColor }}
                    >
                        Kembali ke Pengaturan
                    </a>
                </Link>
            </div>
        ),
        [],
    );

    if (response === "success") {
        return (
            <>
                <PaymentContainer fab={RedirectButton}>
                    <div className={styles.paymentInformation}>
                        <div className={styles.paymentInformation_text}>
                            <div>Berhasil!</div>
                            <div>
                                Pembayaran yang Anda lakukan berhasil! Silahkan
                                periksa transaksi Anda.
                            </div>
                        </div>
                        <SuccessIllustration />
                    </div>
                </PaymentContainer>
            </>
        );
    }

    if (response === "error") {
        return (
            <>
                <PaymentContainer fab={RedirectButton}>
                    <div className={styles.paymentInformation}>
                        <div className={styles.paymentInformation_text}>
                            <div>Terjadi kesalahan!</div>
                            <div>Pembayaran gagal!</div>
                            <div>{message}</div>
                        </div>
                        <ErrorIllustration />
                    </div>
                </PaymentContainer>
            </>
        );
    }

    return null;
};

export default PaymentResult;
