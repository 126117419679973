import React, {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";

interface State {
  closeModalEditKategori: () => void;
  idKategori: number | string | undefined;
  isOpenModalEditKategori: boolean;
  namaKategori: string;
  openModalEditKategori: (id: number | undefined, nama: string) => void;
}

interface IEditKategoriModalContext {
  children: ReactNode;
}

const EditKategoriModalContext = createContext<State | undefined>(undefined);

const EditKategoriModalProvider = ({ children }: IEditKategoriModalContext) => {
  const [isOpenModalEditKategori, setIsOpenModalEditKategori] = useState(false);
  const [idKategori, setIdKategori] = useState<number>();
  const [namaKategori, setNamaKategori] = useState("");

  const openModalEditKategori = useCallback(
    (id: number | undefined, nama: string) => {
      setIdKategori(id);
      setNamaKategori(nama);
      setIsOpenModalEditKategori(true);
      console.log(id + nama);
    },
    []
  );

  const closeModalEditKategori = useCallback(() => {
    setIsOpenModalEditKategori(false);
    setNamaKategori("");
    setIdKategori(undefined);
  }, []);

  const value = useMemo(
    () => ({
      closeModalEditKategori,
      idKategori,
      isOpenModalEditKategori,
      namaKategori,
      openModalEditKategori,
    }),
    [
      closeModalEditKategori,
      idKategori,
      isOpenModalEditKategori,
      namaKategori,
      openModalEditKategori,
    ]
  );
  return (
    <EditKategoriModalContext.Provider value={value}>
      {children}
    </EditKategoriModalContext.Provider>
  );
};

const useEditKategoriModal = () => {
  const context = React.useContext(EditKategoriModalContext);
  if (context === undefined) {
    throw new Error(
      "useEditKategoriModal must be used within a EditKategoriModalProvider"
    );
  }
  return context;
};

export {
  EditKategoriModalContext,
  EditKategoriModalProvider,
  useEditKategoriModal,
};
