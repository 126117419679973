import {
    Avatar,
    Box,
    Divider,
    Grid,
    Link,
    Stack,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";
import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import toRupiah from "../../utils/toRupiah";
import ThemeDefaultComponent from "../../components/ThemeDefaultComponent/ThemeDefaultComponent";
import Spinner from "../../components/Spinner/Spinner";
import useCekStruk from "../../services/queries/useCekStruk";
import LogoUloWhite from "../../components/LogoUlo/LogoUloWhite";
import PhoneIcon from "@mui/icons-material/PhoneOutlined";
import HomeIcon from "@mui/icons-material/HomeOutlined";

interface IHalamanStrukProps {}

type RouteParams = {
    slug: string;
};

const HalamanStruk = (props: IHalamanStrukProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    let { slug } = useParams<RouteParams>();

    const { data: cekStruk, status: statusCekStruk } = useCekStruk(slug);

    if (statusCekStruk === "loading") {
        return (
            <ThemeDefaultComponent>
                <Box
                    sx={{
                        backgroundColor: "#b5dcc9",
                        position: "fixed",
                        width: "100vw",
                        height: "100vh",
                        top: "0px",
                        left: "0px",
                        zIndex: -10,
                    }}
                />
                <Spinner />
            </ThemeDefaultComponent>
        );
    }

    if (statusCekStruk === "error") {
        return (
            <Redirect
                to={{
                    pathname: "/404",
                }}
            />
        );
    }

    return (
        <ThemeDefaultComponent>
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    padding: fullScreen ? "0" : "4%",
                    paddingY: "4%",
                }}
            >
                <Box
                    sx={{
                        backgroundColor: "#b5dcc9",
                        position: "fixed",
                        width: "100vw",
                        height: "100vh",
                        top: "0px",
                        left: "0px",
                        zIndex: -10,
                    }}
                />
                <Box
                    sx={{
                        margin: "0 auto",
                        width: "360px",
                        borderRadius: "0.7em",
                        backgroundColor: "#ffffff",
                        overflow: "hidden",
                        position: "relative",
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        padding: 2,
                    }}
                >
                    {cekStruk?.gambarToko && (
                        <Box
                            display="flex"
                            justifyContent="center"
                            marginBottom={1}
                        >
                            <Avatar
                                src={cekStruk.gambarToko}
                                sx={{
                                    bgcolor: "primary.main",
                                    width: 100,
                                    height: 100,
                                }}
                            />
                        </Box>
                        // <img
                        //     src={cekStruk.gambarToko}
                        //     alt="Logo Ulo"
                        //     width={150}
                        //     height={150}
                        // />
                    )}
                    <Typography
                        variant="h5"
                        textAlign="center"
                        fontWeight={600}
                        marginBottom={1}
                    >
                        {cekStruk?.namaUkm}
                    </Typography>
                    {cekStruk?.noTelp && (
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            spacing={0.5}
                        >
                            <PhoneIcon sx={{ width: 16, height: 16 }} />
                            <Typography variant="subtitle2" textAlign="center">
                                +{cekStruk?.noTelp}
                            </Typography>
                        </Stack>
                    )}
                    {cekStruk?.alamatUkm && (
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            spacing={0.5}
                        >
                            <HomeIcon sx={{ width: 16, height: 16 }} />
                            <Typography variant="caption" textAlign="center">
                                {cekStruk?.alamatUkm}
                            </Typography>
                        </Stack>
                    )}
                </Box>
                <Box
                    sx={{
                        margin: "0 auto",
                        marginTop: "10px",
                        width: "360px",
                        minHeight: "150px",
                        borderRadius: "0.7em",
                        backgroundColor: "#ffffff",
                        overflow: "hidden",
                        position: "relative",
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                    }}
                >
                    <Typography margin={2} variant="h5" fontWeight={600}>
                        Struk
                    </Typography>
                    <Box paddingX={2}>
                        <Divider />
                    </Box>
                    <Grid container spacing={4} sx={{ padding: 2 }}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Tanggal
                            </Typography>
                            <Typography variant="subtitle2">
                                {cekStruk?.tanggalTransaksi}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Metode
                            </Typography>
                            <Typography variant="subtitle2">
                                {cekStruk?.metode ?? "Tunai"}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                No. Invoice
                            </Typography>
                            <Typography variant="subtitle2">
                                {cekStruk?.nomorInvoice}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" fontWeight={600}>
                                Kasir
                            </Typography>
                            <Typography variant="subtitle2">
                                {cekStruk?.kasir}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        margin: "0 auto",
                        width: "360px",
                        // minHeight: fullScreen ? "100vh" : "700px",
                        borderRadius: "0.7em",
                        backgroundColor: "#ffffff",
                        overflow: "hidden",
                        position: "relative",
                        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                        marginTop: "10px",
                    }}
                >
                    <Typography margin={2} variant="h5" fontWeight={600}>
                        Detail Item
                    </Typography>
                    <Box paddingX={2}>
                        <Divider />
                    </Box>
                    <Stack spacing={1} padding={2}>
                        {cekStruk &&
                            cekStruk.daftarItem.map((items, index) => (
                                <Stack direction="row" key={index}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Typography
                                                variant="subtitle1"
                                                fontWeight={600}
                                            >
                                                {items.namaItem}
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                color="#A1A1AA"
                                            >
                                                {toRupiah(items.hargaSatuan)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography
                                                variant="subtitle1"
                                                fontWeight={600}
                                            >
                                                {items.jumlahItem}x
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                            display="flex"
                                            justifyContent="flex-end"
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                fontWeight={600}
                                                textAlign="end"
                                            >
                                                {toRupiah(
                                                    items.hargaSatuan *
                                                        items.jumlahItem,
                                                )}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Stack>
                            ))}
                    </Stack>
                    <Box paddingX={2}>
                        <Divider />
                    </Box>
                    <Box marginTop={2} paddingX={2} paddingBottom={4}>
                        <Stack
                            direction="row"
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <Grid
                                container
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle2"
                                        color="#A1A1AA"
                                    >
                                        Subtotal
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight={600}
                                        textAlign="end"
                                    >
                                        {toRupiah(Number(cekStruk?.totalHarga))}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Stack>
                        <Stack
                            direction="row"
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <Grid
                                container
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle2"
                                        color="#A1A1AA"
                                    >
                                        Diskon
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight={600}
                                        textAlign="end"
                                    >
                                        {toRupiah(Number(cekStruk?.diskon))}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Stack>
                        <Stack
                            direction="row"
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <Grid
                                container
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle2"
                                        color="#A1A1AA"
                                    >
                                        Pajak
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight={600}
                                        textAlign="end"
                                    >
                                        {toRupiah(Number(cekStruk?.pajak))}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Stack>
                        <Stack
                            my={2}
                            direction="row"
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <Grid
                                container
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1">
                                        Total
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="h6"
                                        fontWeight={600}
                                        textAlign="end"
                                    >
                                        {toRupiah(
                                            Number(cekStruk?.totalPembayaran),
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Stack>
                        <Stack
                            direction="row"
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="flex-end"
                            >
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle2"
                                        color="#A1A1AA"
                                    >
                                        Uang yang Dibayar
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight={600}
                                        textAlign="end"
                                    >
                                        {toRupiah(
                                            Number(cekStruk?.uangYangDibayar),
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Stack>
                        <Stack
                            direction="row"
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="flex-end"
                            >
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle2"
                                        color="#A1A1AA"
                                    >
                                        Kembalian
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography
                                        variant="subtitle1"
                                        fontWeight={600}
                                        textAlign="end"
                                    >
                                        {toRupiah(Number(cekStruk?.kembalian))}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Stack>
                        {cekStruk?.keterangan && (
                            <Stack
                                direction="row"
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-end"
                                marginTop={1}
                            >
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    spacing={1}
                                >
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="subtitle2"
                                            color="#A1A1AA"
                                        >
                                            Keterangan
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            disabled
                                            multiline
                                            rows={2}
                                            fullWidth
                                            value={cekStruk.keterangan}
                                            sx={{
                                                "& .MuiOutlinedInput-input.Mui-disabled":
                                                    {
                                                        color: "rgba(0, 0, 0, 0.87)",
                                                        WebkitTextFillColor:
                                                            "rgba(0, 0, 0, 0.87)",
                                                        fontSize: "14px",
                                                    },
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Stack>
                        )}
                    </Box>
                </Box>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    margin="20px"
                    gap={1}
                >
                    <Typography fontWeight={600}>Powered by</Typography>
                    <Link
                        href="https://ukmlokal.com"
                        target="_blank"
                        rel="noreferrer"
                        underline="none"
                    >
                        <LogoUloWhite height={60} width={60} />
                    </Link>
                </Box>
            </Box>
        </ThemeDefaultComponent>
    );
};

export default HalamanStruk;
