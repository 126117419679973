import { useQuery } from "react-query";
import {
    ParamsGetListInvoice,
    GetListInvoiceResponse,
} from "../../constants/types";
import axios from "../axios";

const queryString = require("query-string");

const handleRequest = async (params: ParamsGetListInvoice) => {
    const apiGetListInvoice = queryString.stringifyUrl({
        url: "api/laporan/invoice",
        query: {
            ukmId: params.ukmId,
            search: params.search,
            size: params.size,
            page: params.page,
            tanggalAwal: params.tanggalAwal,
            tanggalAkhir: params.tanggalAkhir,
            sales: params.sales,
            kasir: params.kasir,
            metodeId: params.metodeId,
            void: params.voidInvoice,
        },
    });
    const { data } = await axios.get<GetListInvoiceResponse>(apiGetListInvoice);
    return data.data;
};

export default function useListInvoice(params: ParamsGetListInvoice) {
    return useQuery<GetListInvoiceResponse["data"]>(
        ["listInvoice", params],
        () => handleRequest(params),
    );
}
