export const handleChangeSelectUkm = (userId: number, idUkm: string) => {
    localStorage.setItem(`user${userId}`, idUkm);
};

export const getUkmId = (userId: number) => {
    return localStorage.getItem(`user${userId})`);
};

export const setLocalUkmId = (userId: number, idUkm: number) => {
    const userKey = `user${userId}`;
    try {
        window.localStorage.setItem(userKey, String(idUkm));
    } catch (error) {
        throw error;
    }
};

export const getLocalUkmId = (userId: number) => {
    const userKey = `user${userId}`;
    try {
        const ukmId = window.localStorage.getItem(userKey);
        if (ukmId) {
            return Number(ukmId);
        }
        return null;
    } catch (error) {
        throw error;
    }
};

export const setLocalColor = (userId: number, color: string) => {
    const userKey = `user${userId}__color`;
    try {
        window.localStorage.setItem(userKey, color);
    } catch (error) {
        throw error;
    }
};

export const getLocalColor = (userId: number) => {
    const userKey = `user${userId}__color`;
    try {
        const color = window.localStorage.getItem(userKey);
        if (color) {
            return color;
        }
        return null;
    } catch (error) {
        throw error;
    }
};
