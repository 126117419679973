import React from "react";
import PaymentInformation from "../../components/Payment/PaymentInformation/PaymentInformation";
import { useParams } from "react-router-dom";
import { PaymentRouteParams } from "../../constants/types";

const PaymentInfoPage = () => {
    let { orderId } = useParams<PaymentRouteParams>();

    return <PaymentInformation orderId={String(orderId) ?? ""} />;
};

export default PaymentInfoPage;
