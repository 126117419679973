import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    DialogTitle,
} from "@mui/material";
import React from "react";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";
import { useAuth } from "../../context/authContext";
import { useTransaksi } from "../../context/transaksiContext";
import { useLogoutModal } from "../../context/logoutModalContext";

interface IModalKonfirmasiLogoutProps {}

const ModalKonfirmasiLogout = (props: IModalKonfirmasiLogoutProps) => {
    const { logout, isLoading } = useAuth();
    const { handleReset } = useTransaksi();
    const { isOpenModalLogout, closeModalLogout } = useLogoutModal();

    const handleKeluar = () => {
        handleReset();
        logout();
        closeModalLogout();
    };

    return (
        <>
            <Dialog
                maxWidth="xs"
                fullWidth={true}
                open={isOpenModalLogout}
                onClose={closeModalLogout}
                PaperProps={{
                    sx: {
                        //   minHeight: "150px",
                        //   maxHeight: "150px",
                        borderRadius: 1,
                    },
                }}
            >
                <DialogTitle>
                    {/* <Typography variant="h6" fontWeight="bold">
            Keluar?
          </Typography> */}
                    Keluar?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Anda yakin ingin keluar dari aplikasi?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ padding: 2 }}>
                    <Button
                        variant="outlined"
                        onClick={handleKeluar}
                        color="error"
                        sx={{
                            fontWeight: "bold",
                        }}
                    >
                        Keluar
                    </Button>
                    <Button
                        variant="contained"
                        onClick={closeModalLogout}
                        color="error"
                        sx={{
                            fontWeight: "bold",
                        }}
                    >
                        Tidak
                    </Button>
                </DialogActions>
            </Dialog>
            <LoadingOverlay open={isLoading} />
        </>
    );
};

export default ModalKonfirmasiLogout;
