import ArrowBack from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import defaultAxios, { AxiosError } from "axios";
import React from "react";
import Swal from "sweetalert2";
import { ContentUkm, ErrorFieldResponse } from "../../constants/types";
import axios from "../../services/axios";

interface IModalTrialProps {
    isOpenModalTrial: boolean;
    closeModalTrial: () => void;
    ukmData: ContentUkm;
}

const ModalTrial = ({
    isOpenModalTrial,
    closeModalTrial,
    ukmData,
}: IModalTrialProps) => {
    const [isButtonLoading, setIsButtonLoading] = React.useState(false);

    const onSubmit = async () => {
        setIsButtonLoading(true);
        try {
            const formData = new FormData();
            formData.append("ukmId", String(ukmData.id));

            const { data } = await axios.post(`/api/aktiftrial`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (data.code === 200) {
                Swal.fire({
                    title: data.message ?? "UKM anda berstatus trial",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                window.location.reload();
            }
            setIsButtonLoading(false);
        } catch (error) {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<
                    ErrorFieldResponse | undefined
                >;
                if (serverError && serverError?.response) {
                    const fieldError = serverError?.response?.data;
                    Swal.fire({
                        title: `${fieldError?.message}`,
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "error",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                }
            }
            console.error(error);
            setIsButtonLoading(false);
        }
    };

    return (
        <Dialog
            maxWidth="xs"
            fullWidth={true}
            open={isOpenModalTrial}
            onClose={closeModalTrial}
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalTrial}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Free Trial
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Uji coba gratis selama 7 hari untuk mencoba fitur-fitur PRO
                    UKM Lokal?
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ padding: 2 }}>
                <Button
                    variant="outlined"
                    onClick={closeModalTrial}
                    color="buttonulo"
                >
                    Tidak
                </Button>
                <LoadingButton
                    loading={isButtonLoading}
                    loadingPosition="center"
                    variant="contained"
                    onClick={onSubmit}
                    color="buttonulo"
                >
                    Ya
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default ModalTrial;
