import {
    Box,
    Button,
    Card,
    CardContent,
    Step,
    StepLabel,
    StepContent,
    Stepper,
    Typography,
    // useTheme,
    useMediaQuery,
    Grid,
    FormControl,
    OutlinedInput,
    MenuItem,
    Select,
    Stack,
    TextField,
    FormHelperText,
    InputAdornment,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState, useMemo, useEffect } from "react";
import ThemeDefaultComponent from "../ThemeDefaultComponent/ThemeDefaultComponent";
// import { Link as RouterLink } from "react-router-dom";
import { SketchPicker } from "react-color";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import axios from "../../services/axios";
import { BuatTokoForm, BuatTokoResponse } from "../../constants/types";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import LogoutIcon from "@mui/icons-material/Logout";
import ModalTundaToko from "../ModalTundaToko/ModalTundaToko";
import useUser from "../../services/queries/useUser";

interface IBuatTokoStepProps {}

const schema = yup.object({
    namaToko: yup
        .string()
        .required("Kolom wajib diisi")
        .max(50, "Nama terlalu panjang"),
    noTelpToko: yup
        .string()
        .required("Kolom wajib diisi")
        .matches(/^8[1-9][0-9]/, "No. Telepon tidak valid, contoh: 831xxxx")
        .min(9, "No. Telepon terlalu pendek")
        .max(13, "No. Telepon terlalu panjang"),
    alamatToko: yup
        .string()
        .required("Kolom wajib diisi")
        .max(500, "Alamat terlalu panjang"),
    deskripsiToko: yup.string().max(100, "Deskripsi terlalu panjang"),
    jenisUsaha: yup
        .string()
        .required("Kolom wajib diisi")
        .max(50, "Jenis usaha terlalu panjang"),
    besarUsaha: yup.string().required("Kolom wajib diisi"),
    omzetPerTahun: yup.string().required("Kolom wajib diisi"),
    logoToko: yup.mixed(),
});

const BuatTokoStep = (props: IBuatTokoStepProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    // const isDesktopScreen = useMediaQuery(theme.breakpoints.up("xl"));
    const [activeStep, setActiveStep] = useState(0);
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [isOpenModalTundaToko, setIsOpenModalTundaToko] = useState(false);
    const { data: user } = useUser();

    useEffect(() => console.log("referral", user?.referral), [user?.referral]);

    const initialValues: BuatTokoForm = useMemo(
        () => ({
            namaToko: "",
            noTelpToko: "",
            alamatToko: "",
            warna: "#45A779",
            deskripsiToko: "",
            jenisUsaha: "",
            besarUsaha: "",
            omzetPerTahun: "",
            logoToko: null,
        }),
        [],
    );

    const {
        handleSubmit,
        control,
        formState: { errors },
        trigger,
        setError,
        setValue,
        clearErrors,
    } = useForm<BuatTokoForm>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
        mode: "onBlur",
    });

    const handleNext = async () => {
        let isValid = false;

        switch (activeStep) {
            case 0:
                isValid = await trigger([
                    "namaToko",
                    "noTelpToko",
                    "alamatToko",
                    "deskripsiToko",
                ]);
                break;
            case 1:
                isValid = await trigger([
                    "jenisUsaha",
                    "besarUsaha",
                    "omzetPerTahun",
                ]);
                break;
            case 2:
                isValid = true;
                break;
            case 3:
                isValid = true;
                break;
        }

        if (isValid) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const onSubmit = async (values: BuatTokoForm) => {
        // alert(JSON.stringify(values));
        const formattedOmzet = values.omzetPerTahun.replace(/\./g, "");
        const formattedNoTelp = "62" + values.noTelpToko;
        try {
            const formData = new FormData();
            formData.append("nama", values.namaToko);
            formData.append("alamat", values.alamatToko);
            formData.append("deskripsi", values.deskripsiToko ?? "");
            formData.append("no_telp", formattedNoTelp);
            formData.append("warna", values.warna);
            if (values.logoToko) {
                formData.append(
                    "gambar",
                    values.logoToko ? (values.logoToko as any) : "",
                );
            }
            formData.append("jenis_usaha", values.jenisUsaha);
            formData.append("besar_usaha", values.besarUsaha);
            formData.append("omset", formattedOmzet);
            if (!!user?.referral) {
                formData.append("referral", user.referral);
            }
            // alert(formattedOmzet);
            const { data } = await axios.post<BuatTokoResponse>(
                `/api/ukm`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            );
            if (data.code === 200) {
                Swal.fire({
                    title: "Selamat datang di ULO",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                window.location.reload();
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <ThemeDefaultComponent>
            <Box
                width="100vw"
                // height="100vh"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                sx={{ backgroundColor: "#45A779" }}
            >
                <Button
                    variant="contained"
                    color="error"
                    endIcon={<LogoutIcon />}
                    onClick={() => setIsOpenModalTundaToko(true)}
                    sx={{
                        alignSelf: "flex-end",
                        margin: "24px 24px 0 0",
                    }}
                >
                    Tunda buat ukm
                </Button>
                <Card
                    variant="outlined"
                    sx={{ margin: 3, width: isPhoneScreen ? undefined : "50%" }}
                >
                    <CardContent sx={{ padding: 3, position: "relative" }}>
                        <Stepper
                            activeStep={activeStep}
                            // alternativeLabel
                            orientation="vertical"
                        >
                            <Step>
                                <StepLabel>Buat ukm pertama anda</StepLabel>
                                <StepContent>
                                    <Grid
                                        container
                                        spacing={1}
                                        marginBottom={3}
                                    >
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                Nama UKM
                                            </Typography>
                                            <Controller
                                                name="namaToko"
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControl
                                                        fullWidth
                                                        variant="outlined"
                                                        error={Boolean(
                                                            errors.namaToko,
                                                        )}
                                                    >
                                                        <OutlinedInput
                                                            id="namaToko"
                                                            placeholder="Contoh: UKM Budi Jaya"
                                                            size="small"
                                                            {...field}
                                                        />
                                                        <FormHelperText>
                                                            {errors.namaToko
                                                                ? errors
                                                                      .namaToko
                                                                      .message
                                                                : " "}
                                                        </FormHelperText>
                                                    </FormControl>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                No. Telepon UKM
                                            </Typography>
                                            <Controller
                                                name="noTelpToko"
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControl
                                                        fullWidth
                                                        variant="outlined"
                                                        error={Boolean(
                                                            errors.noTelpToko,
                                                        )}
                                                    >
                                                        <OutlinedInput
                                                            id="noTelpToko"
                                                            placeholder="831xxxxxx"
                                                            size="small"
                                                            type="number"
                                                            startAdornment={
                                                                <InputAdornment
                                                                    position="start"
                                                                    sx={{
                                                                        color: "#000",
                                                                    }}
                                                                >
                                                                    <Typography>
                                                                        +62
                                                                    </Typography>
                                                                </InputAdornment>
                                                            }
                                                            {...field}
                                                        />
                                                        <FormHelperText>
                                                            {errors.noTelpToko
                                                                ? errors
                                                                      .noTelpToko
                                                                      .message
                                                                : " "}
                                                        </FormHelperText>
                                                    </FormControl>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                Alamat UKM
                                            </Typography>
                                            <Controller
                                                name="alamatToko"
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControl
                                                        fullWidth
                                                        variant="outlined"
                                                        error={Boolean(
                                                            errors.alamatToko,
                                                        )}
                                                    >
                                                        <OutlinedInput
                                                            id="alamatToko"
                                                            placeholder="Contoh: Jalan Maju Jaya no. 93 Semarang"
                                                            size="small"
                                                            multiline
                                                            rows={4}
                                                            {...field}
                                                        />
                                                        <FormHelperText>
                                                            {errors.alamatToko
                                                                ? errors
                                                                      .alamatToko
                                                                      .message
                                                                : " "}
                                                        </FormHelperText>
                                                    </FormControl>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                Deskripsi UKM (Opsional)
                                            </Typography>
                                            <Controller
                                                name="deskripsiToko"
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControl
                                                        fullWidth
                                                        variant="outlined"
                                                        error={Boolean(
                                                            errors.deskripsiToko,
                                                        )}
                                                    >
                                                        <OutlinedInput
                                                            id="deskripsiToko"
                                                            placeholder="Contoh: UKM ini merupakan ukm yang menjual barang grosir, dan lain-lain"
                                                            size="small"
                                                            multiline
                                                            rows={4}
                                                            {...field}
                                                        />
                                                        <FormHelperText>
                                                            {errors.deskripsiToko
                                                                ? errors
                                                                      .deskripsiToko
                                                                      .message
                                                                : " "}
                                                        </FormHelperText>
                                                    </FormControl>
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            pt: 2,
                                        }}
                                    >
                                        {/* <Button
                                            color="inherit"
                                            disabled
                                            onClick={handleBack}
                                            sx={{ mr: 1 }}
                                        >
                                            Kembali
                                        </Button> */}
                                        <Box sx={{ flex: "1 1 auto" }} />
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                        >
                                            Lanjutkan
                                        </Button>
                                    </Box>
                                </StepContent>
                            </Step>
                            <Step>
                                <StepLabel>
                                    Informasi jenis usaha anda
                                </StepLabel>
                                <StepContent>
                                    <Grid
                                        container
                                        spacing={1}
                                        marginBottom={3}
                                    >
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                Jenis usaha
                                            </Typography>
                                            <Controller
                                                name="jenisUsaha"
                                                control={control}
                                                render={({ field }) => (
                                                    <FormControl
                                                        fullWidth
                                                        variant="outlined"
                                                        error={Boolean(
                                                            errors.jenisUsaha,
                                                        )}
                                                    >
                                                        <OutlinedInput
                                                            id="jenisUsaha"
                                                            placeholder="Contoh: Kuliner, Jasa, Perdagangan, Mebel, dan lain-lain"
                                                            size="small"
                                                            {...field}
                                                        />
                                                        <FormHelperText>
                                                            {errors.jenisUsaha
                                                                ? errors
                                                                      .jenisUsaha
                                                                      .message
                                                                : " "}
                                                        </FormHelperText>
                                                    </FormControl>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <Typography variant="body2">
                                                Besar Usaha
                                            </Typography>
                                            <Controller
                                                name="besarUsaha"
                                                control={control}
                                                render={({
                                                    field: { onChange, value },
                                                }) => (
                                                    <FormControl
                                                        size="small"
                                                        fullWidth
                                                    >
                                                        {/* <InputLabel>
                                                Pilih Besar Usaha
                                            </InputLabel> */}
                                                        <Select
                                                            margin="dense"
                                                            onChange={onChange}
                                                            value={value}
                                                            // onChange={(
                                                            //     event: SelectChangeEvent,
                                                            // ) =>
                                                            //     setBesarUsaha(
                                                            //         event.target.value,
                                                            //     )
                                                            // }
                                                            // value={besarUsaha}
                                                            displayEmpty
                                                            error={Boolean(
                                                                errors.besarUsaha,
                                                            )}
                                                        >
                                                            <MenuItem value="">
                                                                <em>
                                                                    -- Pilih
                                                                    Besar Usaha
                                                                    --
                                                                </em>
                                                            </MenuItem>
                                                            <MenuItem value="Mikro">
                                                                Mikro
                                                            </MenuItem>
                                                            <MenuItem value="Kecil">
                                                                Kecil
                                                            </MenuItem>
                                                            <MenuItem value="Sedang">
                                                                Sedang
                                                            </MenuItem>
                                                            <MenuItem value="Besar">
                                                                Besar
                                                            </MenuItem>
                                                            <MenuItem value="Lainnya">
                                                                Lainnya
                                                            </MenuItem>
                                                        </Select>
                                                        <FormHelperText>
                                                            {errors.besarUsaha
                                                                ? errors
                                                                      .besarUsaha
                                                                      .message
                                                                : " "}
                                                        </FormHelperText>
                                                    </FormControl>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <Typography variant="body2">
                                                Omzet Per Tahun
                                            </Typography>
                                            <Controller
                                                name="omzetPerTahun"
                                                control={control}
                                                render={({ field }) => (
                                                    <NumberFormat
                                                        customInput={TextField}
                                                        id="omzetPerTahun"
                                                        placeholder="24.000.000"
                                                        size="small"
                                                        // margin="dense"
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        fullWidth
                                                        variant="outlined"
                                                        onValueChange={(
                                                            values,
                                                        ) => {
                                                            const { value } =
                                                                values;
                                                            const valueAkhir =
                                                                value.replace(
                                                                    ".",
                                                                    "",
                                                                );
                                                            console.log(
                                                                valueAkhir,
                                                            );
                                                            setValue(
                                                                "omzetPerTahun",
                                                                valueAkhir,
                                                            );
                                                        }}
                                                        error={Boolean(
                                                            errors.omzetPerTahun,
                                                        )}
                                                        helperText={
                                                            errors.omzetPerTahun
                                                                ? errors
                                                                      .omzetPerTahun
                                                                      .message
                                                                : " "
                                                        }
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <Typography>
                                                                        Rp.
                                                                    </Typography>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            pt: 2,
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleBack}
                                            sx={{ mr: 1 }}
                                        >
                                            Kembali
                                        </Button>
                                        <Box sx={{ flex: "1 1 auto" }} />
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                        >
                                            Lanjutkan
                                        </Button>
                                    </Box>
                                </StepContent>
                            </Step>
                            <Step>
                                <StepLabel>Kustomisasi</StepLabel>
                                <StepContent>
                                    <Grid
                                        container
                                        spacing={isPhoneScreen ? 2 : 3}
                                        marginBottom={3}
                                    >
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                Logo UKM (Opsional)
                                            </Typography>
                                            <Controller
                                                name="logoToko"
                                                control={control}
                                                render={({ field }) => (
                                                    <React.Fragment>
                                                        <TextField
                                                            id="logoToko"
                                                            fullWidth
                                                            type="file"
                                                            variant="outlined"
                                                            inputProps={{
                                                                accept: "image/*",
                                                            }}
                                                            error={Boolean(
                                                                errors.logoToko,
                                                            )}
                                                            helperText={
                                                                errors.logoToko
                                                                    ? errors
                                                                          .logoToko
                                                                          .message
                                                                    : ""
                                                            }
                                                            {...field}
                                                            onChange={(
                                                                event: any,
                                                            ) => {
                                                                if (
                                                                    event.target
                                                                        .files[0]
                                                                        .size <
                                                                    2097152
                                                                ) {
                                                                    console.log(
                                                                        event
                                                                            .target
                                                                            .files[0],
                                                                    );
                                                                    setValue(
                                                                        "logoToko",
                                                                        event
                                                                            .target
                                                                            .files[0],
                                                                    );
                                                                    clearErrors(
                                                                        "logoToko",
                                                                    );
                                                                } else {
                                                                    event.target.value =
                                                                        "";
                                                                    setError(
                                                                        "logoToko",
                                                                        {
                                                                            type: "manual",
                                                                            message:
                                                                                "Ukuran gambar harus kurang dari 2MB",
                                                                        },
                                                                    );
                                                                }
                                                            }}
                                                            value={undefined}
                                                        />
                                                    </React.Fragment>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                Warna Utama UKM
                                            </Typography>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                spacing={2}
                                                marginTop={1}
                                                marginBottom={2}
                                            >
                                                <Controller
                                                    name="warna"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Box>
                                                            <Box
                                                                onClick={() =>
                                                                    setDisplayColorPicker(
                                                                        (
                                                                            prev,
                                                                        ) =>
                                                                            !prev,
                                                                    )
                                                                }
                                                                sx={{
                                                                    width: "54px",
                                                                    height: "54px",
                                                                    borderRadius:
                                                                        "10px",
                                                                    background: `${field.value}`,
                                                                    // background: color,
                                                                    cursor: "pointer",
                                                                }}
                                                            />
                                                            {displayColorPicker ? (
                                                                <Box
                                                                    sx={{
                                                                        position:
                                                                            "absolute",
                                                                        bottom: "100px",
                                                                        left: "90px",
                                                                        zIndex: 1,
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            position:
                                                                                "fixed",
                                                                            top: "0px",
                                                                            right: "0px",
                                                                            bottom: "0px",
                                                                            left: "0px",
                                                                        }}
                                                                        onClick={() =>
                                                                            setDisplayColorPicker(
                                                                                false,
                                                                            )
                                                                        }
                                                                    />
                                                                    <SketchPicker
                                                                        color={
                                                                            field.value
                                                                        }
                                                                        onChange={(
                                                                            color,
                                                                        ) =>
                                                                            field.onChange(
                                                                                color.hex,
                                                                            )
                                                                        }
                                                                        // color={color}
                                                                        // onChange={(
                                                                        //     color,
                                                                        // ) =>
                                                                        //     setColor(
                                                                        //         color.hex,
                                                                        //     )
                                                                        // }
                                                                    />
                                                                </Box>
                                                            ) : null}
                                                        </Box>
                                                    )}
                                                />
                                                <Typography
                                                    variant="subtitle2"
                                                    fontWeight={400}
                                                    sx={{ maxWidth: "130px" }}
                                                >
                                                    Klik persegi untuk pilih
                                                    warna
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            pt: 2,
                                        }}
                                    >
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleBack}
                                            sx={{ mr: 1 }}
                                        >
                                            Kembali
                                        </Button>
                                        <Box sx={{ flex: "1 1 auto" }} />
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                        >
                                            Lanjutkan
                                        </Button>
                                    </Box>
                                </StepContent>
                            </Step>
                        </Stepper>
                        {activeStep === 3 && (
                            <React.Fragment>
                                <Typography sx={{ mt: 2, mb: 1 }}>
                                    Cek kembali data ukm anda sebelum menyimpan!
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        pt: 2,
                                    }}
                                >
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={handleReset}
                                        sx={{ mr: 1 }}
                                    >
                                        Cek dari awal
                                    </Button>
                                    <Box sx={{ flex: "1 1 auto" }} />
                                    <Button
                                        variant="contained"
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        Simpan
                                    </Button>
                                </Box>
                            </React.Fragment>
                        )}
                    </CardContent>
                </Card>
            </Box>
            <ModalTundaToko
                isOpenModalTundaToko={isOpenModalTundaToko}
                closeModalTundaToko={() => setIsOpenModalTundaToko(false)}
            />
        </ThemeDefaultComponent>
    );
};

export default BuatTokoStep;
