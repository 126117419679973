import * as React from "react";
import { SVGProps } from "react";

const Copy = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="18px"
        height="18px"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M14.25 15.75H6V5.25h8.25v10.5Zm0-12H6a1.5 1.5 0 0 0-1.5 1.5v10.5a1.5 1.5 0 0 0 1.5 1.5h8.25a1.5 1.5 0 0 0 1.5-1.5V5.25a1.5 1.5 0 0 0-1.5-1.5ZM12 .75H3a1.5 1.5 0 0 0-1.5 1.5v10.5H3V2.25h9V.75Z"
            fill="currentColor"
        />
    </svg>
);

export default Copy;
