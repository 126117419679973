import { useQuery } from "react-query";
import {
    ParamsGetMutasiStok,
    GetMutasiStokResponse,
} from "../../constants/types";
import axios from "../axios";

const queryString = require("query-string");

const handleRequest = async (params: ParamsGetMutasiStok) => {
    const apiGetMutasiStok = queryString.stringifyUrl({
        url: "/api/mutasiitem",
        query: {
            ukmId: params.ukmId,
            search: params.search,
            size: params.size,
            page: params.page,
            tanggalAwal: params.tanggalAwal,
            tanggalAkhir: params.tanggalAkhir,
        },
    });
    const { data } = await axios.get<GetMutasiStokResponse>(apiGetMutasiStok);
    return data.data;
};

export default function useMutasiStok(params: ParamsGetMutasiStok) {
    return useQuery<GetMutasiStokResponse["data"]>(
        ["mutasiItem", params],
        () => handleRequest(params),
    );
}
