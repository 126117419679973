import {
    Dialog,
    DialogTitle,
    DialogContent,
    useMediaQuery,
    IconButton,
    Stack,
    Typography,
    Box,
    Button,
    Grid,
    Step,
    StepContent,
    StepLabel,
    Stepper,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { Fragment, useEffect, useState } from "react";
import { useMutasiStokModal } from "../../context/mutasiStokModalContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PilihItemMutasiStep from "./PilihItemMutasiStep/PilihItemMutasiStep";
import DetailItemMutasiStep from "./DetailItemMutasiStep/DetailItemMutasiStep";
import { SelectedProductMutasi } from "../../constants/types";

interface IModalMutasiStokProps {}

const ModalMutasiStok = (props: IModalMutasiStokProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const { isOpenModalMutasiStok, closeModalMutasiStok } =
        useMutasiStokModal();
    const [activeStep, setActiveStep] = useState(0);
    const [products, setProducts] = useState<SelectedProductMutasi[]>([]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 1) {
            setProducts([]);
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const addProducts = (item: SelectedProductMutasi[]) => {
        setProducts(item);
    };

    const resetBundlingSteps = () => {
        setActiveStep(0);
        setProducts([]);
    };

    const steps = [
        {
            label: "Pilih produk",
            description: `Anda dapat memilih produk lebih dari satu yang ingin anda mutasi.`,
        },
        {
            label: "Masukkan mutasi",
            description: "Atur mutasi masuk atau keluar untuk setiap stok barang anda.",
        },
    ];

    useEffect(() => {
        if (!isOpenModalMutasiStok) {
            resetBundlingSteps();
        }
    }, [isOpenModalMutasiStok]);

    return (
        <Dialog
            maxWidth="md"
            fullScreen={isPhoneScreen}
            fullWidth={true}
            open={isOpenModalMutasiStok}
            onClose={closeModalMutasiStok}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalMutasiStok}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Mutasi Stok
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                <Fragment>
                    <Grid>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => (
                                <Step key={String(index + 1)}>
                                    <StepLabel>{step.label}</StepLabel>
                                    <StepContent>
                                        <Typography color="GrayText">
                                            {step.description}
                                        </Typography>
                                        <Box my={4}>
                                            {index === 0 && (
                                                <Fragment>
                                                    <PilihItemMutasiStep
                                                        addProducts={
                                                            addProducts
                                                        }
                                                    />
                                                    <Box
                                                        mt={4}
                                                        display="grid"
                                                        gridTemplateColumns="1fr 1fr 1fr"
                                                        justifyContent="flex-end"
                                                        columnGap="16px"
                                                    >
                                                        <div />
                                                        <Button
                                                            disabled={
                                                                index === 0
                                                            }
                                                            onClick={handleBack}
                                                            variant="outlined"
                                                        >
                                                            Kembali
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            disabled={
                                                                activeStep ===
                                                                    0 &&
                                                                products.length ===
                                                                    0
                                                            }
                                                            onClick={handleNext}
                                                        >
                                                            Lanjutkan
                                                        </Button>
                                                    </Box>
                                                </Fragment>
                                            )}
                                            {index === 1 && (
                                                <DetailItemMutasiStep
                                                    products={products}
                                                    onBack={handleBack}
                                                />
                                            )}
                                        </Box>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                </Fragment>
            </DialogContent>
        </Dialog>
    );
};

export default ModalMutasiStok;
