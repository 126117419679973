import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";
import LogoUlo from "../LogoUlo/LogoUlo";
import { ReactComponent as VerifikasiEmailIllust } from "../../assets/images/VerifikasiEmailIllust.svg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import DialogContent from "@mui/material/DialogContent";

interface IModalVerifikasiEmailProps {
    open: boolean;
    onClose: () => void;
}

const ModalVerifikasiEmail = ({
    open,
    onClose,
}: IModalVerifikasiEmailProps) => {
    const history = useHistory();
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));

    return (
        <Dialog
            maxWidth="lg"
            fullScreen={isPhoneScreen}
            fullWidth
            open={open}
            onClose={onClose}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 2,
                },
            }}
        >
            <DialogTitle>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ marginBottom: 6 }}>
                <Stack spacing={4.5} alignItems="center">
                    <LogoUlo width="97px" height="48px" />
                    <VerifikasiEmailIllust
                        width={267}
                        style={{
                            height: "fit-content",
                            marginTop: -64,
                            marginBottom: -64,
                        }}
                    />
                    <Box display="flex" flexDirection="column" gap={1}>
                        <Typography
                            variant="h5"
                            fontSize={28}
                            fontWeight="bold"
                            textAlign="center"
                        >
                            Cek email anda untuk verifikasi
                        </Typography>
                        <Typography
                            variant="body2"
                            color="#808080"
                            textAlign="center"
                        >
                            Silahkan verifikasi akun anda dengan cara klik link
                            yang telah kami kirim ke alamat email anda
                        </Typography>
                    </Box>
                    <Button
                        sx={{
                            background: "#2B7351"
                        }}
                        variant="contained"
                        onClick={() => history.push("/")}
                    >
                        Kembali ke halaman Masuk
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ModalVerifikasiEmail;
