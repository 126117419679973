import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect } from "react";
import ThemeDefaultComponent from "../../components/ThemeDefaultComponent/ThemeDefaultComponent";
import Ilustrasi404 from "../../assets/images/Ilustrasi404.png";
import Button from "@mui/material/Button";

interface IHalaman404Props {}

const Halaman404 = (props: IHalaman404Props) => {
    const theme = useTheme();
    const isTabletScreen = useMediaQuery(theme.breakpoints.down("md"));

    useEffect(() => {
        document.title = "404 :: Halaman tidak ditemukan";
    }, []);

    return (
        <ThemeDefaultComponent>
            <Box
                sx={{
                    width: isTabletScreen ? "100%" : undefined,
                    height: isTabletScreen ? "100%" : undefined,
                    display: "flex",
                    flexDirection: "column",
                    gap: 6,
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "#FFF",
                    borderRadius: isTabletScreen ? undefined : 3,
                    padding: isTabletScreen ? 3 : 9,
                }}
            >
                {/* <Typography
                    component="div"
                    variant="h2"
                    fontWeight="bold"
                    textAlign="center"
                >
                    4
                    <Typography
                        variant="h2"
                        fontWeight="bold"
                        sx={{ color: "#20965F" }}
                        display="inline"
                    >
                        0
                    </Typography>
                    4
                </Typography> */}
                <Box marginY={isTabletScreen ? -20 : -30}>
                    <img
                        src={Ilustrasi404}
                        alt="404"
                        width={isTabletScreen ? 250 : 600}
                        // height={300}
                        style={{ objectFit: "contain" }}
                    />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    marginTop={isTabletScreen ? undefined : -7}
                    gap={4}
                >
                    <Typography
                        component="div"
                        variant={isTabletScreen ? "h6" : "h4"}
                        fontWeight="bold"
                        textAlign="center"
                    >
                        Halaman{" "}
                        <Typography
                            variant={isTabletScreen ? "h6" : "h4"}
                            fontWeight="bold"
                            sx={{ color: "#20965F" }}
                            display="inline"
                        >
                            Tidak{" "}
                        </Typography>
                        Ditemukan
                    </Typography>
                    <Typography textAlign="center" maxWidth={500}>
                        Halaman yang anda cari tidak dapat ditemukan. Silahkan
                        hubungi Customer Support atau kembali ke halaman utama.
                    </Typography>
                    <Button
                        variant="contained"
                        size="large"
                        href="/"
                        sx={{ textTransform: "none", width: "fit-content" }}
                    >
                        Ke Halaman Utama
                    </Button>
                </Box>
            </Box>
        </ThemeDefaultComponent>
    );
};

export default Halaman404;
