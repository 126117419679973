import { useQuery } from "react-query";
import { GetStatusPembayaranResponse } from "../../constants/types";
import axios from "../axios";

const queryString = require("query-string");

const handleRequest = async (transactionId?: string) => {
    const apiGetStatusPembayaran = queryString.stringifyUrl({
        url: "api/payment/check",
        query: {
            transactionId: transactionId,
        },
    });
    const { data } = await axios.get<GetStatusPembayaranResponse>(
        apiGetStatusPembayaran,
    );
    return data.data;
};

export default function useStatusPembayaran(transactionId?: string) {
    return useQuery<GetStatusPembayaranResponse["data"]>(
        ["statusPembayaran", transactionId],
        () => handleRequest(transactionId),
        {
            // Refetch the data every second
            refetchInterval: 5000,
            enabled: !!transactionId,
        },
    );
}
