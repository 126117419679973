import { ArrowBack } from "@mui/icons-material";
import {
    Box,
    Dialog,
    Typography,
    useMediaQuery,
    IconButton,
    DialogTitle,
    Stack,
    DialogContent,
    Button,
    Grid,
    Stepper,
    Step,
    StepLabel,
    StepContent,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { SelectedFakturMutasi } from "../../constants/types";
import PilihFakturStep from "./PilihFakturStep";
import DetailFakturStep from "./DetailFakturStep";

interface IModalPilihFakturProps {
    stokId?: number;
    namaBarang: string;
    isOpenModalPilihFaktur: boolean;
    closeModalPilihFaktur: () => void;
    qty: number;
    hpp: {
        id: number;
        faktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
    isBundling?: boolean;
    idSatuan?: number;
}

const ModalPilihFaktur = ({
    stokId,
    namaBarang,
    isOpenModalPilihFaktur,
    closeModalPilihFaktur,
    qty,
    hpp,
    isBundling,
    idSatuan,
}: IModalPilihFakturProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [activeStep, setActiveStep] = useState(0);
    const [products, setProducts] = useState<SelectedFakturMutasi[]>([]);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 1) {
            setProducts([]);
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const addProducts = (item: SelectedFakturMutasi[]) => {
        setProducts(item);
    };

    const resetBundlingSteps = () => {
        setActiveStep(0);
        setProducts([]);
    };

    const steps = [
        {
            label: "Pilih faktur",
            description: `Anda dapat memilih faktur lebih dari satu.`,
        },
        {
            label: "Atur faktur",
            description:
                "Atur jumlah barang yang ingin anda ambil untuk setiap faktur.",
        },
    ];

    useEffect(() => {
        if (!isOpenModalPilihFaktur) {
            resetBundlingSteps();
        }
    }, [isOpenModalPilihFaktur]);

    return (
        <Dialog
            maxWidth="md"
            fullScreen={isPhoneScreen}
            fullWidth={true}
            open={isOpenModalPilihFaktur}
            onClose={closeModalPilihFaktur}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={closeModalPilihFaktur}>
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h6" fontWeight="bold">
                        Pilih Faktur
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent dividers>
                <React.Fragment>
                    <Grid>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => (
                                <Step key={String(index + 1)}>
                                    <StepLabel>{step.label}</StepLabel>
                                    <StepContent>
                                        <Typography color="GrayText">
                                            {step.description}
                                        </Typography>
                                        <Box my={4}>
                                            {index === 0 && (
                                                <React.Fragment>
                                                    <PilihFakturStep
                                                        addProducts={
                                                            addProducts
                                                        }
                                                        stokId={Number(stokId)}
                                                        qty={qty}
                                                        hpp={hpp}
                                                        namaBarang={namaBarang}
                                                    />
                                                    <Box
                                                        mt={4}
                                                        display="grid"
                                                        gridTemplateColumns="1fr 1fr 1fr"
                                                        justifyContent="flex-end"
                                                        columnGap="16px"
                                                    >
                                                        <div />
                                                        <Button
                                                            disabled={
                                                                index === 0
                                                            }
                                                            onClick={handleBack}
                                                            variant="outlined"
                                                        >
                                                            Kembali
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            disabled={
                                                                activeStep ===
                                                                    0 &&
                                                                products.length ===
                                                                    0
                                                            }
                                                            onClick={handleNext}
                                                        >
                                                            Lanjutkan
                                                        </Button>
                                                    </Box>
                                                </React.Fragment>
                                            )}
                                            {index === 1 && (
                                                <DetailFakturStep
                                                    products={products}
                                                    onBack={handleBack}
                                                    isOpenModalPilihFaktur={
                                                        isOpenModalPilihFaktur
                                                    }
                                                    closeModalPilihFaktur={
                                                        closeModalPilihFaktur
                                                    }
                                                    qty={qty}
                                                    namaBarang={namaBarang}
                                                    idBarang={Number(stokId)}
                                                    isBundling={isBundling}
                                                    idSatuan={idSatuan}
                                                />
                                            )}
                                        </Box>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                </React.Fragment>
            </DialogContent>
        </Dialog>
    );
};

export default ModalPilihFaktur;
