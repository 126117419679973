import React, {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";

interface State {
  closeModalHapusStok: () => void;
  idStok: number | string | undefined;
  isOpenModalHapusStok: boolean;
  namaStok: string;
  openModalHapusStok: (id: number | string, nama: string) => void;
}

interface IHapusStokModalContext {
  children: ReactNode;
}

const HapusStokModalContext = createContext<State | undefined>(undefined);

const HapusStokModalProvider = ({ children }: IHapusStokModalContext) => {
  const [isOpenModalHapusStok, setIsOpenModalHapusStok] = useState(false);
  const [idStok, setIdStok] = useState<number | string | undefined>();
  const [namaStok, setNamaStok] = useState("");

  const openModalHapusStok = useCallback(
    (id: number | string | undefined, nama: string) => {
      setIdStok(id);
      setNamaStok(nama);
      setIsOpenModalHapusStok(true);
    },
    []
  );

  const closeModalHapusStok = useCallback(() => {
    setIsOpenModalHapusStok(false);
  }, []);

  const value = useMemo(
    () => ({
      closeModalHapusStok,
      idStok,
      isOpenModalHapusStok,
      namaStok,
      openModalHapusStok,
    }),
    [
      closeModalHapusStok,
      idStok,
      isOpenModalHapusStok,
      namaStok,
      openModalHapusStok,
    ]
  );
  return (
    <HapusStokModalContext.Provider value={value}>
      {children}
    </HapusStokModalContext.Provider>
  );
};

const useHapusStokModal = () => {
  const context = React.useContext(HapusStokModalContext);
  if (context === undefined) {
    throw new Error(
      "useHapusStokModal must be used within a HapusStokModalProvider"
    );
  }
  return context;
};

export { HapusStokModalContext, HapusStokModalProvider, useHapusStokModal };
