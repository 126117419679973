import { useQuery } from "react-query";
import { GetListBanksResponse } from "../../constants/types";
import axios from "../axios";

const handleRequest = async () => {
    const { data } = await axios.get<GetListBanksResponse>(
        `/api/payment/banks`,
    );
    return data.data;
};

export default function useListBanks() {
    return useQuery<GetListBanksResponse["data"]>(["listBanks"], () =>
        handleRequest(),
    );
}
